import {
  ChevronLeft,
  ChevronRight,
  WorkspacePremium,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import api from "../../api/http-common";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import Extension from "../../Common/Extension";
import "./Accolades.css";
import TitleAndValue from "./TitleAndValue";
import LoadingComponents from "../../Common/Components/LoadingComponents";

const Accolades = () => {
  const userId = +sessionStorage.getItem("userId");
  const [selectedPlayer, setSelectPlayer] = useState("");
  const [players, setPlayers] = useState([]);
  const [achievements, setAchievements] = useState([]);
  const [achievementDetails, setAchievementDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUsersData = useCallback(async () => {
    setIsLoading(true);
    await api
      .get("/users/coachId/" + userId)
      .then((res) => setPlayers(res.data))
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  useEffect(() => {
    if (players) {
      setSelectPlayer(players[0]?.userId);
    }
  }, [players]);

  const handleUserChange = async (e) => {
    setSelectPlayer(e.target.value);
  };
  const getAchievementsForId = useCallback(async () => {
    if (selectedPlayer) {
      setIsLoading(true);
      try {
        const res = await api
          .get(`/Achievements/userId/${selectedPlayer}`)
          .then((res) => res.data)
          .catch((err) => console.error(err));
        console.log(res);
        setAchievements(res);

        const achievementIds = res?.map((response) => response.achievementsId);

        let achievementDetails = [];
        for (let achievementId of achievementIds) {
          const response = await api.get(
            "/achievementsDetails/achievementsId/" + achievementId
          );
          achievementDetails.push(response.data);
        }
        const achievementDetailData = achievementDetails.flat();
        setAchievementDetails(achievementDetailData);
        console.log(achievementDetailData);
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedPlayer]);

  useEffect(() => {
    getAchievementsForId();
  }, [getAchievementsForId]);
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = achievements?.length || 1;

  return (
    <Box className="common-wrapper">
      <LoadingComponents loading={isLoading} />
      <Box
        sx={{
          width: { md: "20%", lg: "20%", sm: "100%", xs: "100%" },
        }}
      >
        <CommonDropdown
          label={"Filter by Player"}
          value={selectedPlayer}
          onChange={handleUserChange}
          array={players}
          menuValue={"userId"}
          display={"userName"}
        />
      </Box>
      {achievements && achievements.length > 0 && (
        <div className="swiper-container">
          <div className="custom-nav-btn-back">
            <ChevronLeft />
          </div>
          <div className="custom-nav-btn-front">
            <ChevronRight />
          </div>

          <Swiper
            navigation={{
              nextEl: ".custom-nav-btn-front",
              prevEl: ".custom-nav-btn-back",
            }}
            modules={[Navigation]}
            className="mySwiper"
            ref={swiperRef}
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
          >
            {achievements?.map((item, index) => (
              <SwiperSlide key={item.achievementsId}>
                <div className="container-paper">
                  {achievementDetails.find(
                    (ac) => ac.achievementsId === item.achievementsId
                  )?.image ? (
                    <Swiper
                      effect={"coverflow"}
                      grabCursor={true}
                      loop={true}
                      centeredSlides={true}
                      slidesPerView={"auto"}
                      coverflowEffect={{
                        rotate: 10,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                      }}
                      modules={[EffectCoverflow]}
                      className="avatar-style"
                      style={{ padding: "10px 0" }}
                    >
                      {achievementDetails
                        .filter(
                          (ac) => ac.achievementsId === item.achievementsId
                        )
                        .map((detail, index) => (
                          <SwiperSlide
                            key={detail?.achievementsId}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "50%",
                            }}
                          >
                            <img
                              // className="image-style"
                              style={{ borderRadius: "var(--roundness)" }}
                              src={`data:image/jpeg;base64,${detail?.image}`}
                              alt={`Achievement ${index + 1}`}
                              loading="lazy"
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    <div className="avatar-style">No Image Found</div>
                  )}
                  <div className="detailed-container">
                    <div className="details">
                      <div className="prize-container">
                        <div className="prize-tag">
                          <WorkspacePremium
                            sx={{ fontSize: "var(--heightWeight-font-size)" }}
                          />{" "}
                          {item.priceOwn}
                        </div>
                        <div className="event-value"></div>
                      </div>
                      <h2>{item.eventName}</h2>
                      <div className="detail-value-container">
                        <div className="value-container">
                          <TitleAndValue
                            title={"Place"}
                            value={item?.location}
                          />
                          {/* <TitleAndValue
                            title={"Category"}
                            value={item.location}
                          /> */}
                          <TitleAndValue
                            title={"Organizer"}
                            value={item?.organizer}
                          />
                        </div>
                        <div className="value-container">
                          <TitleAndValue
                            title={"Date"}
                            value={Extension.convertDateFormatToDMY(item?.date)}
                          />

                          {/* <TitleAndValue
                            title={"Organizer"}
                            value={item.organizer}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="first-polygon"></div>
                    <div className="second-polygon"></div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="pagination-number">
            {currentSlide}/{totalSlides}
          </div>
        </div>
      )}
      {achievements.length <= 0 && (
        <div className="container-paper">
          <div className="avatar-style" style={{ height: "100%" }}>
            No Data Found
          </div>
        </div>
      )}
    </Box>
  );
};

export default Accolades;
