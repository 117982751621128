import { History } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Extension from "../../../../Common/Extension";
import BioTrack from "./BioTrack";
import "./Biometrics.css";
import HeightAndWeight from "./HeightAndWeight";

const Biometrics = ({ biometricData }) => {
  const dayDifference = Extension.dayDifferFind(
    new Date().toISOString().split("T")[0],
    biometricData[0]?.createdDate
  );

  return (
    <Box className="biometric-container">
      <div className="bio-container">
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--font-family)",
            fontWeight: "var(--font-weight)",
            fontSize: "var(--header-font-size)",
          }}
        >
          Biometrics
        </Typography>
        <div
          className="last-update"
          style={{
            fontFamily: "var(--font-family)",
            fontWeight: "var(--font-weight)",
            fontSize: "var(--body-font-size)",
          }}
        >
          <History />
          {dayDifference && dayDifference?.value > 1
            ? `Last Updated ${dayDifference?.value} ${dayDifference?.format} ago`
            : dayDifference?.value === 0
            ? `Last Updated Today`
            : dayDifference?.value === 1
            ? `Last Updated Yesterday`
            : !dayDifference && `Not Updated Yet`}
        </div>
      </div>
      <Grid container spacing={3} columns={1}>
        <Grid item xs={1} md={1} sm={1} lg={1}>
          <HeightAndWeight biometric={biometricData} />
        </Grid>
        <Grid item xs={1} md={1} sm={1} lg={1}>
          <BioTrack biometric={biometricData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Biometrics;
