import { Avatar, Box } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import React from "react";
import noDataIcon from "../../assets/noData.gif";

export const GridTable = ({ rows, columns, height, isLoading, hideFooter }) => {
  const Height = height || "78vh";
  return (
    <Box
      sx={{
        height: Height,
        mt: 2,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: () => CustomNoRowsOverlay(isLoading, rows),
        }}
        sx={{
          height: "100%",
          maxWidth: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "#ffffff",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          "&, [class^=MuiDataGrid]": { border: "none" },
          "& .thead": {
            backgroundColor: `var(--app-bar-color)`,
            color: "var(--header-font-color)",
          },
          "& .grid-cell-left": {
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            fontFamily: "var(--font-family)",
            border: "none",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "100%",
            fontFamily: "var(--font-family)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            position: "relative",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "var(--table-even-row)",
          },
          ".MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "var(--table-odd-row)",
          },
          ".MuiDataGrid-row:hover": {
            backgroundColor: "var(--table-hover)",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            fontFamily: "var(--font-family)",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--font-color)",
          },
          "& .MuiTablePagination-root": {
            color: "var(--font-color)",
            fontFamily: "var(--font-family)",
            width: "100%",
            // fontSize: "var(--pagination-font-size)",
            minHeight: "auto", // Set minHeight to auto to prevent overflow
          },
          "& .MuiTablePagination-toolbar": {
            paddingRight: "75px",
          },
          "& .MuiTablePagination-actions": {
            // margin: "auto",
            display: "flex",
            textAlign: "left",
            color: "var(--font-color)",
          },
          "& .MuiButtonBase-root": {
            color: "var(--font-color)",
          },
          "& .MuiToolbar-root": {
            minHeight: "auto", // Set minHeight to auto to prevent overflow
            // marginLeft: "-30px",
            // marginRight: "20px",
            // overflow: "hidden",
          },
        }}
        disableRowSelection
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooter={hideFooter}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        columnHeaderHeight={50}
      />
    </Box>
  );
};

export default GridTable;

export const CustomNoRowsOverlay = (loading, rows) => {
  const Loading = loading === false ? false : true;
  return (
    <>
      {rows.length <= 0 && (
        <GridOverlay
          sx={{
            backgroundColor: "var(--commonpaper-background)",
            color: "var(--font-color)",
          }}
        >
          {/* <Avatar
            src={noDataIcon}
            style={{ width: "350px", height: "350px" }}
            alt=""
          /> */}
          <p style={{ fontFamily: "var(--font-family)" }}>There Is No Data</p>
        </GridOverlay>
      )}
    </>
  );
};
