import { DirectionsRun, SelfImprovement, Sick } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { FixedSizeList as List } from "react-window";
import Extension from "../../../Common/Extension";
import ProgressChart from "../../common/ProgressChart";
import "./Influence.css";

const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  // backgroundColor: "#80808052",
  fontSize: "50px",
  borderRadius: "50%",
});

const StyledFoodTypography = styled(Typography)({
  display: "block",
  marginBottom: "4px",
  fontSize: "var(--body-font-size)",
  fontFamily: "var(--font-family)",
  backgroundColor: "#012a4a",
  color: "white",
  borderRadius: "5px",
  padding: "5px",
  textAlign: "center",
});

const StyledCard = styled(Card)({
  width: "100%",
  overflowY: "auto",
  margin: "0px 15px 15px 0px",
  background: "var(--light-grey)",
  border: "1px solid #2196f352",
  borderRadius: "12px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const PlayerCard = ({ index, style, data }) => {
  const currentPlayer = data[index];

  return (
    <div style={style}>
      <StyledCard key={currentPlayer.influenceHeaderId}>
        <CardContent sx={{ height: "680px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{
                  fontWeight: 700,
                  textDecoration: "underline",
                  fontSize: "var(--header-font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {Extension.convertDateFormatToDMY(currentPlayer.createdDate)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: 500,
                  padding: "10px",
                  borderRadius: "8px",
                }}
                gutterBottom
              >
                <StyledTypography variant="body2">
                  <div className="emoji-style">
                    {currentPlayer.feel.toUpperCase() === "HAPPY" ? (
                      <p>😊</p>
                    ) : currentPlayer.feel.toUpperCase() === "MAD" ? (
                      <p>😠</p>
                    ) : currentPlayer.feel.toUpperCase() === "ANGRY" ? (
                      <p>😠</p>
                    ) : currentPlayer.feel.toUpperCase() === "STRESSED" ? (
                      <p>😢</p>
                    ) : currentPlayer.feel.toUpperCase() === "BORED" ? (
                      <p>😐</p>
                    ) : (
                      <p>😢</p>
                    )}
                  </div>
                </StyledTypography>

                <StyledTypography variant="body2">
                  {currentPlayer.health.toUpperCase() === "ACTIVE" ? (
                    <Sick fontSize="100px" className="influence-icon" />
                  ) : currentPlayer.health.toUpperCase() ===
                    "JUST RECOVERED" ? (
                    <SelfImprovement
                      fontSize="100px"
                      className="influence-icon"
                    />
                  ) : (
                    <DirectionsRun
                      fontSize="100px"
                      className="influence-icon"
                    />
                  )}
                </StyledTypography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box className="sleep-container">
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "var(--body-font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                  >
                    Total Time in Bed
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginTop: "5px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          fontSize: "var(--sleep-font-size)",
                          fontFamily: "var(--font-family)",
                        }}
                      >
                        {currentPlayer.sleep}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          marginRight: "10px",
                          marginLeft: "3px",
                          fontSize: "var(--sleep-hr-font-size)",
                          fontFamily: "var(--font-family)",
                        }}
                      >
                        hrs
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          fontSize: "var(--sleep-font-size)",
                          fontFamily: "var(--font-family)",
                        }}
                      >
                        00
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          marginRight: "10px",
                          marginLeft: "3px",
                          fontSize: "var(--sleep-hr-font-size)",
                          fontFamily: "var(--font-family)",
                        }}
                      >
                        min
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <ProgressChart
                label="Energy"
                filteredData={currentPlayer.energyLevel}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ padding: "10px", borderRadius: "8px" }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: 700,
                    fontSize: "var(--header-font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  Mood Level
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    mt: 1,
                    width: "100%",
                  }}
                >
                  <div className="progress">
                    <div
                      className="progress-done"
                      style={{
                        opacity: 1,
                        width: `${currentPlayer.moodLevel}%`,
                        fontSize: "var(--body-font-size)",
                        fontFamily: "var(--font-family)",
                      }}
                    >
                      {Number(currentPlayer.moodLevel).toFixed(2)}%
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  padding: "10px",
                  borderRadius: "8px",
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "8px",
                    fontWeight: 700,
                    fontSize: "var(--header-font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  Food
                </Typography>
                <Grid container spacing={2}>
                  {currentPlayer.breakfast === true ||
                  currentPlayer.lunch === true ||
                  currentPlayer.dinner === true ||
                  currentPlayer.snack === true ? (
                    <>
                      {currentPlayer.breakfast && (
                        <Grid item sx={6} md={4}>
                          <StyledFoodTypography variant="body2">
                            Breakfast
                          </StyledFoodTypography>
                        </Grid>
                      )}
                      {currentPlayer.lunch && (
                        <Grid item sx={6} md={4}>
                          <StyledFoodTypography variant="body2">
                            Lunch
                          </StyledFoodTypography>
                        </Grid>
                      )}
                      {currentPlayer.dinner === true && (
                        <Grid item sx={6} md={4}>
                          <StyledFoodTypography variant="body2">
                            Dinner
                          </StyledFoodTypography>
                        </Grid>
                      )}
                      {currentPlayer.snack === true && (
                        <Grid item sx={6} md={4}>
                          <StyledFoodTypography variant="body2">
                            Snack
                          </StyledFoodTypography>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <StyledFoodTypography
                        variant="body2"
                        style={{
                          fontSize: "var(--body-font-size)",
                          fontFamily: "var(--font-family)",
                        }}
                      >
                        No Food
                      </StyledFoodTypography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </div>
  );
};

PlayerCard.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object,
};

const PlayerList = ({ filteredData }) => {
  return (
    <>
      {filteredData.length > 0 ? (
        <List
          height={window.innerHeight}
          itemCount={filteredData?.length}
          itemSize={720} // Adjust this based on your card height (680px + margin)
          width="100%"
          itemData={filteredData}
        >
          {PlayerCard}
        </List>
      ) : (
        <StyledCard>
          <CardContent sx={{ height: "680px" }} className="no-data-found">
            There is No Data
          </CardContent>
        </StyledCard>
      )}
    </>
  );
};

export default PlayerList;

PlayerList.propTypes = {
  filteredData: PropTypes.array,
};
