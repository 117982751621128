import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";

function AcademyAdd() {
  const [academy, setAcademy] = useState([]);
  const [alert, setAlert] = useState(false);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const { toast, contextHolder } = ToastMessage();
  const userId = parseInt(sessionStorage.getItem("userId"));
  const navigate = useNavigate();
  async function getAcademy() {
    try {
      const response = await api.get("academy/all");
      setAcademy(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {
    getAcademy();
  }, []);
  const initialState = {
    academyName: "",
    emailId: "",
    password: "",
    contactNumber: "",
    contactPerson: "",
    gstNo: "",
    address: "",
    city: "",
    pinCode: "",
    prefix: "",
  };
  const [formValue, setFormValue] = useState(initialState);

  function inputChange(e) {
    const name = e.target.name;
    let value = e.target.value;

    // Enforce numeric input and a maximum length of 10 for 'contactNumber'
    if (name === "contactNumber" || name === "pinCode") {
      // Remove non-numeric characters
      value = value.replace(/\D/g, "");

      value = value.slice(0, 10);

      // Enforce a maximum length of 10
    } else if (name === "prefix") {
      // Convert 'prefix' to uppercase
      value = value.toUpperCase();
    }

    setFormValue((previous) => ({ ...previous, [name]: value }));
  }
  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  function handleCloseAlert() {
    setAlert(false);
  }
  async function formSubmit(e) {
    handleCloseAlert();
    e.preventDefault();

    const prefixCheck = academy.find((item) => item.prefix == formValue.prefix);
    if (!prefixCheck) {
      try {
        setAddButtonDisable(true);
        const academyValue = {
          ...formValue,
          createdBy: userId,
          createdDate: getCurrentSQLDate(),
          modifiedBy: userId,
          modifiedDate: getCurrentSQLDate(),
        };
        academyValue.createdDate = getCurrentSQLDate();
        academyValue.coachLimitation = 100;
        academyValue.playerLimitation = 100;
        academyValue.coachLatestId = 0;
        academyValue.playerLatestId = 0;

        const response = await api.post("academy/add", academyValue);

        if (response.data) {
          const adminData = {
            roleId: 1,
            userName: response.data.academyName,
            email: response.data.emailId,
            pwd: formValue.password,
            coachId: 0,
            active: true,
            loginId: response.data.prefix + "MAN",
            modifyDate: getCurrentSQLDate(),
            createDate: getCurrentSQLDate(),
            academyId: response.data.academyId,
          };
          const visitorData = {
            roleId: 4,
            userName: response.data.academyName,
            email: response.data.emailId,
            pwd: formValue.password,
            coachId: 0,
            active: true,
            loginId: response.data.prefix + "VIS",
            modifyDate: getCurrentSQLDate(),
            createDate: getCurrentSQLDate(),
            academyId: response.data.academyId,
          };
          const personalDetails = {
            name: response.data.academyName,
            email: response.data.emailId,
            mobileNo: formValue.contactNumber,
            city: formValue.city,
            pinCode: formValue.pinCode,
            street: formValue.address,
            userId: sessionStorage.getItem("userId"),
            academyId: sessionStorage.getItem("academyId"),
            createBy: sessionStorage.getItem("userId"),
            createdDate: getCurrentSQLDate(),
          };
          await api.post("users/add", adminData);
          console.log("manager post success");
          await api.post("users/add", visitorData);
          console.log("visitor post success");
          // await api.post("/personal_info/add", personalDetails);
          console.log("PersonalDetails post success");
          setFormValue(initialState);

          toast("success", "Academy successfully added");
          // Display a single alert for both academy and admin user success
        } else {
          console.error("Response data is undefined:", response);
        }
      } catch (err) {
        console.log("Error", err);
      } finally {
        navigate("/AcademyList");
        setAddButtonDisable(false);
      }
    } else {
      setAlert(true);
    }
  }
  const fields = [
    {
      name: "academyName",
      label: "Academy Name",
      type: "text",
      required: true,
      value: formValue.academyName,
      onChange: inputChange,
    },
    {
      name: "emailId",
      label: "Email ID",
      type: "email",
      required: false,
      value: formValue.emailId,
      onChange: inputChange,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      maxLength: 12,
      required: true,
      value: formValue.password,
      onChange: inputChange,
    },
    {
      name: "contactNumber",
      label: "Contact No",
      type: "text",
      inputMode: "numeric",
      pattern: "[0-9]*",
      maxLength: 10,
      required: false,
      value: formValue.contactNumber,
      onChange: inputChange,
    },
    {
      name: "contactPerson",
      label: "Contact Person",
      type: "text",
      required: false,
      value: formValue.contactPerson,
      onChange: inputChange,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      required: false,
      value: formValue.address,
      onChange: inputChange,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: false,
      value: formValue.city,
      onChange: inputChange,
    },
    {
      name: "pinCode",
      label: "Pin Code",
      type: "text",
      inputMode: "numeric",
      pattern: "[0-9]*",
      maxLength: 6,
      required: false,
      value: formValue.pinCode,
      onChange: inputChange,
    },
    {
      name: "prefix",
      label: "Prefix Name",
      type: "text",
      required: true,
      value: formValue.prefix,
      onChange: inputChange,
    },
  ];

  const navigatePath = () => navigate("/academyList");

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Box>
        <CustomCommonButton
          onClick={navigatePath}
          variant="contained"
          text="BACK"
          icon={<ArrowCircleLeftIcon />}
        />
      </Box>
      <Paper
        className="content"
        elevation={4}
        sx={{ p: 3, height: "60vh", mt: 3 }}
      >
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h4"
            sx={{ color: "var(--app-bar-color)", fontWeight: 700 }}
          >
            Onboarding Academy
          </Typography>
        </Box>
        {alert && (
          <Alert severity="error" onClose={handleCloseAlert}>
            The Prefix Name Is Already Exist!
          </Alert>
        )}
        <Box
          autoComplete="off"
          component="form"
          onSubmit={formSubmit}
          // sx={{ ml: 15 }}
        >
          <Grid container spacing={3} columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}>
            {fields.map((field) => (
              <Grid item xs={1} md={1} lg={1} xl={1} key={field.name}>
                <CommonTextField
                  value={field.value}
                  name={field.name}
                  onChange={field.onChange}
                  label={field.label}
                  maxLength={field.maxLength}
                  type={field.type}
                  required={field.required}
                />
              </Grid>
            ))}
            <Grid
              item
              xs={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <CustomCommonButton disabled={addButtonDisable} text="Submit" />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default AcademyAdd;
