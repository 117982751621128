import { Spin } from "antd";
import React from "react";
import PropTypes from "prop-types";

const LoadingComponents = ({ loading }) => {
  return <Spin spinning={loading} fullscreen={true} />;
};

export default LoadingComponents;

LoadingComponents.propTypes = {
  loading: PropTypes.bool,
};
