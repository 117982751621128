import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import api from "../../api/http-common";

import {
  CloseRounded,
  CloudUpload,
  DeleteRounded,
  Download,
  EditNoteRounded,
  SaveRounded,
  SettingsAccessibility,
} from "@mui/icons-material";
import {
  DataGrid,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";
import { CommonPlayerAddIcon } from "../../Common/IconsAndComps";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import ExcelJS from "exceljs";
import { saveAs } from "save-as";
import LoadingComponents from "../../Common/Components/LoadingComponents";

function getCurrentSQLDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export const Exercise = ({ params, minValue }) => {
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [unit, setUnit] = useState([]);
  const [exerciseList, setExerciseList] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalRecord, setTotalRecord] = useState(0);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { toast, contextHolder } = ToastMessage();
  const [isLoading, setIsLoading] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedFile("");
    setOpen(false);
  };
  const handleSampleExcel = async () => {
    // Create a sample Excel sheet and trigger download
    // const sampleData = [['Exercise', 'Type', 'Unit', 'Reps', 'ActiveTime', 'Rest']];
    const sampleData = [
      "S NO",
      "Exercise",
      "Type",
      "Unit",
      "Video Url",
      "Image Url",
      "Visible type",
    ];

    const types = ["WarmDown", "WarmUp", "Workout", "Stretch", "Plyometric"];
    const VISIBLE = ["Private", "Public"];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Exercise Data");

    worksheet.addRow(sampleData);

    worksheet.getCell("C2").dataValidation = {
      type: "list",
      allowBlank: false,
      formulae: [`"${types.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Input",
      error: "Please select a value from the list",
    };

    worksheet.getCell("G2").dataValidation = {
      type: "list",
      allowBlank: false,
      formulae: [`"${VISIBLE.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Input",
      error: "Please select a value from the list",
    };

    worksheet.getCell("D2").dataValidation = {
      type: "list",
      allowBlank: false,
      formulae: [`"${unit.map((item) => item.singularName).join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Input",
      error: "Please select a value from the list",
    };

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "XIP Exercise Bulk Upload Sample.xlsx");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
        setErrorMessage(""); // Clear any previous error message
      } else {
        setSelectedFile(null);
        setErrorMessage("Please select a valid Excel file (.xlsx)");
      }
    } else {
      setSelectedFile(null);
      setErrorMessage("");
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = (e) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        console.log("onload", data, workbook);
        try {
          let record = 0;
          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            parsedData.forEach((item) => {
              const Visible = item["Visible type"] === "Public" ? true : false;
              const data = {
                warmUpName: item.Exercise,
                type: item.Type,
                unit: item.Unit,
                videoURL: item["Video Url"] || "",
                image: item["Image Url"] || "",
                isPublic: Visible,
                academyId: sessionStorage.getItem("academyId"),
                partOfBody: 0,
                createdBy: userId,
                createdDate: getCurrentSQLDate(),
                modifiedBy: userId,
                modifiedDate: "2024-06-29T03:41:30.153Z",
              };
              console.log("datas", data.videoURL);
              record++;

              // Send a POST request to your API
              api
                .post("/warmUp/add", data)
                .then((response) => {})
                .catch((error) => {
                  // Handle any errors from the API request, provide feedback to the user
                  console.error("Error uploading data:", error);
                });
            });
          });

          setTotalRecord(record);
          handleClose();
          
          fetchData()

          if (record > 0) {
            toast("success", `${record} records uploaded.`);
          }
          setIsLoading(true)

          // console.log('All data successfully uploaded.');
        } catch (error) {
          console.error("Error processing Excel file:", error);
          setErrorMessage("Error processing Excel file.");
        } finally {
          setIsLoading(false)
        }
      };
    } else {
      setErrorMessage("Please check the data before uploading.");
    }
  };
  const handleClick = () => {
    const id = exerciseList.length + 1;
    // const newRow = { id, warmUpName: '', type: '', unit: '', rep: '', time: '', rest: '', isNew: true };
    const newRow = {
      id,
      warmUpName: "",
      type: "",
      unit: "",
      videoURL: "",
      image: "",
      isNew: true,
      isPublic: true,
    };

    setExerciseList((oldRows) => [newRow, ...oldRows]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "warmUpName" },
    }));
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    console.log("setRowModesModel", id);
  };
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const handlePopupOpen = (index) => {
    setSelectedIndex(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  // Delete the Row
  const handleDeleteClick = async () => {
    try {
      toast("loading", "Processing...");
      const response = await api.delete(`/warmUp/${selectedIndex}`);
      console.log(response);
      setExerciseList(
        exerciseList.filter((exercise) => exercise.id !== selectedIndex)
      );
      handlePopupClose();

      toast("success", "Exercise Deleted Successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = exerciseList.find((row) => row.id === id);
    if (editedRow.isNew) {
      setExerciseList(exerciseList.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      isPublic: false,
      modifiedBy: userId,
      modifiedDate: getCurrentSQLDate(),
    };
    const action = newRow.isPublic ? "new" : "edit";
    console.log("action", action);

    if (
      updatedRow.type === "" ||
      updatedRow.warmUpName === "" ||
      updatedRow.rep === null ||
      updatedRow.rest === null ||
      updatedRow.time === null
    ) {
      toast("error", "All fields are required!");
      return null;
    }

    if (action === "new") {
      addWarmup(updatedRow);
      console.log("updatedrow", updatedRow);
    } else {
      toast("loading", "Processing...");
      setIsLoading(true)
      api
        .put(`/warmUp/${newRow.id}`, updatedRow)
        .then(() => {
          setExerciseList((oldRows) =>
            oldRows.map((row) => (row.id === newRow.id ? updatedRow : row))
          );
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [newRow.id]: { mode: GridRowModes.View },
          }));
        })
        .catch((error) => {
          console.error("Error updating exercise:", error);
        });
      toast("success", "Exercise Updated Successfully");
      setIsLoading(false)
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const fetchData = async () => {
    setIsLoading(true)

    try {
      const unitResponse = await api.get("unit/all");
      if (unitResponse.data !== null && unitResponse.data !== "") {
        setUnit(unitResponse.data);
      } else {
        setUnit([{ data: "No Data Available" }]);
      }

      const exerciseResponse = await api.get("warmUp/createdBy/" + userId);
      console.log("Exercise List:", exerciseResponse.data);
      if (exerciseResponse.data !== null && exerciseResponse.data !== "") {
        const item = exerciseResponse.data.map((row) => {
          row["id"] = row["warmUpId"];
          return row;
        });
        //       console.log("itemex",item);

        //       setExerciseList(item);
        //       console.log("exerciselist",item)
        //     } else {
        //       setExerciseList([{ data: 'No Data Available' }]);
        //     }
        //   } catch (error) {
        //     console.error('Error fetching data:', error);
        //   }
        // };
        const updatedItem = item.map((row) => ({
          ...row,
          image: row.image, //|| 'default-image-url', // Provide a default image URL or handle it based on your requirements
        }));

        setExerciseList(updatedItem);
      } else {
        setExerciseList([{ data: "No Data Available" }]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setIsLoading(false)
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  function addWarmup(newRow) {
    //   const newWarmup = { ...newRow, createdBy: userId, createdDate: currentDate(new Date()) };
    const newWarmup = {
      ...newRow,
      videoURL: newRow.videoURL,
      image: newRow.image, //|| 'default-image-url', // Provide a default image URL or handle it based on your requirements
      createdBy: userId,
      createdDate: currentDate(new Date()),
      academyId: sessionStorage.getItem("academyId"),
      partOfBody: "",
    };
    toast("loading", "Processing...");
    console.log("new warmup", newWarmup.image);
    api
      .post("warmUp/add", newWarmup)
      .then((res) => {
        const response = { ...newWarmup, id: res.data.warmUpId };
        const warmUpList = exerciseList.map((row) =>
          row.id === newRow.id ? response : row
        );
        setExerciseList(warmUpList);
        console.log("warmuplist", warmUpList);
        setRowModesModel({
          ...rowModesModel,
          [response.id]: { mode: GridRowModes.View },
        });
      })
      .catch((error) => {
        console.error("Error adding warm-up:", error);
        // Handle the error, e.g., display an error message to the user
      });
    toast("success", "Exercise Created Successfully");
  }

  const columns = [
    {
      field: "sNo",
      headerClassName: "thead",
      headerName: "S.No",
      headerStyle: {
        fontSize: "var(--font-size)",
        fontFamily: "var(--font-family)",
      },
      width: 150,
      // editable: true,
    },
    {
      field: "warmUpName",
      headerClassName: "thead",
      headerName: "Exercise",
      headerStyle: {
        fontSize: "var(--font-size)",
        fontFamily: "var(--font-family)",
      },
      flex: 1.5,
      editable: true,
    },
    {
      field: "type",
      headerStyle: {
        fontSize: "var(--font-size)",
        fontFamily: "var(--font-family)",
      },
      headerClassName: "thead",
      headerName: "Type",
      type: "singleSelect",
      width: 150,
      editable: true,
      valueOptions: ["WarmDown", "WarmUp", "Workout", "Stretch", "Plyometric"],
    },
    {
      field: "unit",
      headerClassName: "thead",
      headerName: "Unit",
      type: "singleSelect",
      width: 150,
      editable: true,
      valueOptions: unit.map((item) =>
        item.singularName ? item.singularName : item.data
      ),
    },
    {
      field: "image", // Corrected field name
      headerClassName: "thead",
      headerName: "Image URL",
      flex: 1.2,
      editable: true,
    },
    {
      field: "videoURL",
      headerClassName: "thead",
      headerName: "Video URL",
      flex: 1.2,
      editable: true,
    },

    {
      field: "actions",
      type: "actions",
      headerClassName: "thead",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      align: "center",
      headerAlign: "center",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <SaveRounded color="success" onClick={handleSaveClick(id)} />
              <CloseRounded color="error" onClick={handleCancelClick(id)} />
            </Box>,
          ];
        }

        return [
          <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <EditNoteRounded color="success" onClick={handleEditClick(id)} />
            <DeleteRounded color="error" onClick={() => handlePopupOpen(id)} />
          </Box>,
        ];
      },
    },
  ];
  function currentDate(date) {
    // Get the current Date
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const capitalizeFirstLetter = (str) => {
    const exceptions = ["the", "and", "to", "m"]; // Add more words as needed
    const words = str?.split(/\s+/);
    const capitalizedWords = words?.map((word) =>
      exceptions.includes(word.toLowerCase())
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    );
    return capitalizedWords?.join(" ");
  };

  // Assuming exerciseList is your array of objects
  const formattedExerciseList = exerciseList.map((exercise, index) => ({
    ...exercise,
    sNo: index + 1,
    warmUpName: capitalizeFirstLetter(exercise.warmUpName),
    unit: capitalizeFirstLetter(exercise.unit),
    type: capitalizeFirstLetter(exercise.type),
  }));

  return (
    <Box className="common-wrapper">
      <CommonAlertPopup
        handleYes={handleDeleteClick}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Are you sure you want to delete the Exercise?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      {contextHolder}
      <LoadingComponents loading={isLoading} />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <CustomCommonButton
          onClick={handleClick}
          icon={CommonPlayerAddIcon}
          tooltipText="Add Exercise"
        />
        <Box sx={{ position: "relative", height: "100%" }}>
          <CustomCommonButton
            tooltipText="Bulk Upload"
            icon={
              <SettingsAccessibility sx={{ fontSize: "40px", opacity: 0.5 }} />
            }
            onClick={handleOpen}
          />
          <Box sx={{ position: "absolute", bottom: "-5px", right: "3px" }}>
            <CloudUpload
              sx={{
                backgroundColor: "unset",
                color: "white",
                opacity: 0.5,
                fontSize: "18px",
              }}
            />
          </Box>
        </Box>
        {/* <CustomCommonButton
          tooltipText = "Bulk Upload"
            onClick={handleOpen}
            icon={<UploadIcon sx={{ fontSize: "35px", mr: "5px" }} />}
          /> */}
      </div>
      <Modal keepMounted open={open} onClose={handleClose}>
        <Box sx={Style.widthPopupStyle}>
          <Grid
            container
            columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
            spacing={2}
          >
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Typography variant="h4" sx={Style.titleText}>
                Bulk Upload
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <CustomCommonButton
                text={"Upload File"}
                onClick={() => document.getElementById("file-upload").click()}
                icon={<CloudUpload />}
                fullWidth={"100%"}
              />
              <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                accept=".xlsx"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={3}
              lg={3}
              xl={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {selectedFile
                  ? `Selected File: ${selectedFile.name}`
                  : "No File Selected"}
              </Typography>
            </Grid>

            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Sample Excel"}
                onClick={handleSampleExcel}
                icon={<Download />}
                fullWidth={"100%"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Upload"}
                onClick={handleUpload}
                disabled={!selectedFile}
                fullWidth={"100%"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Box
        sx={{
          mt: 2,
          height: "78vh",
        }}
      >
        <DataGrid
          rows={formattedExerciseList}
          columns={columns}
          sx={{
            height: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "auto",
            backgroundColor: "#ffffff",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            "&, [class^=MuiDataGrid]": { border: "none" },
            "& .thead": {
              backgroundColor: `var(--app-bar-color)`,
              color: "var(--header-font-color)",
            },
            "& .grid-cell-left": {
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              border: "none",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              position: "relative",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "var(--table-even-row)",
            },
            ".MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "var(--table-odd-row)",
            },
            ".MuiDataGrid-row:hover": {
              backgroundColor: "var(--table-hover)",
            },
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--font-color)",
            },
            "& .MuiTablePagination-root": {
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              width: "100%",
              // fontSize: "var(--pagination-font-size)",
              minHeight: "auto", // Set minHeight to auto to prevent overflow
            },
            "& .MuiTablePagination-toolbar": {
              paddingRight: "75px",
            },
            "& .MuiTablePagination-actions": {
              // margin: "auto",
              display: "flex",
              textAlign: "left",
              color: "var(--font-color)",
            },
            "& .MuiButtonBase-root": {
              color: "var(--font-color)",
            },
            "& .MuiToolbar-root": {
              minHeight: "auto", // Set minHeight to auto to prevent overflow
              // marginLeft: "-30px",
              // marginRight: "20px",
              // overflow: "hidden",
            },
          }}
          editMode="row"
          rowModesModel={rowModesModel}
          disableRowSelection
          disableRowSelectionOnClick
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          // slots={{
          //   // add new row fun call
          //   toolbar: EditToolbar,
          // }}
          slotProps={{
            // add new row fun call
            toolbar: { setExerciseList, setRowModesModel },
          }}
        />
      </Box>
      {/*</Box></Paper>*/}
    </Box>
  );
};
