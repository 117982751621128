import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CustomCommonButton from "../CustomCommonButton";
import "./CommonPopup.css";

const CommonAlertPopup = (props) => {
  const {
    handleYes,
    handleNo,
    titleText,
    contentText,
    open,
    handleClose,
    cancelButtonText,
    confirmButtonText,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="alert-popup"
    >
      <DialogTitle id="alert-dialog-title" className="dialogue-title">
        {titleText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="dialogue-text"
        >
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialogue-button">
        {/* <Flex wrap gap="small"> */}
        <CustomCommonButton
          onClick={handleNo}
          text={cancelButtonText}
          // fullWidth={true}
        />
        <CustomCommonButton
          onClick={handleYes}
          text={confirmButtonText}
          color="error"
          // fullWidth={true}
        />
        {/* <Button onClick={handleNo}>No</Button>
        <Button danger type="primary" onClick={handleYes} autoFocus>
          Yes
        </Button> */}
        {/* </Flex> */}
      </DialogActions>
    </Dialog>
  );
};

export default CommonAlertPopup;
