import {
  CalendarMonth,
  CloudUpload,
  DeleteRounded,
  Done,
  Download,
  WorkspacePremium,
} from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useReactToPrint } from "react-to-print";
import { saveAs } from "save-as";
import * as XLSX from "xlsx";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";
import { RadioBtnFilter } from "../../Common/Components/dateFilter/RadioBtnFilter";
import Extension from "../../Common/Extension";
import {
  CommonFlagIcon,
  CommonStateIcon,
  CommonWorldIcon,
} from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import logo from "../../assets/logo.png";
import DateCalendarServerRequest from "../../Common/Components/Calender";
import RemainingDays from "../../Common/Components/RemainingDays.jsx";
import LoadingComponents from "../../Common/Components/LoadingComponents.jsx";

export const Performance = () => {
  const userId = parseInt(sessionStorage.getItem("userId"));
  const academyId = parseInt(sessionStorage.getItem("academyId"));
  const [player, setPlayer] = useState([]);
  const [event, setEvent] = useState([]);
  const [subEventList, setSubEventList] = useState([]);
  const { toast, contextHolder } = ToastMessage();
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePopupOpen = (index) => {
    setSelectedIndex(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  // This is for Morning and evening function
  const getIconForSession = (session) => {
    if (session === "Morning") {
      return (
        <WbSunnyIcon
          sx={{
            color: "rgb(248, 234, 33)",
            backgroundColor: "var(--app-bar-color)",
            padding: "6px",
            fontSize: "30px",
            borderRadius: "5px",
          }}
        />
      );
    } else {
      return (
        <WbTwilightIcon
          sx={{
            color: "Orange",
            backgroundColor: "var(--app-bar-color)",
            padding: "6px",
            fontSize: "30px",
            borderRadius: "5px",
          }}
        />
      );
    }
  };

  const today = new Date();

  const currentDay = format(today, "yyyy-MM-dd");

  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );

  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );

  // This is for performance delete function
  async function handlePerformanceDelete() {
    setIsLoading(true);
    try {
      const response = await api.delete(`/practice/${selectedIndex}`);
      toast("success", "Practice Deleted Successfully");
      handlePopupClose();
      getPerformances();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const [performances, setPerformances] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState("All");
  const [selectedSubEventId, setSelectedSubEventId] = useState("All");
  const [playerEvents, setPlayerEvents] = useState([]);
  const [playerSubEvents, setPlayerSubEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");

  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [bulkEvent, setBulkEvent] = useState([]);
  const [bulkSubEvent, setBulkSubEvent] = useState([]);
  const [selectedBulkPlayerID, setSelectedBulkPlayerID] = useState("");
  const [selectedBulkSubEventId, setSelectedBulkSubEventId] = useState("");
  const [selectedBulkEventId, setSelectedBulkEventId] = useState("");
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [period, setPeriod] = useState("all");

  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(monthly),
    endDate: new Date(),
    key: "selection",
  });
  const handleDateRangeChange = (dateRange) => {
    setSelectedRange(dateRange.selection);
    setPeriod("custom");
  };

  // This is for Player get function
  const getPlayer = useCallback(async () => {
    try {
      const response = await api.get(`/users/coachId/${userId}`);
      setPlayer(response.data);
    } catch (err) {
      console.error("Error", err);
    }
  }, [userId]);

  useEffect(() => {
    getPlayer(); // Make sure getPlayer is being called here
  }, [getPlayer]);

  const clearTextField = () => {
    setSelectedBulkPlayerID("");
    setSelectedBulkSubEventId("");
    setSelectedBulkEventId("");
    setSelectedFile(null);
  };

  const handleBulkUploadOpen = () => {
    setBulkUploadOpen(true);
  };
  const handleBulkUploadClose = () => {
    setBulkUploadOpen(false);
    clearTextField();
  };

  useEffect(() => {
    const getRecords = async () => {
      await api
        .get(`/record/all`)
        .then((res) => setRecords(res.data))
        .catch((err) => console.error(err));
      await api
        .get(`/record/all`)
        .then((res) => setRecords(res.data))
        .catch((err) => console.error(err));
    };
    getRecords();
  }, []);

  const getAllEventsAndSubEvents = async () => {
    await api
      .get(`/event_type/all`)
      .then((res) => {
        setEvent(res.data);
      })
      .catch((err) => console.error(err));
    await api
      .get(`/sub_events/all`)
      .then((res) => {
        setSubEventList(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAllEventsAndSubEvents();
  }, []);

  const getBulkEvents = async (e) => {
    setSelectedBulkPlayerID(e.target.value);
    getEvents(e.target.value);
  };

  const getEvents = async (id) => {
    const API =
      id === "All" ? `/strength/userId/${userId}` : `/strength/userId/${id}`;
    await api
      .get(API)
      .then((res) => {
        getEventArray(
          new Set(res.data.map((item) => item.eventTypeId)),
          event,
          setBulkEvent,
          setSelectedBulkEventId
        );
      })
      .catch((err) => console.error(err));
  };

  const getSubEvents = async (id) => {
    const API = `/strength/user/${selectedBulkPlayerID}/${id}`;
    await api
      .get(API)
      .then((res) => {
        getSubEventArray(
          new Set(res.data.map((item) => item.subEventsId)),
          subEventList,
          setBulkSubEvent,
          setSelectedBulkSubEventId
        );
      })
      .catch((err) => console.error(err));
  };

  const getEventArray = (id, array, setArray, setValue) => {
    let EventArray = [];
    for (let eventId of id) {
      EventArray.push(
        array.filter((item) => parseInt(item.eventTypeId) === parseInt(eventId))
      );
    }
    setArray(EventArray.flat());
    setValue(EventArray.flat()[0].eventTypeId);
  };

  const getSubEventArray = (id, array, setArray, setValue) => {
    let EventArray = [];
    for (let eventId of id) {
      EventArray.push(
        array.filter((item) => parseInt(item.subEventId) === parseInt(eventId))
      );
    }
    console.log(EventArray.flat());
    setArray(EventArray.flat());
  };

  const handleBulkEvent = async (e) => {
    setSelectedBulkEventId(e);
    getSubEvents(e);
  };

  useEffect(() => {
    if (selectedBulkEventId) {
      handleBulkEvent(selectedBulkEventId);
    }
  }, [selectedBulkEventId]);

  const getPerformances = useCallback(async () => {
    setIsLoading(true);
    const API =
      selectedPlayerId === "All" && selectedSubEventId !== "All"
        ? `/performance/coachId/findDate/${userId}/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(
            selectedRange.endDate,
            "yyyy-MM-dd"
          )}/${selectedSubEventId}`
        : selectedPlayerId !== "All" && selectedSubEventId !== "All"
        ? `/performance/playerId/findDate/${selectedPlayerId}/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(
            selectedRange.endDate,
            "yyyy-MM-dd"
          )}/${selectedSubEventId}`
        : selectedPlayerId !== "All" && selectedSubEventId === "All"
        ? `/performance/playerId/${selectedPlayerId}/findDate/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(selectedRange.endDate, "yyyy-MM-dd")}/all`
        : `/performance/coachId/${userId}/findDate/${format(
            selectedRange.startDate,
            "yyyy-MM-dd"
          )}/${format(selectedRange.endDate, "yyyy-MM-dd")}/all`;
    await api
      .get(API)
      .then((res) => {
        setPerformances(res.data);
      })
      .catch((err) => setPerformances([]))
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    selectedRange.endDate,
    // selectedRange.startDate,
    selectedSubEventId,
    selectedPlayerId,
  ]);
  useEffect(() => {
    getPerformances();
  }, [getPerformances]);

  const getPlayerEvents = async (e) => {
    setSelectedPlayerId(e);
    handlePlayerChange(e);
    setPeriod("all");
    setSelectedRange({
      startDate: new Date("2022-01-01"),
      endDate: new Date(currentDay),
      key: "selection",
    });
  };

  const handlePlayerChange = useCallback(
    async (id) => {
      const API =
        id === "All" || !id
          ? `/strength/userId/${userId}`
          : `/strength/userId/${id}`;
      await api
        .get(API)
        .then((res) => {
          getEventArray(
            new Set(res.data.map((item) => item.eventTypeId)),
            event,
            setPlayerEvents,
            setSelectedEvent
          );
        })
        .catch((err) => console.error(err));
    },
    [event, userId]
  );

  const GetPlayerSubEvent = useCallback(
    async (id) => {
      if (selectedPlayerId === "All") {
        return;
      }
      const API = `/strength/userId/${selectedPlayerId}`;
      await api
        .get(API)
        .then((res) => {
          getSubEventArray(
            new Set(res.data.map((item) => item["subEventsId"])),
            subEventList,
            setPlayerSubEvents,
            setSelectedSubEventId
          );
        })
        .catch((err) => console.error(err));
    },
    [selectedPlayerId, subEventList]
  );

  useEffect(() => {
    GetPlayerSubEvent();
  }, [GetPlayerSubEvent]);

  const getPlayerSubEvents = async (id) => {
    const API =
      selectedPlayerId === "All"
        ? `/strength/userId/${userId}`
        : `/strength/user/${selectedPlayerId}/${id}`;
    await api
      .get(API)
      .then((res) => {
        getSubEventArray(
          new Set(res.data.map((item) => item["subEventsId"])),
          subEventList,
          setPlayerSubEvents,
          setSelectedSubEventId
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (selectedEvent) {
      handleEventChange(selectedEvent);
    }
  }, [selectedEvent]);

  const handleSubEvent = (e) => {
    setSelectedSubEventId(e.target.value);
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e);
    getPlayerSubEvents(e);
  };

  useEffect(() => {
    if (selectedPlayerId === "All") {
      handlePlayerChange();
    }
  }, [handlePlayerChange, selectedPlayerId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  function setCellProperties(
    cell,
    value,
    isBold = false,
    color = "000000",
    size = 11
  ) {
    cell.value = value;
    cell.font = { bold: isBold, color: { argb: color }, size: size };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  }

  const handleSampleExcel = async () => {
    const sampleData = [
      "S.No",
      "Created Date",
      "Session",
      "Performance",
      "Player Id",
      "Sub Event",
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Practice Data");
    const optionsSheet = workbook.addWorksheet("Options");

    const players_data = player.map((item) => item.userName);
    const players_logId = player.map((item) => item.loginId);
    const subEvents = subEventList.map((item) => item.subEventName);
    worksheet.addRow(sampleData);

    players_logId.forEach((item, index) => {
      optionsSheet.getCell(`A${index + 2}`).value = item;
    });

    players_data.forEach((item, index) => {
      optionsSheet.getCell(`B${index + 2}`).value = item;
    });

    subEvents.forEach((item, index) => {
      optionsSheet.getCell(`D${index + 2}`).value = item;
    });

    const SESSION = ["Morning", "Evening"];

    worksheet.getCell("C2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${SESSION.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid Value from the list",
    };

    setCellProperties(
      optionsSheet.getCell("A1"),
      "Player Login ID",
      true,
      "FF0000",
      12
    );
    setCellProperties(
      optionsSheet.getCell("B1"),
      "Player Name",
      true,
      "FF0000",
      12
    );

    setCellProperties(
      optionsSheet.getCell("D1"),
      "Sub Event",
      true,
      "FF0000",
      12
    );

    setCellProperties(
      worksheet.getCell("F2"),
      "Get From Option Sheet",
      true,
      "FF0000",
      12
    );

    setCellProperties(
      worksheet.getCell("E2"),
      "Get From Option Sheet",
      true,
      "FF0000",
      12
    );

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "XIP Performance Bulk Upload Sample.xlsx");
  };

  const [bulkUploadConfirmPopupOpen, setBulkUploadConfirmPopupOpen] =
    useState(false);
  const handleBulkPopupOpen = () => {
    setBulkUploadConfirmPopupOpen(true);
  };
  const handleBulkPopupClose = () => {
    setBulkUploadConfirmPopupOpen(false);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setBulkUploadConfirmPopupOpen(false);
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        try {
          let parsedData = [];
          // workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets["Practice Data"];
          parsedData = XLSX.utils.sheet_to_json(sheet);
          // });
          if (parsedData.length <= 0) {
            toast("error", "No Data Found To Upload");
            return;
          }
          const mandatoryFields = ["Created Date", "Session", "Performance"];
          let formatWrong = [];
          //Check the mandatory fields are includes or not?
          parsedData.forEach((item, index) => {
            mandatoryFields.forEach((field) => {
              if (!(field in item) || item[field] === "") {
                formatWrong.push(parsedData[index]);
              }
            });
          });
          if (formatWrong.length > 0) {
            toast("error", "Your data not proper need to fill all the columns");
            return;
          }
          const formattedData = parsedData.map((item, index) => {
            const excelTimestamp = item["Created Date"];
            const unixTimestamp = (excelTimestamp - 25569) * 86400 * 1000;
            const date = new Date(unixTimestamp);

            const players_logId = player.find(
              (player_d) => player_d.loginId === item["Player Id"]
            )?.userId;
            const subEvents = subEventList.find(
              (sub) => sub.subEventName === item["Sub Event"]
            )?.subEventId;
            return {
              performance: item["Performance"],
              session: item["Session"],
              createDate: date.toISOString(),
              academyId,
              createBy: userId,
              coachId: userId,
              remarks: "",
              favorite: false,
              subEventId: subEvents,
              userId: players_logId,
              modifyDate: date.toISOString(),
            };
          });

          api
            .post(`/practice/bulkUpload`, formattedData)
            .then((res) =>
              toast("success", "Bulk Upload Completed Successfully")
            )
            .catch((err) => console.error(err));
          handleBulkUploadClose();
          getPerformances();
        } catch (error) {
          console.error("Error processing Excel file:", error);
        } finally {
          handleBulkUploadClose();
        }
      };
    } else {
      toast("error", "Please Select a File");
    }
  };

  const getRecords = useCallback(async () => {
    const playerData = player.find(
      (item) => parseInt(item.userId) === parseInt(selectedPlayerId)
    );
    if (selectedSubEventId === "All") {
      setSelectedRecord([]);
      return;
    }
    await api
      .get(
        `/record/subEventAndGender/${selectedSubEventId}/${
          playerData?.gender || "Male"
        }`
      )
      .then((res) => {
        setSelectedRecord(res.data);
      })
      .catch((err) => console.error(err));
  }, [player, selectedSubEventId, selectedPlayerId]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const handleFavorites = async (id) => {
    const perf = performances?.map((row) =>
      row.practiceId === id ? { ...row, favorite: !row.favorite } : row
    );
    setPerformances(perf);
    handleChangeFavorite(id);
  };

  const handleChangeFavorite = async (id) => {
    try {
      const response = await api
        .get(`/practice/${id}`)
        .then((res) => res.data)
        .catch((err) => console.error(err));
      const favoriteModification = {
        ...response,
        favorite: !response.favorite,
      };
      await api.put(`/practice/${id}`, favoriteModification);
    } catch (err) {
      const perf = performances?.map((row) =>
        row.practiceId === id ? { ...row, favorite: !row.favorite } : row
      );
      setPerformances(perf);
    }
  };

  const rows = performances.map((row, index) => {
    console.log(row);
    return {
      ...row,
      id: index + 1,
      personalBest:
        row.eventType === "Track"
          ? Extension.formatDurationWithMilliSecond(row?.personalBest)
          : row?.performance?.toFixed(2) || "--",
      // progress:Extension.formatDurationWithMilliSecond(row?.progress),
      createDate: Extension.convertDateFormatToDMY(row?.createDate),
      session: getIconForSession(row["session"]) || "--",
      performance:
        row.eventType === "Track"
          ? Extension.formatDurationWithMilliSecond(
              row?.performance?.toFixed(2)
            )
          : row?.performance?.toFixed(2) || "--",
      arrow:
        row.performanceStatus === "up" ? (
          <ArrowDropUpIcon sx={{ color: "green" }} />
        ) : row.performanceStatus === "down" ? (
          <ArrowDropDownIcon sx={{ color: "red" }} />
        ) : row.performanceStatus === "neutral" ? (
          <HorizontalRuleIcon sx={{ color: "violet" }} />
        ) : (
          "--"
        ),
      progress: row?.progress?.toFixed(2) || "--",
      userName: row.userName || "--",
      favorite: (
        <IconButton onClick={() => handleFavorites(row.practiceId)}>
          {row.favorite === false || row.favorite === null ? (
            <FavoriteBorderIcon color="error" />
          ) : (
            <FavoriteIcon color="error" />
          )}
        </IconButton>
      ),
      action: (
        <DeleteRounded
          color="error"
          onClick={() => handlePopupOpen(row.practiceId)}
        />
      ),
    };
  });

  const componentPdf = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const handleBeforePrint = () => {
    setIsVisible(true);
  };

  const handleAfterPrint = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible) {
      generateInvoice();
    }
  }, [isVisible]);

  const generateInvoice = useReactToPrint({
    content: () => componentPdf.current,
    documentTitle: "Performance Data",
    onAfterPrint: handleAfterPrint,
  });

  const handlePrint = () => {
    handleBeforePrint();
    setTimeout(() => {
      generateInvoice();
    }, 100);
  };

  const handleRadioChange = (data) => {
    setPeriod(data);
    if (data === "monthly") {
      setSelectedRange({
        startDate: new Date(monthly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (data === "weekly") {
      setSelectedRange({
        startDate: new Date(weekly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (data === "yearly") {
      setSelectedRange({
        startDate: new Date(yearly),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (data === "today") {
      setSelectedRange({
        startDate: new Date(currentDay),
        endDate: new Date(currentDay),
        key: "selection",
      });
    } else if (data === "all") {
      setSelectedRange({
        startDate: new Date("2010-01-01"),
        endDate: new Date(currentDay),
        key: "selection",
      });
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [pdfPlayer, setPdfPlayer] = useState({});

  useEffect(() => {
    if (selectedPlayerId !== "all") {
      const pdfData = player.find((user) => user.userId === selectedPlayerId);
      setPdfPlayer(pdfData);
    }
  }, [selectedPlayerId]);

  const [pdfEvents, setPdfEvents] = useState({});

  useEffect(() => {
    if (selectedSubEventId !== "") {
      const pdfData = playerSubEvents.find(
        (user) => user.subEventId === selectedSubEventId
      );
      setPdfEvents(pdfData);
    }
  }, [selectedSubEventId, playerSubEvents]);

  const dates = performances.map((user) => user.createDate);
  const uniqueDates = [...new Set(dates)];

  console.log("selectedSUbEventId", selectedSubEventId);

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <LoadingComponents loading={isLoading} />
      <CommonAlertPopup
        handleYes={handlePerformanceDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Are you sure you want to delete the Exercise?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <CommonAlertPopup
        handleYes={handleUpload}
        handleNo={handleBulkPopupClose}
        titleText={<p>Confirmation</p>}
        contentText={
          <p>
            {selectedBulkEventId ||
            selectedBulkPlayerID ||
            selectedBulkSubEventId
              ? "The Selected Fields will be put the optional for the not filled data. \nDo You want to continue?"
              : "Are You Sure Upload This File?"}
          </p>
        }
        open={bulkUploadConfirmPopupOpen}
        handleClose={handleBulkPopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Ok"}
      />
      <Grid
        container
        spacing={3}
        columns={{ xs: 1, sm: 1, md: 6, lg: 6, xl: 6 }}
      >
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label={"Filter By Player"}
            value={selectedPlayerId}
            onChange={(e) => getPlayerEvents(e.target.value)}
            array={player}
            menuValue={"userId"}
            display={"userName"}
          />
        </Grid>
        {/* <DateCalendarServerRequest userData= {rows}/> */}
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label="Filter By Sub Event"
            value={selectedSubEventId}
            onChange={handleSubEvent}
            array={playerSubEvents}
            menuValue={"subEventId"}
            display={"subEventName"}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RadioBtnFilter
            handleRadioChange={(data) => handleRadioChange(data)}
            option={period}
          />
          <Box sx={{ ml: 2 }}>
            <button onClick={handleClick} className="icon-button">
              <CalendarMonth />
            </button>
            {/* <CustomDateRangePicker /> */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DateRange
                editableDateInputs
                onChange={handleDateRangeChange}
                moveRangeOnFirstSelection={false}
                ranges={[selectedRange]}
              />
            </Popover>
          </Box>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          lg={2}
          xl={2}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {selectedSubEventId !== "All" &&
            selectedRecord?.worldValue &&
            selectedRecord?.nationalValue &&
            selectedRecord?.stateValue && (
              <>
                <Tooltip title={"World Record"}>
                  <div className="icon-with-font">
                    {CommonWorldIcon}{" "}
                    <div>{selectedRecord?.worldValue || 0}</div>
                  </div>
                </Tooltip>
                <Tooltip title={"National Record"}>
                  <div className="icon-with-font">
                    {CommonFlagIcon}
                    <div>{selectedRecord?.nationalValue || 0}</div>
                  </div>
                </Tooltip>
                <Tooltip title={"State Record"}>
                  <div className="icon-with-font">
                    {CommonStateIcon}{" "}
                    <div>{selectedRecord?.stateValue || 0}</div>
                  </div>
                </Tooltip>
              </>
            )}
        </Grid>
        <Grid
          item
          xs={1}
          md={1}
          lg={1}
          xl={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
            <Box sx={{ ml: 2, position: "relative", height: "100%" }}>
              <CustomCommonButton
                tooltipText="Performance Bulk Upload"
                icon={<WorkspacePremium sx={{ opacity: 0.5 }} />}
                onClick={handleBulkUploadOpen}
              />
              <Box sx={{ position: "absolute", bottom: "-5px", right: "3px" }}>
                <CloudUpload
                  sx={{
                    backgroundColor: "unset",
                    color: "white",
                    opacity: 0.5,
                    fontSize: "18px",
                  }}
                />
              </Box>
            </Box>
            {/* <CustomCommonButton
              icon={<CloudUpload />}
              onClick={handleBulkUploadOpen}
              tooltipText="Bulk Upload"
            />{" "} */}
            <Box sx={{ ml: 2, position: "relative", height: "100%" }}>
              <CustomCommonButton
                tooltipText="Performance Report"
                icon={<WorkspacePremium sx={{ opacity: 0.5 }} />}
                onClick={handlePrint}
              />
              <Box sx={{ position: "absolute", bottom: "-5px", right: "3px" }}>
                <Download
                  sx={{
                    backgroundColor: "unset",
                    color: "white",
                    opacity: 0.5,
                    fontSize: "18px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Modal keepMounted open={bulkUploadOpen} onClose={handleBulkUploadClose}>
        <Box sx={Style.widthPopupStyle}>
          <Grid
            container
            columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
            spacing={2}
          >
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <Typography variant="h4" sx={Style.titleText}>
                Bulk Upload
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
              <CustomCommonButton
                text={"Upload File"}
                onClick={() => document.getElementById("file-upload").click()}
                icon={<CloudUpload />}
                fullWidth={"100%"}
              />
              <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                accept=".xlsx"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={3}
              lg={3}
              xl={3}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                {selectedFile
                  ? `Selected File: ${selectedFile.name}`
                  : "No File Selected"}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                label={"Select Player"}
                value={selectedBulkPlayerID}
                onChange={getBulkEvents}
                array={player}
                menuValue={"userId"}
                display={"userName"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                // firstOption={"All"}
                // search={true}
                label="Select Event"
                value={selectedBulkEventId}
                onChange={(e) => handleBulkEvent(e.target.value)}
                array={bulkEvent}
                menuValue={"eventTypeId"}
                display={"eventType"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                label="Select Sub Event"
                value={selectedBulkSubEventId}
                onChange={(e) => setSelectedBulkSubEventId(e.target.value)}
                array={bulkSubEvent}
                menuValue={"subEventId"}
                display={"subEventName"}
              />
            </Grid>

            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Sample Excel"}
                onClick={handleSampleExcel}
                icon={<Download />}
                fullWidth={"100%"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
              <CustomCommonButton
                text={"Upload"}
                onClick={handleBulkPopupOpen}
                disabled={!selectedFile}
                fullWidth={"100%"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ marginBottom: "60px" }}>
        <GridTable rows={rows} columns={GetData.performance.columns} />
      </Box>
      <div
        ref={componentPdf}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <Box sx={{ marginTop: "15px" }}>
          {/* <Grid item xs={6} sm={12} md={12} lg={12}> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: "row",
              fontSize: "var(--default-a4-font-size)",
            }}
          >
            <img
              src={logo}
              style={{
                width: "var(--default-a4-font-size)",
                height: "var(--default-a4-font-size)",
                marginRight: "8px",
              }}
              alt="logo"
            />{" "}
            <Typography
              sx={{
                // fontSize: "var(--default-a4-font-size)",
                display: "flex",
                alignItems: "center",
                marginTop: "2px",
                fontSize: "var(--default-a4-header-font-size)",
              }}
            >
              XI Performer
            </Typography>
          </Box>
          {/* </Grid> */}
          {selectedPlayerId !== "All" && (
            <Grid container spacing={2}>
              <Grid item xs={6} sm={12} md={12} lg={4}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-header-font-size)",
                      textDecoration: "underline",
                    }}
                  >
                    Player Info{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    Player Name: &nbsp;{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    {" "}
                    {pdfPlayer !== undefined ? pdfPlayer.userName : ""}{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    Player ID: &nbsp;{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    {" "}
                    {pdfPlayer !== undefined ? pdfPlayer.loginId : ""}{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    Date Joined: &nbsp;{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    {" "}
                    {pdfPlayer !== undefined
                      ? new Date(pdfPlayer.createDate).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                      : ""}{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={12}
                md={4}
                lg={4}
                sx={{ borderBottom: "1px solid #dddddd" }}
              ></Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={12} lg={4}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "var(--font-family)",
                    textDecoration: "underline",
                    fontSize: "var(--default-a4-header-font-size)",
                    marginTop: "8px",
                  }}
                >
                  Performance Data{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={12} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Date Generated: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {format(selectedRange.startDate, "dd-MMM-yyyy")} to{" "}
                  {format(selectedRange.endDate, "dd-MMM-yyyy")}{" "}
                </Typography>
              </Box>
            </Grid>
            {selectedSubEventId !== "All" && (
              <Grid item xs={6} sm={6} md={4} lg={4}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    Sub Event Name: &nbsp;{" "}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    {" "}
                    {pdfEvents?.subEventName}{" "}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  Personal Best: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--default-a4-font-size)",
                  }}
                >
                  {" "}
                  {performances.length > 0
                    ? performances[0].personalBest
                    : ""}{" "}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={4} lg={4}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  Date Exiting: &nbsp;{" "}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: "var(--font-family)" }}
                >
                  {" "}
                </Typography>
              </Box>
            </Grid> */}
            <Grid
              item
              xs={6}
              sm={12}
              md={4}
              lg={4}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
            <Grid item xs={6} sm={12} md={12} lg={12}>
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead>
                  <tr
                    style={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--default-a4-font-size)",
                    }}
                  >
                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      S.No
                    </th>
                    {selectedPlayerId === "All" && (
                      <th
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        Player Name
                      </th>
                    )}
                    {selectedSubEventId === "All" && (
                      <th
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        Sub Event Name
                      </th>
                    )}

                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Session
                    </th>
                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Perf in sec
                    </th>
                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Perf. Status
                    </th>
                    <th
                      style={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      Progress
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.length > 0 ? (
                    rows.map((row) => (
                      <tr
                        key={row.id}
                        style={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--default-a4-font-size)",
                        }}
                      >
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.id}
                        </td>
                        {selectedPlayerId === "All" && (
                          <td
                            style={{
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--default-a4-font-size)",
                            }}
                          >
                            {row.userName}
                          </td>
                        )}
                        {selectedSubEventId === "All" && (
                          <td
                            style={{
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--default-a4-font-size)",
                            }}
                          >
                            {row.subEventName}
                          </td>
                        )}

                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.createDate}
                        </td>
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.session}
                        </td>
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.performance}
                        </td>
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.arrow}
                        </td>
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                        >
                          {row.progress}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {selectedPlayerId !== "All" && (
                        <td
                          style={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--default-a4-font-size)",
                          }}
                          colSpan={7}
                        >
                          No Data
                        </td>
                      )}
                      {selectedPlayerId === "All" &&
                        selectedSubEventId === "All" && (
                          <td
                            style={{
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--default-a4-font-size)",
                            }}
                            colSpan={8}
                          >
                            No Data
                          </td>
                        )}
                      {selectedPlayerId === "All" &&
                        selectedSubEventId !== "All" && (
                          <td
                            style={{
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--default-a4-font-size)",
                            }}
                            colSpan={7}
                          >
                            No Data
                          </td>
                        )}
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
            <Grid
              item
              xs={6}
              sm={12}
              md={12}
              lg={12}
              sx={{ borderBottom: "1px solid #dddddd" }}
            ></Grid>
          </Grid>
          {selectedPlayerId !== "All" && (
            <>
              <div style={{ pageBreakBefore: "always" }}></div>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={12} md={12} lg={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-header-font-size)",
                        textDecoration: "underline",
                      }}
                    >
                      Attendance Details{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      No.of Present Days: &nbsp;{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      {" "}
                      {uniqueDates.length}{" "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--default-a4-font-size)",
                      }}
                    >
                      No.of Absent Days: &nbsp;{" "}
                    </Typography>
                    <RemainingDays dates={performances} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ borderBottom: "1px solid #dddddd" }}
                ></Grid>
                <Grid item xs={6} sm={12} md={12} lg={12}>
                  <DateCalendarServerRequest userData={rows} />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </div>
    </Box>
  );
};
