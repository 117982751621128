import axios from "axios";

export default axios.create({
  // baseURL: "http://44.199.212.99:8089/",
  // baseURL: "http://18.204.23.149:8089/",
  baseURL: "https://api.jslpro.in:4654",
  // baseURL: "http://18.204.23.149:8086/",
  // baseURL: "http://localhost:8094/",
  // baseURL: "http://3.227.244.140:8086/",
  //baseURL: "http://3.227.244.140:8083/",
  headers: {
    "Content-type": "application/json",
  },
});
