import {
  Accessibility,
  ChevronRight,
  MonitorWeight,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

const HeightAndWeight = ({ biometric }) => {
  const HEIGHT_WEIGHT = [
    {
      id: 1,
      name: "Height (cm)",
      value: (biometric && biometric[0]?.height) || 0,
      icon: <Accessibility className="header-icon" style={{fontSize:"55px"}} />,
    },
    {
      id: 2,
      name: "Weight (kg)",
      value: (biometric && biometric[0]?.weight) || 0,
      icon: <MonitorWeight className="header-icon" style={{fontSize:"55px"}} />,
    },
  ];
  return (
    <Grid
      container
      columnSpacing={5}
      rowSpacing={1.5}
      columns={{
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
      }}
      sx={{ pl: { sm: "", xs: "", md: 3 }, pr: { sm: "", xs: "", md: 3 } }}
    >
      {HEIGHT_WEIGHT.map((item) => (
        <Grid item xs={1} md={1} sm={1} lg={1} key={item.id}>
          <Paper elevation={0} className="height-weight-container">
          <div style={{ fontFamily: "var(--font-family)", display:"flex", alignItems:"center"}}>
            {item.icon}
            </div>
            <div className="height-weight-value">
              <div className="header" style={{ fontFamily: "var(--font-family)",fontSize: "var(--body-md-font-size)", }}>
                {item.name}
                {/* <ChevronRight /> */}
              </div>
              <div className="value">
                <h1>
                  <CountUp
                    start={0}
                    end={item.value}
                    duration={2.5}
                    style={{ fontFamily: "var(--font-family)",fontSize: "var(--heightWeight-font-size)", }}
                  />
                </h1>
              </div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default HeightAndWeight;
