import { InputAdornment, TextField } from "@mui/material";
import React from "react";

const CommonTextField = (props) => {
  const {
    value,
    name,
    onChange,
    label,
    type,
    readOnly,
    position,
    comboFixWord,
    maxLength,
    multiline,
    required,
    register,
    disabled,
    rows,
  } = props;

  const Type = type ?? "text";

  const handleInputChange = (e) => {
    if (Type === "number") {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "")
        .slice(0, maxLength);
    } else if (Type === "text") {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange(e);
  };

  const stylesCSS = {
    input: {
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
    },
  };
  return (
    <TextField
      type={Type === "" || Type == null ? "text" : Type}
      label={label}
      id={name}
      name={name}
      {...register}
      required={required ?? false}
      sx={{
        backgroundColor: readOnly
          ? "var(--disabled-field)"
          : "var(--text-field)",
        color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        borderRadius: "var(--roundness)", // Add border radius here
        "& textarea": {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
        },
        "& .MuiSvgIcon-root,.MuiTypography-root": {
          color: readOnly ? "var(--disabled-font)" : "var(--font-color)", // Change the color of the dropdown icon
        },
      }}
      defaultValue={type === "date" && new Date()}
      fullWidth
      inputProps={{
        style: {
          minHeight: multiline ? "" : "35px",
          maxHeight: multiline ? "" : "40px",
          color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
        },
      }}
      disabled={disabled}
      value={value}
      onChange={handleInputChange}
      size="small"
      onKeyDown={(e) => {
        if (Type === "number") {
          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
        } else {
          return;
        }
      }}
      multiline={multiline === "" ? false : multiline}
      rows={multiline ? rows : ""}
      InputProps={{
        sx: { borderRadius: "var(--roundness)" },
        readOnly: readOnly == null || readOnly === "" ? false : readOnly,
        startAdornment: position === "start" && (
          <InputAdornment position={position}>{comboFixWord}</InputAdornment>
        ),
        endAdornment: position === "end" && (
          <InputAdornment position={position}>{comboFixWord}</InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: {
          color: "var(--font-color)", // Add your label style here
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          fontWeight: "bold",
        },
      }}
    />
  );
};

export default CommonTextField;
