import { message } from "antd";

const ToastMessage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const toast = (type, content) => {
    messageApi.open({
      key,
      type: type,
      content: content,
      style: {
        fontSize: "var(--font-size)",
        fontFamily: "var(--font-family)",
        width: "100%",
        float: "right",
      },
    });
  };
  return { toast, contextHolder };
};

export default ToastMessage;
//types : error, success, info, warning, loading
