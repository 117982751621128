import {
  DeleteRounded,
  PostAdd,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Modal,
  OutlinedInput,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CustomTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import Extension from "../../Common/Extension";

const CombinationAdd = () => {
  // Combination Add page style function
  const style = {
    formControl: {
      width: { md: "22%", sm: "40%", xs: "25%" },
      m: 2,
      fontFamily: "var(--font-family)",
    },
    formContro2: {
      width: { md: "12%", sm: "20%", xs: "25%" },
      m: 2,
      fontFamily: "var(--font-family)",
    },
    label: {
      color: "#000",
      fontWeight: 700,
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        color: "#000",
        fontSize: "20px",
        fontFamily: "var(--font-family)",
      },
    },
    inputFiled: {
      color: "#000",
      background: "#FFFFFF",
      borderRadius: "10px",
      fontSize: "20px",
      fontFamily: "var(--font-family)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
      },
      "&:hover > .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
      },
    },
    inputFields: {
      color: "#000",
      //background: "#FFFFFF",
      fontSize: "18px",
      fontFamily: "var(--font-family)",
      marginTop: "35px",
    },
  };

  // Combination Add Page table header data

  const userId = sessionStorage.getItem("userId");
  const [activity, setActivity] = useState([]);
  const [open, setOpen] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [sets, setSets] = useState();
  const [rep, setRep] = useState();
  const [rest, setRest] = useState();
  const [time, setTime] = useState();
  const [combinationName, setCombinationName] = useState("");
  const [activityValue, setActivityValue] = useState("");
  const [warmupList, setWarmupList] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [noOfSet, setNoOfSet] = useState([]);
  const [noOfRep, setNoOfRep] = useState([]);
  const [noOfRest, setNoOfRest] = useState([]);
  const [noOfTime, setNoOfTime] = useState([]);
  const [warmUpId, setWarmUpId] = useState([]);
  const [combinationNameError, setCombinationNameError] = useState("");
  const [activityNameError, setActivityNameError] = useState("");
  const [selectedOption, setSelectedOption] = useState("collaborate");
  const [selectOptionComb, setSelectOptionComb] = useState("collaborate");
  const [isPublicActivity, setIsPublicActivity] = useState(true);
  const [isPublicCombination, setIsPublicCombination] = useState(true);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const { toast, contextHolder } = ToastMessage();
  const [academyDatas, setAcademyData] = useState({});
  const [isActiveTimeSeconds, setIsActiveTimeSeconds] = useState(true);
  const [isRestTimeSeconds, setIsRestTimeSeconds] = useState(true);

  console.log("After selectedOption:", isPublicActivity);
  console.log("After selectOptionComb:", isPublicCombination);

  console.log("activityValue", academyDatas);

  const navigate = useNavigate();

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  var initialExercise = {
    warmUpId: "",
    noOfSet: 0,
    noOfRep: 0,
    noOfRest: "00:00",
    noOfTime: "00:00",
  };
  const [exerciseValue, setExerciseValue] = useState(initialExercise);

  // This is new activity add open and close model function
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Combination Add page Activity get function
  async function getActivity() {
    try {
      const response = await api.get(`/activity/createdBy/isPublic/${userId}`);
      setActivity(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }
  // Combination Add page Exercise get function
  async function getExercise() {
    try {
      const response = await api.get(`/warmUp/createdBy/${userId}`);
      setExercise(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  /* New Activity add in combination page */
  async function handleActivityAdd() {
    if (!activityName) {
      setActivityNameError("Please enter a Activity Name");
      return;
    }
    setActivityNameError("");
    const activityExists = activity.some(
      (act) => act.activityName === activityName
    );

    if (activityExists) {
      setActivityNameError(
        "Activity with this name already exists. Please choose a different name."
      );
      return;
    }

    const academyData = {
      activityName: activityName,
      academyId: sessionStorage.getItem("academyId"),
      createdBy: sessionStorage.getItem("userId"),
      createdDate: getCurrentSQLDate(),
      isPublic: isPublicActivity,
      modifiedBy: userId,
      modifiedDate: getCurrentSQLDate(),
    };
    setAcademyData(academyData);
    try {
      const response = await api.post("/activity/add", academyData);
      console.log(response.data);
      setActivityName("");
      getActivity();
      handleClose();
    } catch (err) {
      console.log("Error", err);
    }
  }

  // Current date get function
  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleIsPublic = async (e) => {
    setActivityValue(e);
    const response = await api.get(`/activity/${e}`);
    setAcademyData(response.data);
  };

  // Combination Add page Submit function
  async function postCombination() {
    if (rows.length <= 0) {
      toast("error", "Please add At-least one Combination");
    }
    try {
      setCombinationNameError("");
      setAddButtonDisable(true);
      if (!combinationName) {
        setCombinationNameError("Please enter a Combination Name");
        return;
      }

      const warmUpCombinationValue = {
        warmUpCombinationName: combinationName,
        activityId: activityValue,
        academyId: sessionStorage.getItem("academyId"),
        createdBy: sessionStorage.getItem("userId"),
        createdDate: getCurrentSQLDate(),
        isPublic: isPublicCombination,
        noOfUses: 0,
        noOfViews: 0,
        modifiedBy: userId,
        modifiedDate: getCurrentSQLDate(),
      };

      console.log("warmupCombination", warmUpCombinationValue);
      let exercisesAdded = false;
      console.log("selectOptionComb:", selectOptionComb);
      console.log("selectedOption:", selectedOption);
      if (selectOptionComb === "private" && selectedOption === "collaborate") {
        console.log("Before setIsPublicActivity:", isPublicActivity);
        setIsPublicActivity(false);
        const res = await api.get(`/activity/${activityValue}`);
        console.log(res.data);
        const academyData = {
          activityName: res.data.activityName,
          academyId: sessionStorage.getItem("academyId"),
          createdBy: sessionStorage.getItem("userId"),
          createdDate: getCurrentSQLDate(),
          isPublic: true,
          modifiedBy: userId,
          modifiedDate: getCurrentSQLDate(),
        };

        const response = await api.put(
          `/activity/${activityValue}`,
          academyData
        );

        console.log("Response from API:", response.data);

        if (warmUpId.length > 0) {
          const warmUpCombinationRes = await api.post(
            "/warmUpCombination/add",
            warmUpCombinationValue
          );
          console.log("successfully added", warmUpCombinationRes);

          if (warmUpCombinationRes.data.nameExists) {
            setCombinationNameError(
              "This name already exists. Please choose a different name."
            );
          } else {
            let i = 0;
            for (let item of warmUpId) {
              console.log(warmUpId);
              const warmUpDetailsAdd = {
                warmUpId: item,
                noOfSet: noOfSet[i],
                rep: noOfRep[i],
                rest: Extension.convertDurationIntoSeconds(noOfRest[i]),
                time: Extension.convertDurationIntoSeconds(noOfTime[i]),
                warmUpCombinationId:
                  warmUpCombinationRes.data.warmUpCombinationId,
                createdBy: sessionStorage.getItem("userId"),
                createdDate: getCurrentSQLDate(),
                academyId: sessionStorage.getItem("academyId"),
                modifiedBy: userId,
                modifiedDate: getCurrentSQLDate(),
              };
              i++;
              console.log(warmUpDetailsAdd);
              const warmUpCombinationResponse = await api.post(
                "/warmUpDetails/add",
                warmUpDetailsAdd
              );
              console.log(warmUpCombinationResponse.data);
            }
            exercisesAdded = true;
          }
        } else {
          toast("error", "Please add at least one exercise");
        }
        if (exercisesAdded) {
          navigate("/Combination");
        }
      } else {
        if (warmUpId.length > 0) {
          const warmUpCombinationRes = await api.post(
            "/warmUpCombination/add",
            warmUpCombinationValue
          );
          console.log("successfully added", warmUpCombinationRes);

          if (warmUpCombinationRes.data.nameExists) {
            setCombinationNameError(
              "This name already exists. Please choose a different name."
            );
          } else {
            var i = 0;
            for (let item of warmUpId) {
              console.log(warmUpId);
              const warmUpDetailsAdd = {
                warmUpId: item,
                noOfSet: noOfSet[i],
                rep: noOfRep[i],
                rest: Extension.convertDurationIntoSeconds(noOfRest[i]),
                time: Extension.convertDurationIntoSeconds(noOfTime[i]),
                warmUpCombinationId:
                  warmUpCombinationRes.data.warmUpCombinationId,
                createdBy: sessionStorage.getItem("userId"),
                createdDate: getCurrentSQLDate(),
                academyId: sessionStorage.getItem("academyId"),
              };
              i++;
              console.log(warmUpDetailsAdd);
              const warmUpCombinationResponse = await api.post(
                "/warmUpDetails/add",
                warmUpDetailsAdd
              );
              console.log(warmUpCombinationResponse.data);
            }
            exercisesAdded = true;
          }
        } else {
          toast("error", "Please add At-least one exercise");
        }
        if (exercisesAdded) {
          navigate("/Combination");
        }
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setAddButtonDisable(false);
    }
  }

  //  COmbination Add page Combination And Activity empty value error message function
  const handleExerciseAdd = () => {
    if (!combinationName) {
      toast(
        "error",
        "Please Enter a Combination Name before adding an exercise."
      );
    } else if (!activityValue) {
      toast("error", "Please select an Activity before adding an exercise.");
    } else if (!exerciseValue.warmUpId) {
      toast("error", "Please select an Exercise");
    } else {
      addSingleExercise();
    }
  };
  useEffect(() => {
    console.log("isPublicActivity changed:", isPublicActivity);
  }, [isPublicActivity]);

  useEffect(() => {
    getActivity();
    getExercise();
  }, []);

  // Exercise Add table function
  function addSingleExercise() {
    console.log("selectOptionComb:", selectOptionComb);
    var alreadyExist = warmupList.filter((item) => {
      return item.warmUpId === exerciseValue.warmUpId;
    });
    if (alreadyExist.length > 0) {
      toast("error", "The exercise already exists!");
      setExerciseValue(initialExercise);
    } else if (
      (academyDatas.isPublic === false && isPublicCombination === false) ||
      (academyDatas.isPublic === true && isPublicCombination === false) ||
      (academyDatas.isPublic === true && isPublicCombination === true)
    ) {
      const rest = exerciseValue.noOfRest;
      const active = exerciseValue.noOfTime;
      let singleExercise = exercise
        .map((item) => {
          if (item.warmUpId === exerciseValue.warmUpId) {
            return {
              ...item,
              assignSet: exerciseValue.noOfSet,
              rep: exerciseValue.noOfRep,
              time: active,
              rest: rest,
            };
          }
          return item;
        })
        .filter((item) => item.warmUpId === exerciseValue.warmUpId);

      setExerciseValue(initialExercise);

      setWarmupList([...warmupList, singleExercise[0]]);
      setWarmUpId([...warmUpId, singleExercise[0].warmUpId]);
      setNoOfSet([...noOfSet, exerciseValue.noOfSet]);
      setNoOfRep([...noOfRep, exerciseValue.noOfRep]);
      setNoOfRest([...noOfRest, rest]);
      setNoOfTime([...noOfTime, active]);
    } else if (
      academyDatas.isPublic === false &&
      isPublicCombination === true
    ) {
      toast(
        "warning",
        ` You cannot create a combination exercise in public when the activity is in private`
      );
    }
  }

  //  Combination Add page table delete function
  const handleDelete = (index) => {
    const updatedWarmupList = [...warmupList];
    const updatedWarmupId = [...warmUpId];
    updatedWarmupId.splice(index, 1);
    updatedWarmupList.splice(index, 1);
    console.log(updatedWarmupList);
    setWarmupList(updatedWarmupList);
    setWarmUpId(updatedWarmupId);
  };

  const handleActivity = (option) => {
    try {
      if (option === "collaborate") {
        setSelectedOption("collaborate");
        setIsPublicActivity(true);
      } else if (option === "private") {
        setSelectedOption("private");
        setIsPublicActivity(false);
      }
    } catch (err) {
      console.log("error in handleActivity", err);
    }
  };

  const handleCombination = (option) => {
    try {
      if (option === "collaborate") {
        setSelectOptionComb("collaborate");
        setIsPublicCombination(true);
      } else if (option === "private") {
        setSelectOptionComb("private");
        setIsPublicCombination(false);
      }
    } catch (err) {
      console.log("error in handleCombination");
    }
  };

  const rows = warmupList.map((item, index) => {
    return {
      id: index + 1,
      exercise: item.warmUpName || "--",
      type: item.type || "--",
      unit: item.unit || "--",
      noOfSet: item.assignSet || 0,
      reps: item.rep || 0,
      rest: item.rest || 0,
      activeTime: item.time || 0,
      action: (
        <DeleteRounded
          className="icon-button-style"
          onClick={() => handleDelete(index)}
          color="error"
        />
      ),
    };
  });

  const navigatePath = () => navigate("/Combination");

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Box>
        <CustomCommonButton
          onClick={navigatePath}
          variant="contained"
          text="BACK"
          icon={<ArrowCircleLeftIcon />}
        />
      </Box>
      <Grid
        container
        spacing={2}
        mt={0.4}
        columns={{ sm: 1, md: 5, lg: 5, xl: 5 }}
      >
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CustomTextField
            value={combinationName}
            name="Combination Name"
            onChange={(e) =>
              setCombinationName(capitalizeFirstLetter(e.target.value))
            }
            label="Combination Name"
            type="text"
          />
        </Grid>

        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            label={"Activity"}
            value={activityValue}
            onChange={(e) => {
              handleIsPublic(e.target.value);
            }}
            array={activity}
            menuValue={"activityId"}
            display={"activityName"}
          />
        </Grid>
        <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
          <CustomCommonButton
            tooltipText="Add Activity"
            onClick={handleOpen}
            icon={<PostAdd sx={{ fontSize: "40px" }} />}
          />
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // marginTop: 25,
                // marginLeft: 60,
                width: 350,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: (theme) => theme.shadows[5],
                p: 4,
              }}
            >
              <FormControl variant="outlined" sx={{ mb: 2 }}>
                <InputLabel
                  sx={style.label}
                  style={{
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  Enter your Activity
                </InputLabel>
                <OutlinedInput
                  sx={{
                    ...style.inputFiled,
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                  id="activity"
                  type="text"
                  label="Enter your Activity"
                  onChange={(e) => setActivityName(e.target.value)}
                />
                {activityNameError && (
                  <div style={{ color: "red" }}>{activityNameError}</div>
                )}
              </FormControl>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Checkbox
                    checked={selectedOption === "private"}
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "#F8F9F5",
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                          borderRadius: "60%",
                          marginTop: "5px",
                        }}
                      />
                    }
                    checkedIcon={
                      <RadioButtonChecked
                        sx={{
                          color: "var(--app-bar-color)",
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                          borderRadius: "60%",
                        }}
                      />
                    }
                    value={selectedOption}
                    onChange={() => handleActivity("private")}
                  />
                  <FormControl
                    sx={{
                      ...style.inputFields,
                      marginTop: "10px",
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                  >
                    Private
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Checkbox
                    checked={selectedOption === "collaborate"}
                    icon={
                      <RadioButtonUnchecked
                        sx={{
                          color: "#F8F9F5",
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                          borderRadius: "60%",
                          marginTop: "1px",
                        }}
                      />
                    }
                    checkedIcon={
                      <RadioButtonChecked
                        sx={{
                          color: "var(--app-bar-color)",
                          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                          borderRadius: "60%",
                        }}
                      />
                    }
                    value={selectedOption}
                    onChange={() => handleActivity("collaborate")}
                  />
                  <FormControl
                    sx={{
                      ...style.inputFields,
                      marginTop: "8px",
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                  >
                    Collaborate
                  </FormControl>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <Button
                  sx={{
                    color: "#fff",
                    backgroundColor: "var(--app-bar-color)",
                    fontWeight: 700,
                    width: "50%",
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                    "&:hover": {
                      backgroundColor: "#4498e1",
                    },
                  }}
                  onClick={handleActivityAdd}
                >
                  Add Activity
                </Button>
              </Box>
            </Box>
          </Modal>
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            label={"Exercise Name"}
            value={exerciseValue.warmUpId}
            onChange={(e) => {
              setExerciseValue((previous) => {
                return { ...previous, warmUpId: e.target.value };
              });
            }}
            array={exercise}
            menuValue={"warmUpId"}
            display={"warmUpName"}
          />
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  ml: 2,
                }}
                checked={selectOptionComb === "private"}
                icon={
                  <RadioButtonUnchecked
                    sx={{
                      color: "#F8F9F5",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                      borderRadius: "50%",
                    }}
                  />
                }
                checkedIcon={
                  <RadioButtonChecked
                    sx={{
                      color: "var(--app-bar-color)",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                      borderRadius: "50%",
                    }}
                  />
                }
                value="private"
                onChange={() => handleCombination("private")}
              />
            }
            label={
              <FormControl
                sx={{
                  ml: 1,
                  color: "#000",
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Private
              </FormControl>
            }
            labelPlacement="end"
          />

          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  ml: 2,
                }}
                checked={selectOptionComb === "collaborate"}
                icon={
                  <RadioButtonUnchecked
                    sx={{
                      color: "#F8F9F5",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                      borderRadius: "50%",
                    }}
                  />
                }
                checkedIcon={
                  <RadioButtonChecked
                    sx={{
                      color: "var(--app-bar-color)",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                      borderRadius: "50%",
                    }}
                  />
                }
                value="collaborate"
                onChange={() => handleCombination("collaborate")}
              />
            }
            label={
              <FormControl
                sx={{
                  ml: 1,
                  color: "#000",
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                Collaborate
              </FormControl>
            }
            labelPlacement="end" // Place the label to the start (left) of the checkbox
          />
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CustomTextField
            value={exerciseValue.noOfSet}
            name="No.of Sets"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (
                inputValue === "" ||
                (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
              ) {
                setExerciseValue((previous) => {
                  return { ...previous, noOfSet: inputValue };
                });
                setSets(inputValue === "" ? "" : parseInt(inputValue));
              }
            }}
            label="No. of Sets"
            type="number"
          />
        </Grid>

        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CustomTextField
            value={exerciseValue.noOfRep}
            name="Reps"
            onChange={(e) => {
              const inputValue = e.target.value;
              if (
                inputValue === "" ||
                (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
              ) {
                setExerciseValue((previous) => {
                  return { ...previous, noOfRep: inputValue };
                });
                setRep(inputValue === "" ? "" : parseInt(inputValue));
              }
            }}
            label="Reps"
            type="number"
          />
        </Grid>

        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CustomTextField
            value={exerciseValue.noOfRest}
            name="Rest"
            inputProps={{
              maxLength: 5,
            }}
            onChange={(e) => {
              const value = Extension.autoFormatDuration(e.target.value);
              console.log(value);
              setExerciseValue((previous) => {
                return { ...previous, noOfRest: value };
              });
              setRest(value);
            }}
            label="Rest (mm:ss)"
            // type="number"
          />
        </Grid>
        {/* <Grid
          item
          xs={1}
          sm={1}
          md={0.3}
          lg={0.3}
          xl={0.3}
          sx={{ display: "flex", alignItems: "end" }}
        >
          <CustomCommonButton
            onClick={() => setIsRestTimeSeconds(!isRestTimeSeconds)}
            text={isRestTimeSeconds ? "m" : "s"}
          />
        </Grid> */}
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CustomTextField
            value={exerciseValue.noOfTime}
            name="Active Time"
            inputProps={{
              maxLength: 5,
            }}
            onChange={(e) => {
              const value = Extension.autoFormatDuration(
                e.target.value,
                setRest
              );
              console.log(value);
              setExerciseValue((previous) => {
                return { ...previous, noOfTime: value };
              });
              setTime(value === "" ? "" : parseInt(value));
            }}
            label="Active (mm:ss)"
            // type="number"
          />
        </Grid>
        {/* <Grid
          item
          xs={1}
          sm={1}
          md={0.3}
          lg={0.3}
          xl={0.3}
          sx={{ display: "flex", alignItems: "end" }}
        >
          <CustomCommonButton
            onClick={() => setIsActiveTimeSeconds(!isActiveTimeSeconds)}
            text={isActiveTimeSeconds ? "m" : "s"}
          />
        </Grid> */}
        <Grid
          item
          xs={1}
          md={0.3}
          lg={0.3}
          xl={0.3}
          sx={{ display: "flex", alignItems: "end" }}
        >
          <CustomCommonButton
            tooltipText="Add Exercise"
            disabled={
              (!exerciseValue.warmUpId &&
                !exerciseValue.noOfSet &&
                !exerciseValue.noOfRep &&
                !exerciseValue.noOfRest &&
                !exerciseValue.noOfTime) ||
              (exerciseValue.noOfSet <= 0 &&
                exerciseValue.noOfRep <= 0 &&
                exerciseValue.noOfRest === "00:00" &&
                exerciseValue.noOfTime === "00:00")
            }
            onClick={handleExerciseAdd}
            icon={<PostAdd sx={{ fontSize: "40px" }} />}
          />
        </Grid>
        <Grid
          item
          xs={1}
          md={0.5}
          lg={0.5}
          xl={0.5}
          sx={{ display: "flex", alignItems: "end" }}
        >
          <CustomCommonButton
            disabled={addButtonDisable}
            tooltipText="Add Combination"
            onClick={postCombination}
            icon={<PlaylistAddIcon sx={{ fontSize: "40px" }} />}
          />
        </Grid>
      </Grid>

      <GridTable columns={GetData.combinationAdd} rows={rows} height={"55vh"} />
    </Box>
  );
};
export default CombinationAdd;
