import {
  EditNoteRounded
} from "@mui/icons-material";
import {
  Box,
  Paper,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import { CommonAddIcon } from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";

export const AcademyList = () => {
  const navigation = useNavigate();
  const [academy, setAcademy] = useState([]);
  const [search, setSearch] = useState("");
  async function getAcademy() {
    try {
      const response = await api.get("academy/all");
      console.log(response.data);
      setAcademy(response.data);
      // const res = academy.map(item=>item.academyId)
      // console.log(res)
    } catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {
    getAcademy();
  }, []);
  const filteredData = academy
    ? academy.filter((item) => {
        return item.academyName.toLowerCase().includes(search.toLowerCase());
      })
    : [];

  // const handleDelete= async(academyId)=>{
  //    const response = await api.delete(`/academy/${academyId}`)
  //    console.log(response)
  // }

  const rows = filteredData.map((item, index) => {
    return {
      id: index + 1,
      academyName: item.academyName,
      action: (
        <Box>
          <Link
            to={{
              pathname: `/AcademyEdit/${item.academyId}`,
            }}
          >
            <EditNoteRounded color="success" />
          </Link>
          {/* <DeleteRounded color="error" onClick = {()=>handleDelete(item.academyId)}  /> */}
        </Box>
      ),
    };
  });
  return (
    <>
      <Box className="common-wrapper">
        <Paper
          className="content-paper"
          elevation={4}
          sx={{ p: 3, borderRadius: "var(--roundness)" }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" sx={{ color: "var(--app-bar-color)", fontWeight: 700, fontFamily:"var(--font-family)" }}>
              Academy List
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "30%" }}>
              <CommonTextField
                value={search}
                name="Academy Name"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                label="Filter by Academy"
                type="text"
              />
            </div>
            <CustomCommonButton
              icon={CommonAddIcon}
              tooltipText = "Add Academy"
              onClick={() => {
                navigation("/academyAdd");
              }}
            />
          </Box>
          <GridTable
            columns={GetData.academyList}
            rows={rows}
            height={"68vh"}
          />
        </Paper>
      </Box>
    </>
  );
};
