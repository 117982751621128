import { DeleteRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import { CommonAddIcon, CommonPlayerAddIcon } from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import ToastMessage from "../../Common/Components/ToastMessage";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";

const GroupingEdit = () => {
  const userId = sessionStorage.getItem("userId");
  const { userGroupId } = useParams();
  const [selectedItem, setSelectedItem] = useState("");
  const [player, setPlayer] = useState();
  const [playerId, setPlayerId] = useState([]);
  const [updateUserGroup, setUpdateUserGroup] = useState();
  const [playerValue, setPlayerValue] = useState([]);
  const [values, setValues] = useState({
    userGroupId: userGroupId,
    groupName: "",
  });
  const { toast, contextHolder } = ToastMessage();

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState("");
  const handlePopupOpen = (index) => {
    setSelectedPopup(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const navigate = useNavigate();

  async function getGroupname() {
    try {
      const response = await api.get(`/userGroup/` + userGroupId);
      setValues({ ...values, groupName: response.data.groupName });
      setUpdateUserGroup(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getPlayerTable() {
    try {
      const response = await api.get(`/groupingUser/userGroup/` + userGroupId);
      const playerIds = response.data.map((item) => item.userId);
      setPlayerId(playerIds);
      const player = playerIds.map(async (userId) => {
        try {
          const playerResponse = await api.get(`/users/` + userId);
          console.log(playerResponse);
          return playerResponse.data;
        } catch (err) {
          console.error("Error fetching player data:", err);
          return null;
        }
      });
      const playersData = await Promise.all(player);
      const filteredPlayersData = playersData.filter(
        (player) => player !== null
      );
      setPlayerValue(filteredPlayersData);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getPlayer() {
    try {
      const response = await api.get("/users/coachId/" + userId);
      setPlayer(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }
  useEffect(() => {
    getGroupname();
    getPlayerTable();
    getPlayer();
  }, []);

  const handleAdd = () => {
    if (selectedItem) {
      if (playerId.includes(selectedItem)) {
        toast("error", "Player already exists.");
        console.log(playerId.includes(selectedItem));
      } else {
        const selectedPlayerObj = player.find(
          (option) => option.userId === selectedItem
        );
        if (selectedPlayerObj) {
          // Check if the selected player is already in the playervalue array
          if (
            playerValue.some(
              (player) => player.userId === selectedPlayerObj.userId
            )
          ) {
            toast("error", "Player already exists.");
          } else {
            setPlayerValue([...playerValue, selectedPlayerObj]); // Add the selected player object
            setPlayerId((previous) => [...previous, selectedItem]);
            setSelectedItem("");
          }
        } else {
          toast("error", "Player Not Found."); // Handle the case where the selected player doesn't exist
        }
      }
    }
  };

  const handleDelete = () => {
    const updatedPlayerValue = [...playerValue];
    updatedPlayerValue.splice(selectedPopup, 1);
    const updatedPlayerId = [...playerId];
    updatedPlayerId.splice(selectedPopup, 1);

    setPlayerValue(updatedPlayerValue);
    setPlayerId(updatedPlayerId);
    handlePopupClose();
  };

  const handleSubmit = (e) => {
    try {
      toast("loading", "Processing...");
      e.preventDefault();
      console.log("Values", values);
      var updateData = updateUserGroup;
      updateData.groupName = values.groupName;
      updateData.modifiedDate = getCurrentSQLDate();
      updateData.modifiedBy = sessionStorage.getItem("userId");
      console.log("Update:", updateData);

      api.put(`/userGroup/` + userGroupId, updateData).then((response) => {
        console.log(response.data);
        if (response.data.userGroupId) {
          for (let item of playerId) {
            const player = {
              ...playerValue,
              userGroupId: response.data.userGroupId,
              userId: item,
              createdBy: sessionStorage.getItem("userId"),
              createdDate: getCurrentSQLDate(),
            };
            console.log(player);
            api
              .post("/groupingUser/add", player)

              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
      api
        .delete(`/groupingUser/userGroup/` + userGroupId)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err.message);
    }
    navigate("/playerGrouping");

    toast("success", "Updated Successfully");
  };

  const rows = playerValue.map((item, index) => {
    return {
      id: index + 1,
      playerName: item.userName,
      action: (
        <DeleteRounded
          onClick={() => handlePopupOpen(index)}
          color="error"
          className="icon-button-style"
        />
      ),
    };
  });

  const navigatePath = () => navigate("/playerGrouping");
  return (
    <Box className="common-wrapper">
      {contextHolder}
      <CommonAlertPopup
        handleYes={handleDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Do you want to delete the Player from the Group?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <Box>
        <CustomCommonButton
          onClick={navigatePath}
          variant="contained"
          text="BACK"
          icon={<ArrowCircleLeftIcon />}
        />
      </Box>
      <Box autoComplete="off">
        <Grid
          container
          spacing={3}
          mt={0.5}
          columns={{ sm: 1, md: 3, lg: 4, xl: 4 }}
        >
          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <CommonTextField
              name="Group Name"
              value={values.groupName} // Use values.groupingName here
              onChange={(e) =>
                setValues({ ...values, groupName: e.target.value })
              }
              label="Group Name"
              type="text"
              disabled={true}
              required={true}
            />
          </Grid>

          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <CommonDropdown
              firstOption={"All"}
              label="Player"
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
              array={player}
              menuValue={"userId"}
              display={"userName"}
            />
          </Grid>
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
            <CustomCommonButton
              icon={CommonPlayerAddIcon}
              onClick={handleAdd}
              disabled={!selectedItem}
            />
          </Grid>
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
            <CustomCommonButton
              icon={CommonAddIcon}
              onClick={handleSubmit}
              text="Update"
              disabled={playerValue.length <= 0}
            />
          </Grid>
        </Grid>
      </Box>
      <GridTable
        columns={GetData.add_player_grouping}
        rows={rows}
        height={"72vh"}
      />
    </Box>
  );
};

export default GroupingEdit;
