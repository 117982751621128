import {
  CancelOutlined,
  CloudUpload,
  DeleteRounded,
  Download,
  VisibilityOff,
  VisibilitySharp,
} from "@mui/icons-material";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import HeightIcon from "@mui/icons-material/Height";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MonitorWeightIcon from "@mui/icons-material/MonitorWeight";
import PersonIcon from "@mui/icons-material/Person";
import PinDropIcon from "@mui/icons-material/PinDrop";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { utils } from "xlsx";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";
import {
  CommonAddIcon,
  CommonPlayListAddIcon,
} from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import ExcelJS from "exceljs";
import { saveAs } from "save-as";
import Extension from "../../Common/Extension";
import LoadingComponents from "../../Common/Components/LoadingComponents";

const UserCreation = () => {
  const user_id = parseInt(sessionStorage.getItem("userId"));
  const style = {
    formControl1: {
      width: "100%",
    },
    label: {
      color: "grey",
      fontSize: "15px",
      fontWeight: 500,
      // mb: 5,
      fontFamily: "var(--font-family)",
      "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
        color: "grey",
        fontSize: "15px",
        fontFamily: "var(--font-family)",
      },
    },
    inputFiled: {
      background: "white",
      color: "#000",
      // background: "#FFFFFF",
      fontSize: "18px",
      fontFamily: "var(--font-family)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
      },
      "&:hover > .MuiOutlinedInput-notchedOutline": {
        borderColor: "grey",
      },
    },
  };

  const initialUserData = {
    userName: "",
    pwd: "",
    conpwd: "",
    email: "",
    roleId: "",
    coachId: "",
  };
  const initialPersonalData = {
    name: "",
    fatherName: "",
    gender: "",
    dob: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
    mobileNo: "",
    email: "",
    ageCategory: "",
    height: "",
    weight: "",
  };
  const [userValue, setUserValue] = useState(initialUserData);
  const [personalValue, setPersonalValue] = useState(initialPersonalData);
  const [logId, setLogId] = useState("");
  const academyId = parseInt(sessionStorage.getItem("academyId"));
  const [coachList, setCoachList] = useState([]);
  const [showCoach, setShowCoach] = useState("");
  const [showAgeCategory, setShowAgeCategory] = useState("");
  const [coachCount, setCoachCount] = useState("");
  const [activeCoach, setActiveCoach] = useState("");
  const [playerCount, setPlayerCount] = useState("");
  const [activePlayer, setActivePlayer] = useState("");
  const [event, setEvent] = useState([]);
  const [subEvent, setSubEvent] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSubEventType, setSelectedSubEventType] = useState(null);
  const [showCoachEvents, setShowCoachEvents] = useState("");
  const [data, setData] = useState([]);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [todayDate, setTodayDate] = useState(formattedDate);
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [subEvents, setSubEvents] = useState([]);
  const [formData, setFormData] = useState({ pwd: "" });
  const [passwordError, setPasswordError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const { toast, contextHolder } = ToastMessage();
  const navigate = useNavigate();
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [coachFieldDisabled, setCoachFieldDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setUserValue({ ...userValue, pwd: value });
    setFormData({ ...formData, pwd: value });
    const alpha = /[a-zA-Z]/;
    const numeric = /\d/;
    const spl = /[!@#$%^&*(),.?":{}|<>]/;
    if (
      !alpha.test(value) ||
      !numeric.test(value) ||
      !spl.test(value) ||
      value.length < 8
    ) {
      setPasswordError(
        "Password must be at-least 8 characters, One lowercase, One Uppercase, and One Special Character "
      );
    } else {
      setPasswordError("");
    }
  };

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function setPersonalInput(e) {
    const name = e.target.name;
    const value =
      name === "name" ||
      name === "fatherName" ||
      name === "street" ||
      name === "city" ||
      name === "state"
        ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        : e.target.value;
    setPersonalValue((previous) => {
      const updatedData = { ...previous, [name]: value };
      return updatedData;
    });
    if (name === "height" && parseInt(value) > 250) {
      setHeightError("The Maximum Height Should Be 250 cm");
    } else {
      setHeightError("");
    }
  }
  function setUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setUserValue((previous) => {
      const updatedUsername = personalValue.name;
      const updatedEmail = personalValue.email;
      const updatedData = {
        ...previous,
        userName: updatedUsername,
        email: updatedEmail,
        [name]: value,
      };
      if (updatedData.coachId) {
        getPlayerEvent(updatedData.coachId);
      }
      return updatedData;
    });
  }

  function showCoachSelect(val) {
    if (parseInt(val) === 3) {
      setShowAgeCategory(true);
      setShowCoach(true);
      setShowCoachEvents(true);
      setUserValue((previous) => {
        return {
          ...previous,
          academyId: academyId,
          active: true,
          coachId: 0,
          loginId: logId.prefix + "P" + (parseInt(logId.playerLatestId) + 1),
          createDate: getCurrentSQLDate(),
        };
      });
    } else {
      setShowAgeCategory(false);
      setShowCoach(false);
      setShowCoachEvents(true);
      setUserValue((previous) => {
        return {
          ...previous,
          academyId: academyId,
          active: true,
          coachId: 0,
          loginId: logId.prefix + "C" + (parseInt(logId.coachLatestId) + 1),
          createDate: getCurrentSQLDate(),
        };
      });
    }
  }

  async function getLog() {
    try {
      const response = await api.get("academy/" + academyId);
      setLogId(response.data);
    } catch (err) {
      console.error("Error", err);
    }
  }
  async function getCoach() {
    try {
      setIsLoading(true);
      const allCoaches = await api.get("users/academy/coach/" + academyId);
      setCoachList(allCoaches.data);
      setCoachCount(allCoaches.data.length);
      const activeCoaches = allCoaches.data.filter(
        (coach) => coach.active === true
      );
      setActiveCoach(activeCoaches.length > 0 ? activeCoaches.length : 0);
    } catch (err) {
      console.error("error", err);
    } finally {
      setIsLoading(false);
    }
  }
  async function getPlayer() {
    try {
      const allPlayers = await api.get("users/academy/player/" + academyId);
      setPlayerCount(allPlayers.data.length);
      const activePlayers = allPlayers.data.filter(
        (player) => player.active === true
      );
      setActivePlayer(activePlayers.length > 0 ? activePlayers.length : 0);
    } catch (err) {
      console.error("error", err);
    }
  }
  useEffect(() => {
    getCoach();
    getPlayer();
    getLog();
    getEvent();
  }, []);

  useEffect(() => {
    const getSubEvents = async () => {
      await api
        .get(`/sub_events/all`)
        .then((res) => {
          setSubEvents(res.data);
        })
        .catch((err) => console.error(err));
    };
    getSubEvents();
  }, []);

  async function getEvent() {
    try {
      const eventResponse = await api.get("/event_type/all");
      const eventList = eventResponse.data;
      setEvent(eventList);

      for (let event of eventList) {
        const subEventResponse = await api.get(
          `/sub_events/eventId/${event.eventTypeId}`
        );
        const subEventList = subEventResponse.data;
        setSubEvent((prevSubEvents) => [
          ...prevSubEvents,
          { eventId: event.eventTypeId, subEvents: subEventList },
        ]);
      }
    } catch (error) {
      console.error("Error fetching events or sub-events:", error);
    }
  }
  async function getPlayerEvent(coachId) {
    try {
      let eventPlayerRes = await api.get(`/strength/userId/${coachId}`);
      const eTypeId = eventPlayerRes.data.map((id) => id.eventTypeId);
      const subETypeId = eventPlayerRes.data.map((id) => id.subEventsId);

      let eventSubEventMap = new Map();

      for (let i = 0; i < eTypeId.length; i++) {
        const eventId = eTypeId[i];
        const subEventId = subETypeId[i];

        // Check if the event ID is already in the map
        if (eventSubEventMap.has(eventId)) {
          // If yes, add the unique sub event to the existing array
          const existingSubEvents = eventSubEventMap.get(eventId).subEvents;
          const newSubEventRes = await api.get(`/sub_events/${subEventId}`);
          const newSubEvents =
            newSubEventRes.data instanceof Array
              ? newSubEventRes.data
              : [newSubEventRes.data];
          const uniqueSubEvents = [
            ...new Set([...existingSubEvents, ...newSubEvents]),
          ];
          eventSubEventMap.set(eventId, {
            eventId,
            subEvents: uniqueSubEvents,
          });
        } else {
          // If no, add the event ID and its sub event to the map
          const newSubEventRes = await api.get(`/sub_events/${subEventId}`);
          const newSubEvents =
            newSubEventRes.data instanceof Array
              ? newSubEventRes.data
              : [newSubEventRes.data];
          eventSubEventMap.set(eventId, { eventId, subEvents: newSubEvents });
        }
      }
      let uniqueEventList = [...eventSubEventMap.values()].map(
        (item) => item.eventId
      );

      // Fetch data for the unique event types
      let eventList = [];
      for (let eventId of uniqueEventList) {
        const res = await api.get(`/event_type/${eventId}`);
        eventList.push(res.data);
      }
      setEvent(eventList);
      // Convert the map values to an array
      let newSubEventList = [...eventSubEventMap.values()];

      // Set the state with the new subEventList
      setSubEvent(newSubEventList);
    } catch (err) {
      console.error("error", err);
    }
  }
  const DataRow = data.map((esd, index) => {
    // Find the event and sub-event objects based on eventTypeId and subEventsId
    const selectedEvent =
      event.find((e) => e.eventTypeId === esd.eventTypeId) || {};
    const selectedSubEvent =
      subEvent
        .find((item) => item.eventId === esd.eventTypeId)
        ?.subEvents.find(
          (subEventItem) => subEventItem.subEventId === esd.subEventsId
        ) || {};

    return {
      id: index + 1,
      event: selectedEvent.eventType || "", // Use the eventType property from the selected event
      subEvent: selectedSubEvent.subEventName || "", // Use the subEventName property from the selected sub-event
      strengthId: esd.strengthId,
    };
  });
  function handleDelete(index) {
    let total = [...data];
    total.splice(index, 1);
    setData(total);
  }

  const handleAdd = () => {
    const strengthArray = [];

    // Check if both selectedEvent and selectedSubEventType are defined
    if (selectedEvent && selectedSubEventType) {
      // Create a new strength object
      const strength = {
        selectedEvent: selectedEvent,
        selectedSubEventType: selectedSubEventType,
        eventTypeId: selectedEvent?.eventTypeId || null,
        subEventsId: selectedSubEventType?.subEventId || null,
      };
      if (
        data.some((item) => JSON.stringify(item) === JSON.stringify(strength))
      ) {
        toast("error", "Strength data already exist!");
        return;
      }

      // Add the strength object to the array
      strengthArray.push(strength);
      setData((prevData) => {
        const newData = [...prevData, ...strengthArray];
        return newData;
      });
      setSelectedEvent(null);
      setSelectedSubEventType(null);
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      setCoachFieldDisabled(true);
    } else {
      setCoachFieldDisabled(false);
    }
  }, [data.length]);

  console.log("userValue", userValue);

  async function handleForm(e) {
    e.preventDefault();
    let userId;
    getLog();
    if (
      !personalValue.name ||
      !personalValue.gender ||
      !personalValue.dob ||
      !userValue.pwd ||
      !userValue.conpwd ||
      !userValue.roleId
    ) {
      const missingFields = [];
      if (!personalValue.name) missingFields.push("First name");
      if (!personalValue.gender) missingFields.push("Gender");
      if (!personalValue.dob) missingFields.push("DOB");
      if (!userValue.pwd) missingFields.push("Password");
      if (!userValue.conpwd) missingFields.push("Confirm Password");
      if (!userValue.roleId) missingFields.push("Role");
      let alertMessage = "Please Fill in Fields:\n" + missingFields.join(", ");
      toast("error", alertMessage);
      return;
    }
    if (passwordError) {
      toast("error", "The Password Should Satisfy the Specified Requirements");
      return;
    }
    if (userValue.pwd !== userValue.conpwd) {
      toast("error", "Password and Confirm Password Should Be Same");
      return;
    }
    if (heightError) {
      toast("error", "Height Must Be Less than 250 cm");
      return;
    }
    // if (Array.isArray(data) && data.length <= 0) {
    //   toast("error", "The User Must Have at-least one Strength");
    //   return;

    // }

    try {
      setIsLoading(true);
      setAddButtonDisabled(true);
      const res = await api.post("users/add", {
        ...userValue,
        gender: personalValue.gender,
        modifyDate: Extension.getCurrentSQLDate(),
      });
      userId = res.data.userId;
      let lastLogId;
      let updateLogId;
      if (res.data !== null) {
        if (res.data.roleId === 3) {
          lastLogId = parseInt(logId.playerLatestId) + 1;
          updateLogId = logId;
          updateLogId.playerLatestId = lastLogId;
        } else {
          lastLogId = parseInt(logId.coachLatestId) + 1;
          updateLogId = logId;
          updateLogId.coachLatestId = lastLogId;
        }

        await api
          .put("academy/" + academyId, updateLogId)
          .catch((err) => console.error(err));

        const playerAssign = {
          playerId: res.data.userId,
          active: true,
          coachId: res.data.roleId === 2 ? 0 : res.data.coachId,
          startDate: getCurrentSQLDate(),
          academyId: res.data.academyId,
          createdBy: user_id,
          createdDate: getCurrentSQLDate(),
          modifiedBy: user_id,
          modifiedDate: Extension.getCurrentSQLDate(),
        };

        await api
          .post("playerAssign/add", playerAssign)
          .catch((err) => console.error(err));

        await api
          .post("/personal_info/add", {
            ...res.data,
            createBy: sessionStorage.getItem("userId"),
            createdDate: getCurrentSQLDate(),
            academy: sessionStorage.getItem("academyName"),
            modifyDate: Extension.getCurrentSQLDate(),
            ...personalValue,
          })
          .catch((err) => console.error(err));
      }
    } catch (err) {
      console.error("Error:", err);
    }
    toast("success", "User Added Successfully");
    try {
      if (userId) {
        for (let item of data) {
          await api
            .post("/strength/add", {
              ...item,
              userId: userId,
              createBy: sessionStorage.getItem("userId"),
              createdDate: getCurrentSQLDate(),
              modifyDate: getCurrentSQLDate(),
            })
            .then((res) => {})
            .catch((err) => console.error(err));
        }
      }
      setUserValue(initialUserData);
      setPersonalValue(initialPersonalData);
      setSelectedEvent(null);
      setSelectedSubEventType(null);
      setData([]);
      navigate("/userManipulation");
    } catch (err) {
      console.error("error", err);
    } finally {
      setAddButtonDisabled(false);
      setIsLoading(false);
    }
  }

  const handleBulkUploadOpen = () => {
    setBulkUploadOpen(true);
  };
  const handleBulkUploadClose = () => {
    clearTextField();
    setBulkUploadOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  const clearTextField = () => {
    setSelectedFile("");
  };

  const AGE_CATEGORIES = [
    { id: 1, maxAge: 12, value: "U12" },
    { id: 2, maxAge: 14, value: "U14" },
    { id: 3, maxAge: 16, value: "U16" },
    { id: 4, maxAge: 18, value: "U18" },
    { id: 5, maxAge: 20, value: "U20" },
    { id: 5, maxAge: 23, value: "U23" },
    { id: 5, minAge: 24, value: "General" },
  ];

  const GENDER = ["Male", "Female", "Transgender"];

  const ROLES = ["Coach", "Player"];

  const handleSampleExcel = async () => {
    const sampleDataColumns = [
      "S.No", //A
      "First Name", //B
      "Last Name", //C
      "Gender", //D
      "DOB", //E
      "Role", //F
      "Password", //G
      "Coach", //H
      "Age Category", //I
      "Sub Event", //J
      "Joined Date", //K
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Sample");
    const optionsSheet = workbook.addWorksheet("Options");
    const sub_name = subEvents?.map((item) => item?.subEventName);
    const coach_name = coachList.map((item) => item.userName);
    // headers
    worksheet.addRow(sampleDataColumns);

    sub_name.forEach((item, index) => {
      optionsSheet.getCell(`C${index + 2}`).value = item;
    });

    coach_name.forEach((item, index) => {
      optionsSheet.getCell(`A${index + 2}`).value = item;
    });

    // data validation for the dropdown
    worksheet.getCell("D2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + GENDER.join(",") + '"'],
      showErrorMessage: true,
      errorTitle: "Invalid Option",
      error: "Please select a valid option from the dropdown",
    };

    worksheet.getCell("E2").dataValidation = {
      type: "date",
      allowBlank: true,
    };

    worksheet.getCell("F2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: ['"' + ROLES.join(",") + '"'],
      showErrorMessage: true,
      errorTitle: "Invalid Option",
      error: "Please select a valid option from the dropdown",
    };

    worksheet.getCell("I2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [
        '"' + AGE_CATEGORIES.map((item) => item.value).join(",") + '"',
      ],
      showErrorMessage: true,
      errorTitle: "Invalid Option",
      error: "Please select a valid option from the dropdown",
    };
    const coach = optionsSheet.getCell("A1");
    coach.value = "Coach List";
    coach.font = {
      bold: true,
      color: { argb: "FF0000" },
      size: 12,
    };
    coach.alignment = { vertical: "middle", horizontal: "center" };

    const subEvent = optionsSheet.getCell("C1");
    subEvent.value = "Sub Event List";
    subEvent.font = {
      bold: true,
      color: { argb: "FF0000" },
      size: 12,
    };
    subEvent.alignment = { vertical: "middle", horizontal: "center" };

    const cell = worksheet.getCell("J2");
    cell.value = "From Options Sheet";
    cell.font = {
      bold: true,
      color: { argb: "FF0000" },
      size: 12,
    };
    cell.alignment = { vertical: "middle", horizontal: "center" };

    const coachCell = worksheet.getCell("H2");
    coachCell.value = "From Options Sheet";
    coachCell.font = {
      bold: true,
      color: { argb: "FF0000" },
      size: 12,
    };
    coachCell.alignment = { vertical: "middle", horizontal: "center" };

    worksheet.getCell("K2").dataValidation = {
      type: "date",
      allowBlank: true,
    };

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "XIP User Bulk Upload Sample.xlsx");
  };

  const handleUpload = async (e) => {
    try {
      if (selectedFile) {
        setAddButtonDisabled(true);
        const reader = new FileReader();
        reader.readAsBinaryString(selectedFile);
        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });

          let parsedData = [];
          setAddButtonDisabled(true);
          const sheet = workbook.Sheets["User Sample"];
          parsedData = XLSX.utils.sheet_to_json(sheet);
          if (parsedData.length <= 0) {
            toast("error", "There is no data to upload");
            setAddButtonDisabled(false);
            return;
          }
          const mandatoryFields = [
            "First Name",
            "Last Name",
            "Gender",
            "DOB",
            "Role",
            "Password",
          ];
          let formatWrong = [];
          //Check the mandatory fields are includes or not?
          parsedData.forEach((item, index) => {
            mandatoryFields.forEach((field) => {
              if (!(field in item) || item[field] === "") {
                formatWrong.push(parsedData[index]);
              }
            });
          });
          if (formatWrong.length > 0) {
            toast(
              "error",
              "Your data not proper need to fill\n First Name\n Last Name\n Gender\n DOB\n Role\n Password columns"
            );
            setAddButtonDisabled(false);
            return;
          }
          let lastCoachLoginId = logId.coachLatestId;
          let lastPlayerLoginId = logId.playerLatestId;
          const formattedData = parsedData.map((item, index) => {
            const dob = getDate(item["DOB"]);
            const subID = subEvents.find(
              (sub_item) => sub_item.subEventName === item["Sub Event"]
            )?.subEventId;
            const roleId =
              item["Role"] === "Coach" || item["Role"] === "coach" ? 2 : 3;
            const eventTypeId = subEvents.find(
              (data) => parseInt(data.subEventId) === parseInt(subID)
            )?.eventTypeId;
            const coachId = coachList.find(
              (coach) => coach.userName === item["Coach"]
            )?.userId;
            let loginId = "";
            if (roleId === 2) {
              loginId = `${logId.prefix}C${parseInt(lastCoachLoginId) + 1}`;
              lastCoachLoginId = parseInt(lastCoachLoginId) + 1;
            } else {
              loginId = `${logId.prefix}P${parseInt(lastPlayerLoginId) + 1}`;
              lastPlayerLoginId = parseInt(lastPlayerLoginId) + 1;
            }
            return {
              roleId: roleId,
              userName: `${item["First Name"]}`,
              firstName: item["First Name"],
              lastName: item["Last Name"],
              name: `${item["First Name"]}`,
              fatherName: item["Last Name"],
              ageCategory: item["Age Category"],
              coachId: coachId,
              pwd: item["Password"],
              gender: item["Gender"],
              subEventId: subID,
              subEventsId: subID,
              startDate: getDate(item["Joined Date"]).toISOString(),
              createDate: getDate(item["Joined Date"]).toISOString(),
              createdDate: getDate(item["Joined Date"]).toISOString(),
              createBy: user_id,
              eventTypeId: eventTypeId,
              active: true,
              loginId: loginId,
              dob: dob.toISOString(),
              createdBy: user_id,
              academyId: academyId,
              modifyDate: new Date().toISOString(),
            };
          });
          await addUserData(formattedData);
          toast("success", "User Bulk Uploaded Successfully");
          navigate("/userManipulation");
          await api.put(`/academy/${academyId}`, {
            ...logId,
            playerLatestId: lastPlayerLoginId,
            coachLatestId: lastCoachLoginId,
          });
          getCoach();
          getPlayer();
          getLog();
          getEvent();
          clearTextField();
          handleBulkUploadClose();
          setAddButtonDisabled(false);
        };
      }
    } catch (error) {
      console.error("Error processing Excel file:", error);
    }
  };
  async function addUserData(formattedData) {
    for (let data of formattedData) {
      try {
        setIsLoading(true);
        const res = await api.post(`/users/add`, data);
        const userId = res.data.userId;

        const personalInfoPromise = api.post(`/personal_info/add`, {
          ...data,
          userId,
        });
        const strengthInfoPromise = api.post(`/strength/add`, {
          ...data,
          userId,
        });
        const playerAssignPromise = api.post(`/playerAssign/add`, {
          ...data,
          playerId: userId,
        });

        await Promise.all([
          personalInfoPromise,
          strengthInfoPromise,
          playerAssignPromise,
        ]);
      } catch (err) {
        console.error(err);
        setAddButtonDisabled(false);
        toast("error", "Issue In Bulk Upload");
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleDateChange = (e) => {
    const value = new Date(e);
    setPersonalValue({ ...personalValue, dob: value.toISOString() });
  };

  const getDate = (value) => {
    const data = (value - 25569) * 86400 * 1000;
    return new Date(data);
  };

  const COUNT_ARRAY = [
    {
      id: 1,
      name: "Coach",
      value: coachCount || 0,
    },
    {
      id: 2,
      name: "Player",
      value: playerCount || 0,
    },
    {
      id: 3,
      name: "Active Coach",
      value: activeCoach || 0,
    },
    {
      id: 4,
      name: "Active Player",
      value: activePlayer || 0,
    },
  ];

  const event_row = DataRow.map((item) => {
    return {
      ...item,
      action: <DeleteRounded color="error" onClick={handleDelete} />,
    };
  });

  //to 2015
  const MaxDate = new Date();
  MaxDate.setFullYear(MaxDate.getFullYear() - 4);

  useEffect(() => {
    if (personalValue.dob) {
      const today = new Date();
      const DOB = new Date(personalValue.dob).getUTCFullYear();
      const difference = Math.abs(today.getUTCFullYear() - DOB);
      const Category = AGE_CATEGORIES.find(
        ({ maxAge }) => difference <= maxAge
      ) ||
        AGE_CATEGORIES.find(({ minAge }) => difference >= minAge) || {
          value: "U20",
        };
      setPersonalValue({ ...personalValue, ageCategory: Category.value });
    }
  }, [personalValue.dob]);

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <LoadingComponents loading={isLoading} />
      <Paper sx={{ p: 2, borderRadius: "15px" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <CustomCommonButton
            icon={<CloudUpload />}
            tooltipText="Bulk Upload"
            onClick={handleBulkUploadOpen}
          />
        </div>
        <Modal
          keepMounted
          open={bulkUploadOpen}
          // onClose={handleBulkUploadClose}
        >
          <Box sx={Style.widthPopupStyle}>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
              spacing={2}
            >
              <Grid
                item
                xs={1}
                sm={1}
                md={3}
                lg={3}
                xl={3}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h4" sx={Style.titleText}>
                  Bulk Upload
                </Typography>
                <IconButton
                  onClick={handleBulkUploadClose}
                  disabled={addButtonDisabled}
                >
                  <CancelOutlined sx={{ color: "var(--app-bar-color)" }} />
                </IconButton>
              </Grid>
              <Grid item xs={1} sm={1} md={3} lg={3} xl={3}>
                <CustomCommonButton
                  text={"Upload File"}
                  onClick={() => document.getElementById("file-upload").click()}
                  icon={<CloudUpload />}
                  fullWidth={"100%"}
                />
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileChange}
                  accept=".xlsx"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={3}
                lg={3}
                xl={3}
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="p"
                  gutterBottom
                  sx={{
                    fontSize: "var(--font-size)",
                    fontFamily: "var(--font-family)",
                  }}
                >
                  {selectedFile
                    ? `Selected File: ${selectedFile.name}`
                    : "No File Selected"}
                </Typography>
              </Grid>

              <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
                <CustomCommonButton
                  text={"Sample Excel"}
                  onClick={handleSampleExcel}
                  icon={<Download />}
                  fullWidth={"100%"}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1.5} lg={1.5} xl={1.5}>
                <CustomCommonButton
                  text={"Upload"}
                  onClick={handleUpload}
                  disabled={!selectedFile || addButtonDisabled}
                  fullWidth={"100%"}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Grid
          container
          columns={{ sm: 1, xs: 1, md: 4, lg: 4, xl: 4 }}
          spacing={2}
          sx={{ mt: 1 }}
        >
          {COUNT_ARRAY.map((item) => (
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} key={item.id}>
              <Paper
                elevation={3}
                key={item.id}
                sx={{
                  height: 150,
                  borderRadius: "15px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#85beff48",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 25,
                    fontFamily: "var(--font-family)",
                    fontWeight: "bold",
                    color: "var(--app-bar-color)",
                  }}
                >
                  {item.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "var(--app-bar-color)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    mt: 1,
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: 50,
                      fontFamily: "var(--font-family)",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          columns={{ sm: 1, xs: 1, md: 4, lg: 4, xl: 4 }}
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>First Name *</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="FirstName"
                name="name"
                type="text"
                label="First Name"
                value={personalValue.name}
                onChange={setPersonalInput}
                endAdornment={
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Last Name *</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="lastName"
                name="fatherName"
                type="text"
                label="Last Name"
                onChange={setPersonalInput}
                value={personalValue.fatherName}
                endAdornment={
                  <InputAdornment position="end">
                    <PersonIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl variant="outlined" sx={style.formControl1}>
              <InputLabel sx={style.label}>Gender *</InputLabel>
              <Select
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="gender"
                label="Gender"
                name="gender"
                value={personalValue.gender}
                onChange={(e) => {
                  setPersonalInput(e);
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  value={"Male"}
                >
                  Male
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  value={"Female"}
                >
                  Female
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  value={"Transgender"}
                >
                  Transgender
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={{ ...style.formControl1 }}>
              {/* <CustomDatePicker
                label={"DOB"}
                value={personalValue.dob}
                onChange={handleDateChange}
                name="dob"
              /> */}

              <InputLabel className="dobLabel" sx={style.label}>
                DOB *
              </InputLabel>
              <OutlinedInput
                style={{
                  borderRadius: "10px",
                  textTransform: "uppercase",
                  // padding: "5px",
                }}
                sx={style.inputFiled}
                id="date"
                name="dob"
                type="date"
                label="Date"
                // size="small"
                inputProps={{
                  // min: MinDate.toISOString().split("T")[0],
                  max: MaxDate.toISOString().split("T")[0],
                }}
                className="dateUppercase"
                value={personalValue.dob}
                onChange={setPersonalInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>City</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="city"
                type="text"
                label="City"
                name="city"
                onChange={setPersonalInput}
                value={personalValue.city}
                endAdornment={
                  <InputAdornment position="end">
                    <LocationCityIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>State</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="state"
                type="text"
                label="State"
                name="state"
                onChange={setPersonalInput}
                value={personalValue.state}
                endAdornment={
                  <InputAdornment position="end">
                    <LocationCityIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel sx={style.label}>Address</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="address"
                type="text"
                label="Address"
                name="street"
                onChange={setPersonalInput}
                value={personalValue.street}
                endAdornment={
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Pincode</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="pincode"
                type="text"
                label="Pincode"
                name="pinCode"
                onChange={setPersonalInput}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={personalValue.pinCode}
                endAdornment={
                  <InputAdornment position="end">
                    <PinDropIcon />
                  </InputAdornment>
                }
                inputProps={{
                  maxLength: 6,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Mobile No.</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="phoneno"
                type="tel"
                label="Mobile No."
                name="mobileNo"
                onChange={setPersonalInput}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={personalValue.mobileNo}
                endAdornment={
                  <InputAdornment position="end">
                    <ContactPhoneIcon />
                  </InputAdornment>
                }
                inputProps={{
                  maxLength: 10,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1} error={!!heightError}>
              <InputLabel sx={style.label}>Height (cm)</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="height"
                type="tel"
                label="Height (cm)"
                name="height"
                onChange={setPersonalInput}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={personalValue.height}
                endAdornment={
                  <InputAdornment position="end">
                    <HeightIcon />
                  </InputAdornment>
                }
                inputProps={{
                  maxLength: 5,
                }}
              />
              {heightError && <FormHelperText>{heightError}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Weight (kg)</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="weight"
                type="tel"
                label="Weight (kg)"
                name="weight"
                onChange={setPersonalInput}
                onKeyDown={(e) => {
                  if (
                    !/[0-9]/.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete"
                  ) {
                    e.preventDefault();
                  }
                }}
                value={personalValue.weight}
                endAdornment={
                  <InputAdornment position="end">
                    <MonitorWeightIcon />
                  </InputAdornment>
                }
                inputProps={{
                  maxLength: 3,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Email ID</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="email"
                type="email"
                label="Email"
                name="email"
                onChange={setPersonalInput}
                value={personalValue.email}
                endAdornment={
                  <InputAdornment position="end">
                    <EmailIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1} error={!!passwordError}>
              <InputLabel sx={style.label} htmlFor="pwd">
                Password *
              </InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="pwd"
                type={passwordVisible ? "text" : "password"}
                label="Password"
                name="pwd"
                onChange={handlePasswordChange}
                value={formData.pwd}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? (
                        <VisibilityOff />
                      ) : (
                        <VisibilitySharp />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordError && (
                <FormHelperText>{passwordError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl sx={style.formControl1}>
              <InputLabel sx={style.label}>Confirm Password *</InputLabel>
              <OutlinedInput
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="conpwd"
                type={confirmPasswordVisible ? "text" : "password"}
                label="Confirm Password"
                name="conpwd"
                onChange={setUserInput}
                value={userValue.conpwd}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setConfirmPasswordVisible(!confirmPasswordVisible)
                      }
                    >
                      {confirmPasswordVisible ? (
                        <VisibilityOff />
                      ) : (
                        <VisibilitySharp />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <FormControl variant="outlined" sx={style.formControl1}>
              <InputLabel sx={style.label}>Select Role *</InputLabel>
              <Select
                style={{ borderRadius: "10px" }}
                sx={style.inputFiled}
                id="role"
                label="Select Role"
                name="roleId"
                onChange={(e) => {
                  setUserInput(e);
                  showCoachSelect(e.target.value);
                }}
                value={userValue.roleId}
              >
                <MenuItem
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  value={2}
                >
                  Coach
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  value={3}
                >
                  Player
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {showCoach ? (
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>Select Coach *</InputLabel>
                <Select
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="role"
                  label="Select Role"
                  name="coachId"
                  onChange={setUserInput}
                  disabled={coachFieldDisabled}
                  value={userValue.coachId}
                >
                  {coachList.map((option, index) => {
                    return (
                      <MenuItem
                        sx={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--font-size)",
                        }}
                        key={index}
                        value={option.userId}
                      >
                        {option.userName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            {showAgeCategory ? (
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>Select Age Category *</InputLabel>
                <Select
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="role"
                  label="Select Age Category"
                  name="ageCategory"
                  onChange={setPersonalInput}
                  value={personalValue.ageCategory}
                >
                  {AGE_CATEGORIES.map((item) => (
                    <MenuItem
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                      }}
                      value={item.value}
                      key={item.id}
                    >
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
        </Grid>
        {showCoachEvents ? (
          <>
            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 4, xl: 4, lg: 4 }}
              sx={{ mt: 1 }}
              spacing={1}
            >
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Event *</InputLabel>
                  <Select
                    style={{ borderRadius: "10px" }}
                    sx={style.inputFiled}
                    id="event"
                    label="Event"
                    value={selectedEvent ? selectedEvent.eventTypeId : ""}
                    onChange={(e) =>
                      setSelectedEvent(
                        event.find(
                          (option) => option.eventTypeId === e.target.value
                        )
                      )
                    }
                  >
                    {event.map((option, index) => (
                      <MenuItem
                        sx={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--font-size)",
                        }}
                        key={index}
                        value={option.eventTypeId}
                      >
                        {option.eventType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel sx={style.label}>Sub Event *</InputLabel>
                  <Select
                    style={{ borderRadius: "10px" }}
                    sx={style.inputFiled}
                    id="subEvent"
                    label="Sub Event"
                    value={
                      selectedSubEventType
                        ? selectedSubEventType.subEventId
                        : ""
                    }
                    onChange={(e) => {
                      const selectedEventItem = subEvent.find(
                        (item) =>
                          item.eventId === (selectedEvent?.eventTypeId || "")
                      );
                      const selectedSubEventItem = selectedEventItem
                        ? selectedEventItem.subEvents.find(
                            (option) => option.subEventId === e.target.value
                          )
                        : null;
                      setSelectedSubEventType(selectedSubEventItem);
                    }}
                  >
                    {Array.isArray(
                      subEvent.find(
                        (item) =>
                          item.eventId === (selectedEvent?.eventTypeId || "")
                      )?.subEvents
                    ) &&
                      subEvent
                        .find(
                          (item) =>
                            item.eventId === (selectedEvent?.eventTypeId || "")
                        )
                        ?.subEvents.map((option, index) => (
                          <MenuItem
                            sx={{
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--font-size)",
                            }}
                            key={index}
                            value={option.subEventId}
                          >
                            {option.subEventName}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <FormControl variant="outlined" sx={style.formControl1}>
                  <InputLabel
                    sx={style.label}
                    style={{
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                  >
                    Joining Date
                  </InputLabel>
                  <OutlinedInput
                    style={{
                      borderRadius: 12,
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                    sx={{
                      ...style.inputFiled,
                      fontSize: "var(--font-size)",
                      fontFamily: "var(--font-family)",
                    }}
                    id="date"
                    type="date"
                    value={todayDate}
                    onChange={(e) => setTodayDate(e.target.value)}
                    label="Date"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <CustomCommonButton
                  onClick={handleAdd}
                  icon={CommonPlayListAddIcon}
                  text="Add Event"
                />
              </Grid>
            </Grid>
            <GridTable
              rows={event_row}
              columns={GetData.event_user_data}
              height={"20vh"}
              hideFooter={true}
            />
          </>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CustomCommonButton
            onClick={handleForm}
            text={
              parseInt(userValue.roleId) === 2
                ? "Create Coach"
                : parseInt(userValue.roleId) === 3
                ? "Create Player"
                : "Create User"
            }
            disabled={addButtonDisabled}
            icon={CommonAddIcon}
          />
          <Typography
            sx={{
              ml: 2,
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              fontWeight: 800,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <span style={{}}> * </span> - Mandatory
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserCreation;
