import React, { useEffect, useState } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { Brightness7, DeleteRounded, ModeNight, MoreHorizRounded, North, RadioButtonChecked, RadioButtonUnchecked, South, StarBorderRounded, StarRateRounded } from '@mui/icons-material';
import { GridTable } from '../common/GridTable';
import api from '../../api/http-common'
import { GetData } from '../../data/GetData';



export const PlayerPerformance = () => {
    console.log("player");
    var initialPerformance = [];
    const roleId = sessionStorage.getItem('roleId');
    const userId = sessionStorage.getItem('userId');
    const [performance, setPerformance] = useState([]);
    const [performanceFilter, setPerformanceFilter] = useState([]);
    const [player, setPlayer] = useState([]);
    const [name, setName] = useState('');
    const [age, setAge] = React.useState('');

    // Input feilds form controll style 
    const style = {
        formControl: {
            width: { md: "20%", sm: "35%", xs: "25%" },
            m: 2,
        },
        label: {
            color: '#000',
            fontWeight: 700,
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                color: "#000",
                fontSize: '20px',

            }
        },
        inputFiled: {
            color: '#000',
            background: "#FFFFFF",
            border: '1px solid var(--app-bar-color)',
            borderradius: '8px',
            boxshadow: 'rgb(0 0 8px var(--app-bar-color))',
            fontSize: '20px'
            // "& .MuiOutlinedInput-notchedOutline": {
            //     borderColor: "#FFFFFF",
            // }
            // "&:hover > .MuiOutlinedInput-notchedOutline": {
            //     borderColor: "#000",

            // }
        }
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        var i = 0;
        api.get("performanceList/userId/" + userId)
            .then((res) => {
                const perf = res.data.map((item) => {
                    // World record api call and validation
                    item['nr'] = '-';
                    item['sr'] = '-';
                    item['wr'] = '-';
                    api.get("world_record/subEvent/" + item['subEventId'])
                        .then((event) => {
                            event.data.map((row) => {
                                if (row['recordLevel'] === "National") {
                                    item['nr'] = row['recordValue'];
                                } else if (row['recordLevel'] === "State") {
                                    item['sr'] = row['recordValue'];
                                } else {
                                    item['wr'] = row['recordValue'];
                                }
                            })


                        });

                    // Progress api call and validation 
                    api.get("practice/progress/" + item['userId'] + "/" + item['coachId'] + "/" + item['subEventId'] + "/" + item['createDate'])
                        .then((progressDetails) => {
                            if (progressDetails.data) {
                                if (item['eventTypeId'] === 8) {
                                    item['progress'] = (progressDetails.data['performance'] - item['performance']).toFixed(2);
                                } else {
                                    item['progress'] = (item['performance'] - progressDetails.data['performance']).toFixed(2);
                                }

                                if (item['progress'] > 0) {
                                    item['arrow'] = <North color='success' />;
                                } else if (item['progress'] === 0) {
                                    item['arrow'] = "-";
                                } else {
                                    item['arrow'] = <South color='error' />;
                                }
                            } else {
                                item['progress'] = '-';
                                item['arrow'] = "-";
                            }
                        })

                    var today = new Date();
                    var toDate = formatDate(today);
                    var fromDate = formatDate(new Date(new Date().setDate(today.getDate() - 90)));

                    // Performance Best api call and validation 
                    api.get("performanceBest/" + item['userId'] + "/" + item['coachId'] + "/" + item['subEventId'] + "/" + item['session'])
                        .then((personalBest) => {
                            item['personalBest'] = "-";
                            if (personalBest.data) {
                                if (personalBest.data['eventTypeId'] === 8) {
                                    item['personalBest'] = personalBest.data['minPerformance'];
                                } else {
                                    item['personalBest'] = personalBest.data['maxPerformance'];
                                }
                            }
                        })
                    // Recent performance api call and validation 
                    api.get("performanceBest/recent/" + fromDate + "/" + toDate + "/" + item['userId'] + "/" + item['coachId'] + "/" + item['subEventId'] + "/" + item['session']).then((recentPerf) => {
                        item['recentPerf'] = "-";
                        if (recentPerf.data) {
                            if (recentPerf.data['eventTypeId'] === 8) {
                                item['recentPerf'] = recentPerf.data['minPerformance'];
                            } else {
                                item['recentPerf'] = recentPerf.data['maxPerformance'];
                            }
                        }
                    })
                    item['id'] = ++i;
                    item['session'] = item['session'] === 'Morning' ? <Brightness7 sx={{ color: 'yellow', fontSize: "30px" }} /> : <ModeNight sx={{ color: '#555', fontSize: "30px" }} />;
                    item['date'] = formatDate1(new Date(item['createDate']));
                    item['action'] = <Box sx={{ display: 'flex', }}><MoreHorizRounded sx={{ fontSize: "30px" }} /> <DeleteRounded sx={{ ml: 2, color: 'red', fontSize: "30px" }} /></Box>
                    return item;

                })
                setPerformance(perf);
                setPerformanceFilter(perf);
            }).catch((err) => {
                console.log(err.message);
            })
    }, [])
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }
    function formatDate1(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const handlePlayer = (event) => {
        const playerId = event.target.value;
        setName(playerId);
        const newItem = performance.filter((item) => item.userId === playerId)
        setPerformanceFilter(newItem)
        if (playerId === "all") {
            setPerformanceFilter(performance);
        }
    };

    return (
        <>
            <Box className="wrapper" sx={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
                <Paper className='content' elevation={4} sx={{ mt: 8, mb: 20 }}>
                    <Box sx={{ m: 2, display: { sm: "flex", md: "flex" } }}>
                        <FormControl sx={{ m: 2, width: { md: "20%", xs: "92%" } }}>
                            <InputLabel sx={style.label}>Event</InputLabel>
                            <Select
                                sx={style.inputFiled}
                                value={age}
                                label="Event"
                                onChange={handleChange}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={10}>Ten1</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 2, width: { md: "20%", xs: "92%" } }}>
                            <InputLabel sx={style.label}>Sub Event</InputLabel>
                            <Select
                                sx={style.inputFiled}
                                value={age}
                                label="Sub Event"
                                onChange={handleChange}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Box sx={{ m: 2, mt: 3, width: { md: "25%" } }}>
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#FFFFF0' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#FFFFF0' }} />} />} sx={{ color: '#FFFFF0' }} label="Today" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#FFFFF0' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#FFFFF0' }} />} />} sx={{ color: '#FFFFF0' }} label="FTW" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#FFFFF0' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#FFFFF0' }} />} />} sx={{ color: '#FFFFF0' }} label="FTM" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#FFFFF0' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#FFFFF0' }} />} />} sx={{ color: '#FFFFF0' }} label="FTY" />
                        </Box>
                    </Box>
                    {/* Grid table  */}
                    <GridTable rows={performanceFilter} columns={GetData.performance.playerColumn} />
                </Paper>
            </Box>
        </>
    )
}
