import { Button, Tooltip } from "@mui/material";
import React from "react";
import Styles from "./Styles";

const CustomCommonButton = (props) => {
  const {
    onClick,
    icon,
    text,
    disabled,
    color,
    tooltipText,
    startIcon,
    height,
    fullWidth,
  } = props;
  return (
    <Tooltip title={tooltipText} arrow>
      <Button
        type="submit"
        variant={color === "error" ? "outlined" : "contained"}
        color={color ?? "info"}
        size="small"
        disabled={disabled === "" ? false : disabled}
        sx={{
          ...Styles.commonButton(),
          borderRadius: "var(--roundness)",
          backgroundColor: color ? "" : "var(--app-bar-color)",
          color: color ? "red" : "white",
          display: "flex",
          alignItems: "center",
          minWidth: fullWidth || "70px",
          height: height || "100%",
          "&:hover": {
            background: color ? "red" : "#006392",
            color: color && "white",
          },
        }}
        onClick={onClick}
        startIcon={text && icon}
      >
        {!text && icon}
        {text}
      </Button>
    </Tooltip>
  );
};

export default CustomCommonButton;
