import {
  Add,
  DirectionsRun,
  Flag,
  Home,
  PlaylistAdd,
  Public,
  Speed,
  Timer
} from "@mui/icons-material";

export const CommonPlayListAddIcon = (
  <PlaylistAdd style={{ fontSize: "40px" }} />
);
export const CommonPlayerAddIcon = <PlaylistAdd style={{ fontSize: "40px" }} />;
export const CommonAddIcon = <Add style={{ fontSize: "50px" }} />;
export const commonPlayerRunIcon = <DirectionsRun />;
export const CommonTimerIcon = <Timer />;
export const CommonSpeedMeterIcon = <Speed />;
export const CommonWorldIcon = (
  <Public sx={{ fontSize: "32px", color: "var(--active-color)" }} />
);

export const CommonFlagIcon = (
  <Flag sx={{ fontSize: "32px", color: "var(--sick-color)" }} />
);
export const CommonStateIcon = (
  <Home sx={{ fontSize: "32px", color: "var(--just-recovered-color)" }} />
);
