import { Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const CoachContainer = ({ coaches, handleUserSelection }) => {
  const [selectedCoach, setSelectedCoach] = useState("");

  const handleClick = useCallback(
    (e) => {
      setSelectedCoach(e.userId);
      handleUserSelection(e);
    },
    [handleUserSelection]
  );

  useEffect(() => {
    if (Array.isArray(coaches) && coaches.length > 0 && !selectedCoach) {
      handleClick(coaches[0]);
    }
  }, [handleClick, coaches, selectedCoach]);

  return (
    <div className="player-container">
      {Array.isArray(coaches) && coaches.length <= 0 && (
        <div className="images">
          <div
            className={`player-image`}
            style={{
              height: 85,
              width: 85,
            }}
          ></div>
          <p>No coaches</p>
          <p>Assigned Yet</p>
        </div>
      )}
      {coaches?.map((item, index) => (
        <div className="images" key={item.userId}>
          <div
            className={`player-image`}
            style={{
              height: 85,
              width: 85,
              border:
                parseInt(selectedCoach) === parseInt(item.userId) &&
                "2px solid rgb(32, 240, 9)",
            }}
            onClick={() => handleClick(item)}
          >
            {item.userName.split("")[0]}
          </div>
          <p>{item.loginId}</p>
          <Tooltip title={item.userName} placement="top">
            <p className="cut-text">{item.userName}</p>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default CoachContainer;

CoachContainer.propTypes = {
  coaches: PropTypes.array,
  handleUserSelection: PropTypes.func,
};
