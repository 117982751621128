import { Tooltip } from "@mui/material";
import { Segmented } from "antd";
import React, { useCallback, useEffect } from "react";
import "./RadioBtnFilter.css";

export const RadioBtnFilter = ({ handleRadioChange, option, custom }) => {
  //Option change function
  const handleChange = useCallback(
    (event) => {
      const value = event || option;
      handleRadioChange(value);
    },
    [option]
  );

  useEffect(() => {
    handleChange(option);
  }, [handleChange, option]);

  const array = [
    {
      value: "today",
      icon: <Tooltip title="Today">T</Tooltip>,
    },
    {
      value: "weekly",
      icon: <Tooltip title="Weekly">W</Tooltip>,
    },
    {
      value: "monthly",
      icon: <Tooltip title="Monthly">M</Tooltip>,
    },
    {
      value: "yearly",
      icon: <Tooltip title="Yearly">Y</Tooltip>,
    },
    {
      value: "all",
      icon: <Tooltip title="All">A</Tooltip>,
    },
  ];

  return (
    <Segmented
      options={array}
      className="segmented-button"
      defaultValue="all"
      value={option}
      onChange={(value) => {
        handleChange(value);
      }}
      // block
      style={{
        borderRadius: "var(--roundness)",
        padding: 7,
      }}
      size="large"
    />
  );
};
