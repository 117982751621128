import {
  Assessment,
  ExitToApp,
  HorizontalSplit,
  HouseSiding,
  MedicalInformation,
} from "@mui/icons-material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import MoodIcon from "@mui/icons-material/Mood";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import SubjectIcon from "@mui/icons-material/Subject";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Zoom } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar"; // Import the Avatar component
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../api/http-common";
import logo from "../../assets/logo.png";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ZeeAppBar() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const academyName = sessionStorage.getItem("academyName");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const userName = sessionStorage.getItem("userName");
  const [profileImage, setProfileImage] = useState("");
  // Add this state hook at the top of your component
  const [openDropdown, setOpenDropdown] = useState(false);

  useEffect(() => {
    if (
      location.pathname !== "/profile" &&
      location.pathname !== "/adminprofile"
    ) {
      sessionStorage.setItem("location", location.pathname);
    }
  }, [location]);

  // Step 2: Function to update sessionStorage and state

  // Step 3: Use effect to sync state with sessionStorage
  useEffect(() => {
    const handleStorageChange = () => {};

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (roleId === null) {
      navigate("/");
    }
  }, [navigate, roleId]);

  const Logout = () => {
    // window.localStorage.removeItem("isLogedIn")
    sessionStorage.clear();
    setAnchorEl(null);
    navigate("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        console.error("");
        return;
      }

      try {
        if (roleId !== 5) {
          const profile = await api.get(`/personal_info/userId/${userId}`);
          const data = profile.data;
          setProfileImage(data.image);
        }
      } catch (error) {
        // Handle the error as previously described
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [userId, setProfileImage]);

  const visitorList = [
    { text: "User Analytics", icon: <Assessment />, path: "/UserAnalytics" },
  ];

  const superAdminList = [
    { text: "Academy", icon: <HouseSiding />, path: "/academyList" },
    { text: "Events", icon: <MergeTypeIcon />, path: "/EventMaster" },
    { text: "Records", icon: <SubjectIcon />, path: "/RecordMaster" },
    { text: "Academy Analytics", icon: <Assessment />, path: "/analytic" },
  ];

  const AdminList = [
    { text: "User Creation", icon: <PersonAddIcon />, path: "/userCreation" },
    {
      text: "User Manipulation",
      icon: <ManageAccountsIcon />,
      path: "/userManipulation",
    },
    {
      text: "User Migration",
      icon: <ImportExportIcon />,
      path: "/UserMigration",
    },
    { text: "User Analytics", icon: <Assessment />, path: "/analytic" },
  ];

  const PlayerList = [
    {
      text: "Preparation",
      icon: <HorizontalSplit />,
      path: "/playerPreperation",
    },
    {
      text: "Performance",
      icon: <SportsHandballIcon />,
      path: "/playerPerformance",
    },
    {
      text: "Achievements",
      icon: <EmojiEventsIcon />,
      path: "/playerAchivements",
    },
    { text: "Influencer", icon: <MoodIcon />, path: "/playerInfluencers" },
  ];

  const CoachList = [
    {
      text: "Preparation View and Assignment",
      icon: <HorizontalSplit />,
      path: "/preparation",
    },
    { text: "Player Grouping", icon: <GroupsIcon />, path: "/playerGrouping" },
    {
      text: "Exercise Master",
      icon: <FitnessCenterIcon />,
      path: "/WorkourMaster",
    },
    {
      text: "Exercise Combination",
      icon: <MergeTypeIcon />,
      path: "/Combination",
    },
    { text: "Performance", icon: <SportsHandballIcon />, path: "/Performance" },
    { text: "Accolades", icon: <WorkspacePremiumIcon />, path: "/Accolades" },
    {
      text: "Manage Players",
      icon: <ManageAccountsIcon />,
      dropdownItems: [
        {
          text: "Medical History",
          icon: <MedicalInformation />,
          path: "/medicalHistory",
        },
        { text: "Influencer", icon: <MoodIcon />, path: "/Influencer" },
      ],
    },
    { text: "Analytics", icon: <Assessment />, path: "/analytic" },
  ];

  const Some_screen_List = [
    { text: "Player Grouping", path: "/Grouping" },
    {
      text: "Assign Preparation",
      path: "/assignPreparation",
    },
    {
      text: "Add Combination",
      path: "/CombinationAdd",
    },
    {
      text: "Assign Preparation",
      path: "/CombinationEdit*",
    },
    {
      text: "Profile",
      path: "/profile",
    },
    {
      text: "Profile",
      path: "/adminprofile",
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const location = useLocation();

  // Determine whether to show the AppBar based on the current route
  const showAppBar = location.pathname;
  if (showAppBar === "/") {
    // Don't show AppBar on the login page
    return null;
  }

  const toCamelCase = (text) => {
    const words = text?.split(" ");
    const camelCasedWords = words?.map((word) => {
      // Capitalize each word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return camelCasedWords?.join(" ");
  };

  const currentPath = location.pathname;

  const findTextFromPath = (currentPath, menuList) => {
    for (const item of menuList) {
      if (item.path === currentPath) {
        return item.text.toUpperCase();
      }
      if (item.dropdownItems) {
        for (const subItem of item.dropdownItems) {
          if (subItem.path === currentPath) {
            return subItem.text.toUpperCase();
          }
        }
      }
    }
    return null;
  };

  const pathText =
    currentPath === "/"
      ? ""
      : toCamelCase(
          findTextFromPath(
            currentPath,
            AdminList.concat([
              ...CoachList,
              ...PlayerList,
              ...superAdminList,
              ...Some_screen_List,
            ])
          )
        );
  const navigateTo = (path) => {
    console.log(path);
    navigate(path);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "var(--app-bar-color)" }}
      >
        <Toolbar disableGutters>
          <div>
            <Avatar sx={{ ml: 2 }} alt="Logo" src={logo} />
          </div>
          <Box sx={{ ml: 2, width: academyName ? "30%" : "0%" }}>
            <h6
              style={{
                letterSpacing: ".2rem",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
                fontWeight: "700",
              }}
            >
              {academyName}
            </h6>
            {/* <span style={{ marginLeft: '8px', fontWeight: '700', letterSpacing: '.2rem',fontFamily:"var(--font-family)",fontSize:20 }}>ACADEMY</span> */}
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ m: 2, borderColor: "white" }}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            href="/"
            sx={{
              display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              width: "100%",
              fontFamily: "var(--font-family)",
              color: "#FFFFFF",
              textDecoration: "none",
            }}
          >
            {pathText}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              float: "right",
              mr: 1,
              width: "40%",
            }}
          >
            <Typography
              sx={{
                mt: 0.5,
                display: { xs: "none", sm: "block", md: "block" },
                fontFamily: "var(--font-family)",
                fontSize: 20,
              }}
            >
              Hi, {userName}!
            </Typography>
            <Tooltip title="Profile">
              <Avatar
                alt={userName}
                sx={{ marginLeft: 2 }}
                src={userName}
                onClick={handleClick}
              />
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

      {roleId !== 5 && roleId !== 4 ? (
        <Menu
          sx={{ fontFamily: "var(--font-family)" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {roleId === 5 ? (
              <Avatar
                alt={userName}
                src={`data:image/jpeg;base64,${profileImage}`}
              />
            ) : null}
          </Box>

          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>

            {roleId === 1 ? (
              <Link
                to={"/adminprofile"}
                style={{ textDecoration: "none", color: "black" }}
              >
                Admin Profile
              </Link>
            ) : roleId === 2 ? (
              <Link
                to={"/profile"}
                style={{ textDecoration: "none", color: "black" }}
              >
                Coach Profile
              </Link>
            ) : roleId === 3 ? (
              <Link
                to={"/playerprofile"}
                style={{ textDecoration: "none", color: "black" }}
              >
                Player Profile
              </Link>
            ) : null}
          </MenuItem>

          <MenuItem onClick={Logout}>
            <ExitToApp sx={{ color: "grey" }} />
            <ListItemIcon onClick={Logout} sx={{ ml: 1.5 }}>
              Log Out
            </ListItemIcon>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          sx={{ fontFamily: "var(--font-family)" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {roleId === 1 || roleId === 2 || roleId === 3 || roleId === 5 ? (
              <Avatar
                alt={userName}
                src={`data:image/jpeg;base64,${profileImage}`}
              />
            ) : null}
          </Box>
          <MenuItem onClick={handleClose}>
            <ExitToApp sx={{ color: "grey" }} />
            <ListItemIcon onClick={Logout} sx={{ ml: 1.5 }}>
              Log Out
            </ListItemIcon>
          </MenuItem>
        </Menu>
      )}
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { backgroundColor: "var(--app-bar-color)" } }}
      >
        <DrawerHeader
          sx={{ backgroundColor: "var(--app-bar-color)" }}
        ></DrawerHeader>
        <Divider style={{ color: "#FFFFF0" }} />
        <div style={{ backgroundColor: "var(--app-bar-color)" }}>
          <List>
            {roleId === 1
              ? AdminList.map((item, index) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{
                      backgroundColor: "var(--app-bar-color)",
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "#FFFFF0",
                        minHeight: 48,
                      }}
                      onClick={() => navigateTo(item.path)}
                    >
                      <Tooltip title={item.text} arrow>
                        <ListItemIcon
                          sx={{
                            color: "#FFFFF0",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : null}

            {roleId === 2
              ? CoachList.map((item, index) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{
                      backgroundColor: "var(--app-bar-color)",
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (item.dropdownItems) {
                          setOpenDropdown(!openDropdown);
                        } else {
                          navigateTo(item.path);
                        }
                      }}
                      sx={{
                        color: "#FFFFF0",
                        minHeight: 48,
                        // justifyContent: item.dropdownItems
                        //   ? "initial"
                        //   : "center",
                        // px: 2.5,
                      }}
                    >
                      <Tooltip title={item.text} arrow>
                        <ListItemIcon
                          sx={{
                            color: "#FFFFF0",
                            minWidth: 0,
                            // mr: item.dropdownItems ? 3 : "auto",
                            // justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                    </ListItemButton>
                    {item.dropdownItems &&
                      openDropdown &&
                      item.dropdownItems.map((dropdownItem) => (
                        <ListItem
                          key={dropdownItem.text}
                          disablePadding
                          sx={{ pl: -10 }}
                        >
                          <ListItemButton
                            onClick={() => navigateTo(dropdownItem.path)}
                            sx={{
                              color: "#FFFFF0",
                              minHeight: 48,
                              // justifyContent: "initial",
                              // px: 2.5,
                            }}
                          >
                            <Tooltip title={dropdownItem.text} arrow>
                              <ListItemIcon
                                sx={{
                                  color: "#FFFFF0",
                                  minWidth: 0,
                                  // mr: 3,
                                  // justifyContent: "center",
                                }}
                              >
                                {dropdownItem.icon}
                              </ListItemIcon>
                            </Tooltip>
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </ListItem>
                ))
              : null}

            {roleId === 3
              ? PlayerList.map((item, index) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{
                      backgroundColor: "var(--app-bar-color)",
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "#FFFFF0",
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => navigateTo(item.path)}
                    >
                      <Tooltip title={item.text} arrow>
                        <ListItemIcon
                          sx={{
                            color: "#FFFFF0",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
            {roleId === 4
              ? visitorList.map((item, index) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{
                      backgroundColor: "var(--app-bar-color)",
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "#FFFFF0",
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => navigateTo(item.path)}
                    >
                      <Tooltip title={item.text} arrow>
                        <ListItemIcon
                          sx={{
                            color: "#FFFFF0",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
            {roleId === 5
              ? superAdminList.map((item, index) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{
                      backgroundColor: "var(--app-bar-color)",
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        color: "#FFFFF0",
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => navigateTo(item.path)}
                    >
                      <Tooltip title={item.text} arrow>
                        <ListItemIcon
                          sx={{
                            color: "#FFFFF0",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))
              : null}
          </List>
        </div>
      </Drawer>
    </Box>
  );
}
