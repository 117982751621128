import { EditNoteRounded } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Grid, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Style from "../../Common/Components/Styles";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import ToastMessage from "../../Common/Components/ToastMessage";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";

export const EventMaster = () => {
  const userId = sessionStorage.getItem("userId");
  const [event, setEvent] = useState([]);
  const [units, setUnits] = useState([]);
  const [eventType, setEventType] = useState("");

  const [unitsValue, setUnitsValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subEventName, setSubEventName] = useState("");
  const [subEvents, setSubEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("All");
  const [eventValue, setEventValue] = useState("All");
  const [selectedSubEvent, setSelectedSubEvent] = useState(null);
  const { toast, contextHolder } = ToastMessage();

  useEffect(() => {
    getAllUnits();
    getSubEvents();
    getAllEvent();
  }, [selectedEvent]);
  useEffect(() => {
    setEventValue("All");
  }, []);

  const getSubEvents = async () => {
    try {
      const response = await api
        .get("/sub_events/all")
        .then((res) => res.data)
        .catch((err) => console.log(err));

      response.sort(
        (a, b) => parseInt(a.eventTypeId) - parseInt(b.eventTypeId)
      );
      setSubEvents(response);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleEvent = (event) => {
    const eventId = event.target.value;
    setEventValue(eventId);
    setSelectedEvent(eventId);
  };

  const getAllEvent = async () => {
    try {
      const response = await api.get("/event_type/all");
      setEvent(response.data);
      console.log(event);
      setEventValue(response.data[0].eventTypeId);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getAllUnits = async () => {
    try {
      const response = await api.get("/unit/all");
      setUnits(response.data);
      setUnitsValue(response.data[0].unitId);
    } catch (err) {
      console.log("Error", err);
    }
  };

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleUnits = (units) => {
    const unitId = units.target.value;
    setUnitsValue(unitId);
  };

  const handleAddButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    handleCloseEditModal();
    clearTextField();
    setIsModalOpen(false);
  };
  const handleEdit = (subEvent) => {
    console.log(subEvent);
    setSelectedSubEvent(subEvent);
    setSubEventName(subEvent.subEventName); // Set the subevent name in the state
    setEventType(subEvent.eventTypeId); // Set the event type in the state
    setUnitsValue(subEvent.unitId); // Set the unit in the state
    setIsModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedSubEvent(null);
    setSubEventName(""); // Reset the subevent name in the state
    setEventType(""); // Reset the event type in the state
    setUnitsValue(""); // Reset the unit in the state
  };

  const handleUpdate = async () => {
    if (subEventName && eventType && unitsValue) {
      try {
        toast("loading", "Loading..");
        const response = {
          subEventName: subEventName,
          eventTypeId: eventType,
          unitId: unitsValue,
        };
        const res = await api.put(
          `/sub_events/${selectedSubEvent.subEventId}`,
          response
        );
        console.log(res.data);

        console.log("Subevent updated successfully:", res.data);

        setSubEvents((prevSubEvents) =>
          prevSubEvents.map((event) =>
            event.subEventId === selectedSubEvent.subEventId ? res.data : event
          )
        );

        handleCloseModal();
        toast("success", "Event updated successfully.");
      } catch (err) {
        console.error("Error updating subevent:", err);
      }
    } else {
      toast("warning", "Please Fill All The Fields..");
    }
  };

  const handleClick = async () => {
    if (subEventName && eventType && unitsValue) {
      try {
        toast("loading", "Loading...");
        const response = {
          subEventName: subEventName,
          eventTypeId: eventType,
          unitId: unitsValue,
          createBy: userId,
          createdDate: getCurrentSQLDate(),
        };
        await api.post("sub_events/add", response);
        getSubEvents();
        clearTextField();
        handleCloseModal();
        toast("success", "Event added successfully.");
      } catch (err) {
        console.error("Error adding sub event:", err);
      }
    } else {
      toast("warning", "Please Fill All The Fields..");
    }
  };

  const clearTextField = () => {
    setSubEventName("");
    setEventType("");
    setUnitsValue("");
  };

  const [open,setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("")

  const handleOpen = (data) =>{
    setOpen(true)
    setSelectedData(data)
  }

  const handleClose = () =>{
    setOpen(false)
  }

  const handleDelete = async () => {
    const subEventId = selectedData
      try {
        await api.delete(`/sub_events/ ${subEventId}`);
        console.log(`Deleted sub event with ID ${subEventId}`);

        setSubEvents((prevSubEvents) =>
          prevSubEvents.filter((event) => event.subEventId !== subEventId)
        );
        handleClose()
        toast("success", "Event Deleted Successfully..");
      } catch (err) {
        console.error("Error deleting sub event:", err);
      }
  };

  const filteredRows = subEvents.filter(
    (item) => selectedEvent === "All" || selectedEvent === item.eventTypeId
  );

  const rows = filteredRows.map((item, index) => {
    return {
      id: index + 1,
      subName: item?.subEventName,
      unit: units.find((unit) => unit.unitId === item.unitId)?.singularName,
      action: (
        <Box>
          <EditNoteRounded onClick={() => handleEdit(item)} color="success" />
          {/* <DeleteIcon
            onClick={() => handleOpen(item.subEventId)}
            color="error"
          /> */}
        </Box>
      ),
    };
  });


  return (
    <Box className="common-wrapper">
      <CommonAlertPopup
       handleYes = {handleDelete}
       handleNo = {handleClose}
       titleText = {<p>Confirmation</p>}
       contentText = {<p>Do you want to Delete the Event?</p>}
       open = {open}
       handleClose = {handleClose}
       cancelButtonText = {"Cancel"}
       confirmButtonText = {"Yes"}
      />
      {contextHolder}
      <Grid container spacing={3} columns={{ sm: 2, md: 5, lg: 5, xl: 5 }}>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label="Event"
            value={selectedEvent}
            onChange={handleEvent}
            array={event}
            menuValue={"eventTypeId"}
            display={"eventType"}
          />
        </Grid>
        <Grid item xs={1} md={1} lg={1} xl={1}>
          <Tooltip title="Add Sub Event" arrow>
            <CustomCommonButton
              onClick={handleAddButtonClick}
              icon={<PlaylistAddIcon sx={{ fontSize: "40px" }} />}
              tooltipText = "Add Event"
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={Style.popupStyle()}>
          <Typography variant="h4" sx={{ ...Style.titleText(), mb: 2 }}>
            {selectedSubEvent ? "Update Sub Event" : "Add Sub Event"}
          </Typography>
          <Grid container columns={1} gap={2}>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                label="Event"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
                array={event}
                menuValue={"eventTypeId"}
                display={"eventType"}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                name="Sub Event"
                value={subEventName}
                onChange={(e) => setSubEventName(e.target.value)}
                label="Sub Event"
                type="text"
                required={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonDropdown
                label="Event"
                value={unitsValue}
                onChange={handleUnits}
                array={units}
                menuValue={"unitId"}
                display={"singularName"}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CustomCommonButton
                onClick={selectedSubEvent ? handleUpdate : handleClick}
                text={selectedSubEvent ? "UPDATE" : "ADD"}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <GridTable columns={GetData.eventMaster} rows={rows} />
    </Box>
  );
};
