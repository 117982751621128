import { DeleteRounded, PlaylistAdd, PostAdd } from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import { CommonAddIcon } from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";

const Grouping = () => {
  const [groupingName, setGroupingName] = useState("");
  const [groupingNameExist, setGroupingNameExist] = useState([]);
  const [player, setPlayer] = useState();
  const userId = sessionStorage.getItem("userId");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [playerId, setPlayerId] = useState([]);
  const { toast, contextHolder } = ToastMessage();
  const [disableAddButton, setDisableAddButton] = useState(false);

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function fetchExistingGroupingNames() {
    try {
      const response = await api.get("/userGroup/coach/" + userId);
      const existingNames = response.data.map((group) => group.groupName);
      setGroupingNameExist(existingNames);
    } catch (err) {
      console.log("Error fetching existing grouping names", err);
    }
  }

  async function getPlayer() {
    try {
      const response = await api.get("/users/coachId/" + userId);
      setPlayer(response.data);
      console.log(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {
    getPlayer();
    fetchExistingGroupingNames();
  }, []);

  const handleAdd = () => {
    if (selectedItem) {
      const selectedPlayerObj = player.find(
        (option) => option.userId === selectedItem
      );
      if (selectedPlayerObj) {
        const playerToAdd = selectedPlayerObj.userName;

        // Check if the player name is already in the array
        if (!playerId.includes(selectedItem)) {
          setSelectedPlayers([...selectedPlayers, playerToAdd]);
          console.log(selectedItem);
          setPlayerId((previous) => {
            return [...previous, selectedItem];
          });
          setSelectedItem("");
        } else {
          // Player name is already in the array, you can display a message or take another action
          toast("error", "Player Already Added.");
          // alert("Player already added.");
        }
      }
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedPopup, setSelectedPopup] = useState("");
  const handlePopupOpen = (index) => {
    setSelectedPopup(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };
  const handleDelete = (index) => {
    const updatedPlayers = [...selectedPlayers];
    updatedPlayers.splice(selectedPopup, 1);
    setSelectedPlayers(updatedPlayers);
    const updatedPlayerId = [...playerId];
    updatedPlayerId.splice(index, 1);
    setPlayerId(updatedPlayerId);
    handlePopupClose();
  };

  async function handleSubmit() {
    //setError("");

    if (!groupingName) {
      toast("error", "Please Enter a Group Name");
      // navigate("/Grouping");
      return;
    }

    if (groupingNameExist.includes(groupingName)) {
      toast("error", "Grouping Already Exists");
      // navigate("/Grouping");
      return;
    }
    if (playerId.length < 1) {
      toast("error", "Please Select At Least One Player.");
      // navigate("/Grouping");
      return;
    }
    const userGroup = {
      groupName: groupingName,
      academyId: sessionStorage.getItem("academyId"),
      createdBy: sessionStorage.getItem("userId"),
      createdDate: getCurrentSQLDate(),
    };
    try {
      toast("loading", "Processing...");
      setDisableAddButton(true);
      const response = await api.post(
        "/userGroup/add",
        userGroup
      ); /* .then((res) => res.data) */
      console.log(response);

      if (response.data.userGroupId) {
        for (let item of playerId) {
          const player = {
            userId: item,
            userGroupId: response.data.userGroupId,
            createdBy: sessionStorage.getItem("userId"),
            createdDate: getCurrentSQLDate(),
          };
          const playerResponse = await api.post("/groupingUser/add", player);
          console.log(playerResponse);
        }
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setDisableAddButton(false);
    }
    toast("success", "Group Successfully Added");
    navigate("/playerGrouping");
  }

  const rows = selectedPlayers.map((item, index) => {
    return {
      id: index + 1,
      playerName: item,
      action: (
        <DeleteRounded
          onClick={() => handlePopupOpen(index)}
          color="error"
          className="icon-button-style"
        />
      ),
    };
  });

  const navigate = useNavigate();

  const navigatePath = () => navigate("/playerGrouping");
  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Box autoComplete="off">
        <Box>
          <CustomCommonButton
            onClick={navigatePath}
            variant="contained"
            text="BACK"
            icon={<ArrowCircleLeftIcon />}
          />
        </Box>
        <Grid
          container
          spacing={3}
          mt={0.5}
          columns={{ sm: 1, md: 3, lg: 4, xl: 4 }}
        >
          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <CommonTextField
              name="Group Name"
              value={groupingName}
              onChange={(e) => setGroupingName(e.target.value)}
              label="Group Name"
              type="text"
              required={true}
            />
          </Grid>

          <Grid item xs={1} md={1} lg={1.5} xl={1.5}>
            <CommonDropdown
              firstOption={"All"}
              label="Player"
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
              array={player}
              menuValue={"userId"}
              display={"userName"}
            />
          </Grid>
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
            <Box>
              <CustomCommonButton
                icon={<PostAdd sx={{ fontSize: "40px" }} />}
                onClick={handleAdd}
                disabled={!selectedItem}
                tooltipText="Add Player"
              />
            </Box>
          </Grid>
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
            <CustomCommonButton
              icon={<PlaylistAdd sx={{ fontSize: "40px" }} />}
              onClick={handleSubmit}
              tooltipText="Add Group"
              disabled={selectedPlayers.length <= 0 || disableAddButton}
            />
          </Grid>
        </Grid>
      </Box>
      <CommonAlertPopup
        handleYes={handleDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Do you want to delete the Player from the Group?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <GridTable
        columns={GetData.add_player_grouping}
        rows={rows}
        height={"70vh"}
      />
    </Box>
  );
};

export default Grouping;
