import { KeySharp, Visibility, VisibilityOff } from "@mui/icons-material";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Textra from "react-textra";
import api from "../api/http-common";
import runner from "../assets/running.png";
import ToastMessage from "../Common/Components/ToastMessage";
// import { message } from "antd";
// const [contextHolder] = message.useMessage();

export const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isRememberMe = localStorage.getItem("rememberme");
  sessionStorage.clear();
  const Navigate = useNavigate();
  const initialValues = { userName: "", pwd: "" };
  const [formValue, setFormValue] = useState(initialValues);
  const [rememberMe, setRememberMe] = useState(isRememberMe || false);
  const handleChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFormValue({ ...formValue, [id]: value });
  };

  const { toast, contextHolder } = ToastMessage();
  const LoginSubmit = async () => {
    toast("loading", "Logging in...");
    if (formValue.userName && formValue.pwd) {
      try {
        const res = await api.post("users/login", {
          loginId: formValue.userName,
          password: formValue.pwd,
        });
        if (res?.data?.roleId === 3) {
          toast("error", "Invalid Credentials");
          return;
        }
        if (res.data === "" || res.data === null) {
          toast("error", "Invalid Credentials");
          return;
        }

        sessionStorage.setItem("roleId", res.data.roleId);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("academyId", res.data.academyId);
        sessionStorage.setItem("userName", res.data.userName);

        if (res.data.roleId !== 7) {
          try {
            const academy = await api.get("academy/" + res.data.academyId);

            if (academy?.data?.academyName) {
              sessionStorage.setItem("academyName", academy.data.academyName);
            } else {
              console.error("Academy data or academyName property is null.");
            }
          } catch (academyError) {
            console.error("Error fetching academy data:", academyError);
          }
        }
        const encLogin = CryptoJS.AES.encrypt(
          formValue.userName,
          "xi_performer"
        ).toString();
        const encPassword = CryptoJS.AES.encrypt(
          formValue.pwd,
          "xi_performer"
        ).toString();
        if (rememberMe) {
          //store remember me with credentials
          localStorage.setItem("rememberme", true);
          localStorage.setItem("loginId", encLogin);
          localStorage.setItem("password", encPassword);
        } else {
          // If Remember Me is not checked, clear stored credentials from localStorage
          localStorage.removeItem("loginId");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberme");
        }
        setFormValue(initialValues);
        if (res.data.roleId === 1) {
          Navigate("/userCreation");
        } else if (res.data.roleId === 3) {
          toast("error", "Invalid Credentials");
        } else if (res.data.roleId === 4) {
          Navigate("/userAnalytics");
        } else if (res.data.roleId === 5) {
          Navigate("/academyList");
        } else {
          Navigate("/preparation");
        }
      } catch (error) {
        console.log(error)
        toast("error", error?.response?.data||"Invalid Credentials");
      }
    } else {
      toast("error", "Please Enter The Login ID and Password");
    }
  };

  useEffect(() => {
    if (rememberMe) {
      const login_Id = localStorage.getItem("loginId");
      const Pass = localStorage.getItem("password");
      if (login_Id && Pass) {
        let encLoginId = CryptoJS.AES.decrypt(
          login_Id,
          "xi_performer"
        ).toString(CryptoJS.enc.Utf8);
        let encPass = CryptoJS.AES.decrypt(Pass, "xi_performer").toString(
          CryptoJS.enc.Utf8
        );
        setFormValue({
          userName: encLoginId,
          pwd: encPass,
        });
      }
    }
  }, [rememberMe]);

  const style = {
    formControl: {
      width: { md: "80%" },
      mt: 2,
    },
    label: {
      fontFamily: "var(--font-family)",
      color: "#000000",
    },
    icon: {
      // color: "#000000",
      fontSize: 30,
    },
    inputFiled: {
      fontFamily: "var(--font-family)",
      color: "#000000",
      fontSize: "20px",
      "& .MuiOutlinedInput-notchedOutline": {
        fontFamily: "var(--font-family)",
        borderColor: "#000000",
      },
      "&:hover > .MuiOutlinedInput-notchedOutline": {
        // borderColor: "#000000",
      },
    },
  };

  return (
    <Box className="login">
      {contextHolder}
      <Paper
        className="row wrapper"
        elevation={4}
        sx={{ width: { xs: "80%", md: "68%", textAlign: "center" } }}
      >
        <Box
          className=""
          sx={{
            display: { xs: "none", md: "block" },
            width: "50%",
            height: "100%",
            textAlign: "center",
          }}
        >
          <div className="img">
            <img src={runner} alt="" />
          </div>
          <Textra
            className="animated-text"
            data={[
              "If you can't fly, then run.",
              "If you can't run, then walk.",
              "If you can't walk, then crawl.",
              "But by all means. keep moving forward",
            ]}
            effect="rightLeft"
            duration={700}
          />
        </Box>
        <Box
          className=" inputFiled"
          sx={{ width: { md: "50%", sx: "100%" } }}
          component="form"
          autoComplete="off"
        >
          <Box
            sx={{
              ml: 3,
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl sx={style.formControl} variant="outlined" fullWidth>
              <InputLabel sx={style.label}>LOGIN ID</InputLabel>
              <OutlinedInput
                onChange={handleChange}
                value={formValue.userName}
                id="userName"
                type="text"
                sx={style.inputFiled}
                startAdornment={
                  <InputAdornment position="start">
                    <PermIdentityOutlinedIcon sx={style.icon} />
                  </InputAdornment>
                }
                label="USERNAME"
              />
            </FormControl>
            <FormControl sx={style.formControl} variant="outlined" fullWidth>
              <InputLabel sx={style.label}>PASSWORD</InputLabel>
              <OutlinedInput
                onChange={handleChange}
                id="pwd"
                value={formValue.pwd}
                sx={style.inputFiled}
                type={showPassword ? "text" : "password"}
                startAdornment={
                  <InputAdornment position="start">
                    <KeySharp sx={style.icon} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityOff sx={style.icon} />
                      ) : (
                        <Visibility sx={style.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="PASSWORD"
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "auto 0",
                mt: 2,
              }}
            >
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                style={{ marginRight: "10px" }}
              />

              <Typography variant="h7" className="common-font-style">
                Remember Me
              </Typography>
            </Box>
            {/* <TextField type="checkbox" /> */}
            <Button
              onClick={LoginSubmit}
              sx={{
                width: { md: "80%" },
                mt: 2,
                fontFamily: "var(--font-family)",
                color: "#fff",
                fontSize: "var(--font-size)",
                background: "var(--app-bar-color)",
                borderRadius: "var(--roundness)",
                "&:hover": { background: "var(--app-bar-color)" },
              }}
              fullWidth
            >
              LOGIN
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
