import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ChartComponent } from "../../../../Common/Components/chart/ChartComponent";
import api from "../../../../api/http-common";
import PropTypes from "prop-types";
import Extension from "../../../../Common/Extension";

const PerformanceTrack = ({
  performance,
  events,
  selectedEvent,
  selectedPlayer,
}) => {
  const [unit, setUnit] = useState("");
  const [performanceBest, setPerformanceBest] = useState({});

  useEffect(() => {
    const getPersonalPerformance = async () => {
      if (selectedPlayer && selectedEvent) {
        const performanceBestData = await api
          .get(`/performanceBest/${selectedPlayer}/${selectedEvent}`)
          .catch((err) => console.error(err));
        setPerformanceBest(performanceBestData?.data);
      }
    };
    getPersonalPerformance();
  }, [selectedEvent, selectedPlayer]);

  // performance = performance.reverse();

  const series = [
    {
      name: "Morning",
      data:
        performance
          .filter((item) => item.session === "Morning")
          .map((data) => data.performance)
          .reverse() || [],
    },
    {
      name: "Evening",
      data:
        performance
          .filter((item) => item.session === "Evening")
          .map((data) => data.performance)
          .reverse() || [],
    },
  ];

  console.log(series);

  const uniqueDay = [
    ...new Set(
      performance?.map((item) =>
        Extension.convertDateFormatToDMY(item.createDate)
      )
    ),
  ];

  const selectedSubEvent = events?.find(
    (item) => parseInt(item.subEventId) === parseInt(selectedEvent)
  );

  useEffect(() => {
    const getUnit = async () => {
      const id = selectedSubEvent?.unitId;
      if (id) {
        await api
          .get(`/unit/${id}`)
          .then((res) => {
            setUnit(res.data);
          })
          .catch((err) => console.log(err));
      }
    };
    getUnit();
  }, [selectedSubEvent]);

  const chartOption = {
    series: series,
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 0.5,
      },
      markers: {
        size: 5,
      },
      colors: ["var(--chart-bar-color)", "var(--chart-dark-color)"],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        color: "var(--font-color)", // Legend item color
        labels: {
          colors: "var(--font-color)",
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 20,
        },
        itemTextTransform: "rotate-45",
      },
      yaxis: {
        min: performanceBest?.minPerformance || 5,
        max: performanceBest?.maxPerformance || 30,
        reversed: unit?.unit === "s",
        labels: {
          formatter: function (val) {
            return unit?.unit === "s"
              ? `${Extension.formatDuration(val)}`
              : `${val?.toFixed(2)} m`;
          },
        },
      },
      xaxis: {
        categories: uniqueDay.map((item) => item).reverse(),
      },
      noData: {
        text: "There Is No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "var(--font-family)",
        },
      },
    },
  };
  return (
    <Box className="biometric-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          mb: 3,
        }}
      >
        Performance Tracking
      </Typography>
      <ChartComponent
        options={chartOption.options}
        series={chartOption.series}
        type={"line"}
        height={330}
      />
    </Box>
  );
};

export default PerformanceTrack;

PerformanceTrack.propTypes = {
  performance: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  selectedEvent: PropTypes.string.isRequired,
  selectedPlayer: PropTypes.number.isRequired,
};
