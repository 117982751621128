import {
  ChevronRight,
  MonitorHeart,
  Sick,
  Thermostat,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, Paper, Tooltip } from "@mui/material";
import React from "react";

const BioTrack = ({ biometric }) => {
  const BIO_ARRAY = [
    {
      id: 1,
      name: "Heart Rate",
      info: "bpm",
      // info: "Beats per minute",
      icon: <MonitorHeart className="footer-icon" fontSize="" />,
      value: `${(biometric && biometric[0]?.targetedHeartRate) || 0}`,
      before: `${(biometric && biometric[0]?.restingHeartRate) || 0}`,
    },
    {
      id: 2,
      name: "Blood Pressure",
      info: "mmHg",
      // info: "Millimeters of mercury",
      icon: <Sick className="footer-icon" fontSize="" />,
      value: `${(biometric && biometric[0]?.bpafterTrail) || 0}`,
      before: `${(biometric && biometric[0]?.bpbeforeTrail) || 0}`,
    },
    {
      id: 3,
      name: "Temperature",
      info: "°F",
      // info: "Fahrenheit",
      icon: <Thermostat className="footer-icon" fontSize="" />,
      value: `${(biometric && biometric[0]?.temperatureAfter) || 0}`,
      before: `${(biometric && biometric[0]?.temperatureBefore) || 0}`,
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      columns={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 3,
      }} /* sx={{ pl: 3, pr: 3 }} */
    >
      {BIO_ARRAY.map((item) => (
        <Grid item xs={1} md={1} sm={1} lg={1} key={item.id}>
          <Paper elevation={0} className="bio-track">
            <div className="header">
              <p style={{ fontFamily: "var(--font-family)",fontSize: "var(--body-md-font-size)" }}>
                {item.name} ({item.info})
                {/* <Tooltip title={item.info}>
                  <InfoIcon sx={{ fontSize: "12px" }} />
                </Tooltip> */}
              </p>

              {/* <ChevronRight /> */}
            </div>
            <div className="footer">
              <div style={{ fontFamily: "var(--font-family)", display:"flex", alignItems:"center"}}>
              {item.icon}
              </div>
              <div className="value-container-tooltip">
                
              </div>
              <div className="value-container">
                <h3 style={{ fontFamily: "var(--font-family)",fontSize: "var(--heightWeight-font-size)" }}>
                  {item.value}
                </h3>
                <p
                  style={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--body-md-font-size)",
                    display:"flex",
                    alignItems:"center"
                  }}
                >
                  <Tooltip style={{ fontFamily: "var(--font-family)",marginRight:"5px" }} title={`Before: ${item.before}`}>
                  <InfoIcon />
                </Tooltip>
                  Current
                </p>
              </div>
            </div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default BioTrack;
