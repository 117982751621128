import CancelIcon from "@mui/icons-material/Cancel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import { ArrowCircleLeft } from "@mui/icons-material";
import ToastMessage from "../../Common/Components/ToastMessage";

export const AcademyEdit = () => {
  const { academyId } = useParams();
  const [academyName, setAcademyName] = useState("");
  const [email, setEmail] = useState("");
  const [prefixName, setPrefixName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const initialFormData = {
    userName: "",
    pwd: "",
    conpwd: "",
    email: "",
    roleId: "",
  };
  const [formValue, setFormValue] = useState(initialFormData);
  const [showCoach, setShowCoach] = useState();
  const [coachList, setCoachList] = useState();
  const [setError, setSetError] = useState(false);
  const [setSucc, setSetSucc] = useState();
  const [logId, setLogId] = useState();
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const { toast, contextHolder } = ToastMessage();
  //const [contactNumber, setContactNumber] = useState('');

  const handleContactNumberChange = (e) => {
    // Remove non-numeric characters
    const cleanedValue = e.target.value.replace(/\D/g, "");

    // Enforce a maximum length of 10
    const truncatedValue = cleanedValue.slice(0, 10);

    // Update the state with the cleaned and truncated value
    setContactNumber(truncatedValue);
  };

  const handlePincodeChange = (e) => {
    // Remove non-numeric characters
    const cleanedValue = e.target.value.replace(/\D/g, "");

    // Enforce a maximum length of 10
    const truncatedValue = cleanedValue.slice(0, 10);

    // Update the state with the cleaned and truncated value
    setPinCode(truncatedValue);
  };

  function setInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue((previous) => {
      return { ...previous, [name]: value };
    });
  }

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function getCoach() {
    try {
      const response = await api.get("users/academy/coach/active/" + academyId);
      setCoachList(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getLog() {
    try {
      const response = await api.get("academy/" + academyId);
      setLogId(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  const handleCloseAlert = () => {
    setSetError(false);
    setSetSucc(false);
  };

  useEffect(() => {
    getCoach();
    getLog();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function setAcademy() {
    try {
      const response = await api.get(`/academy/${academyId}`);
      console.log(response);
      setAcademyName(response.data.academyName);
      setEmail(response.data.emailId);
      setPrefixName(response.data.prefix);
      setContactNumber(response.data.contactNumber);
      setContactPerson(response.data.contactPerson);
      setAddress(response.data.address);
      setCity(response.data.city);
      setPinCode(response.data.pinCode);
    } catch (err) {
      console.log("set academy is error");
    }
  }
  useEffect(() => {
    setAcademy();
    getAcademy();
  }, []);

  async function handleSubmit() {
    const response = await api.get(`/academy/${academyId}`);
    try {
      setAddButtonDisable(true);
      if (response.data.academyId) {
        const academyDetails = {
          ...response.data,
          academyName: academyName,
          prefix: prefixName,
          contactNumber: contactNumber,
          contactPerson: contactPerson,
          address: address,
          city: city,
          pinCode: pinCode,
          createdBy: sessionStorage.getItem("userId"),
          modifiedDate: getCurrentSQLDate(),
        };
        console.log(academyDetails);
        const res = await api.put(`/academy/${academyId}`, academyDetails);
        console.log("updated successfully", res);

        toast("success", "Academy successfully updated");
        navigate("/AcademyList");
      }
    } catch (err) {
      console.log("error in updated");
    } finally {
      setAddButtonDisable(false);
    }
  }

  async function getAcademy() {
    try {
      const response = await api.get(
        `/users/academy/multiRoleId/${academyId}?roleId=1&roleId=4`
      );
      console.log(response.data);
      setResponseData(response.data);
      const res = await api.get("/user_role/all");
      console.log(res.data);
      setRoles(res.data);
    } catch (err) {
      console.log("error occurred in getAcademy");
    }
  }

  const fields = [
    {
      name: "academyName",
      label: "Academy Name",
      type: "text",
      required: true,
      disabled: true,
      value: academyName,
      onChange: (e) => setAcademyName(e.target.value),
    },
    {
      name: "emailId",
      label: "Email ID",
      type: "email",
      disabled: true,
      required: false,
      value: email,
      onChange: (e) => setEmail(e.target.value),
    },
    {
      name: "prefix",
      label: "Prefix Name",
      type: "text",
      required: true,
      disabled: true,
      value: prefixName,
      onChange: (e) => setPrefixName(e.target.value),
    },
    {
      name: "contactNumber",
      label: "Contact No",
      type: "text",
      inputMode: "numeric",
      pattern: "[0-9]*",
      maxLength: 10,
      required: true,
      value: contactNumber,
      onChange: handleContactNumberChange,
    },
    {
      name: "contactPerson",
      label: "Contact Person",
      type: "text",
      required: true,
      value: contactPerson,
      onChange: (e) => setContactPerson(e.target.value),
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      required: false,
      value: address,
      onChange: (e) => setAddress(e.target.value),
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: false,
      value: city,
      onChange: (e) => setCity(e.target.value),
    },
    {
      name: "pinCode",
      label: "Pin Code",
      type: "text",
      inputMode: "numeric",
      pattern: "[0-9]*",
      maxLength: 6,
      required: false,
      value: pinCode,
      onChange: handlePincodeChange,
    },
  ];

  const rows = responseData.map((item, index) => {
    const role_name = roles.find(
      (role) => parseInt(role.roleId) === parseInt(item.roleId)
    );
    return {
      ...item,
      id: index + 1,
      role: role_name?.roleName,
      action: <MoreVertIcon />,
    };
  });

  const navigatePath = () => navigate("/academyList");
  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Box>
        <CustomCommonButton
          onClick={navigatePath}
          variant="contained"
          text="BACK"
          icon={<ArrowCircleLeft />}
        />
      </Box>
      <Paper className="content" elevation={4} sx={{ p: 3, mt: 2 }}>
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="h4"
            sx={{
              color: "var(--app-bar-color)",
              fontWeight: 700,
              fontFamily: "var(--font-family)",
            }}
          >
            Academy Edit
          </Typography>
        </Box>
        <Grid container spacing={3} columns={{ sm: 1, md: 2, lg: 3, xl: 3 }}>
          {fields.map((field) => (
            <Grid item xs={1} md={1} lg={1} xl={1} key={field.name}>
              <CommonTextField
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                label={field.label}
                disabled={field.disabled}
                maxLength={field.maxLength}
                type={field.type}
                required={field.required}
              />
            </Grid>
          ))}
          <Grid
            item
            xs={1}
            md={1}
            lg={1}
            xl={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <CustomCommonButton
              disabled={addButtonDisable}
              onClick={handleSubmit}
              text="Submit"
            />
          </Grid>
        </Grid>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1" }}>
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="h4"
                sx={{
                  color: "var(--app-bar-color)",
                  fontWeight: 700,
                  fontFamily: "var(--font-family)",
                }}
              >
                Academy User
              </Typography>
            </Box>
          </div>
        </div>
        <Modal keepMounted open={open} onClose={handleClose}>
          <Box
            sx={{
              m: 8,
              mb: 3,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              height: 550,
              bgcolor: "background.paper",
              borderRadius: 3,
              clipPath: "ellipse(80% 80% at 40% 48%)",
              boxShadow: (theme) => theme.shadows[5],
              p: 2,
            }}
          >
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton
                sx={{ color: "var(--app-bar-color)" }}
                onClick={handleClose}
              >
                <CancelIcon />
              </IconButton>
            </div>
            <br />
            <br />
            <form action="#" className="form">
              {setError ? (
                <Alert severity="error" onClose={handleCloseAlert}>
                  Password and ReEnter Password Not Equal
                </Alert>
              ) : null}
              {setSucc && (
                <Alert severity="success" onClose={handleCloseAlert}>
                  User Add Successfully
                </Alert>
              )}
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontFamily: "var(--font-family)",
                  mt: 3,
                  fontWeight: 700,
                }}
              >
                User Creation
              </Typography>
              <div className="input-box">
                {/* <label>User Name</label> */}
                <TextField
                  sx={{ width: { md: "400px", sm: "200px" }, m: 1 }}
                  InputProps={{
                    style: {
                      borderRadius: "40px",
                    },
                  }}
                  id="outlined-basic"
                  // label="userName"
                  name="userName"
                  variant="outlined"
                  onChange={setInput}
                  type="text"
                  placeholder="Enter your Name"
                  value={formValue.userName}
                />
                <Box sx={{ display: "flex", flexDirection: "row", m: 1 }}>
                  {/* <label>Password</label> */}
                  <TextField
                    sx={{ width: "190px", mr: 2 }}
                    InputProps={{
                      style: {
                        borderRadius: "40px",
                      },
                    }}
                    id="outlined-basic"
                    // label="Password"
                    variant="outlined"
                    name="pwd"
                    onChange={setInput}
                    type="password"
                    placeholder="Enter your Password"
                    value={formValue.pwd}
                  />
                  {/* <label>ReEnter Password</label> */}
                  <TextField
                    sx={{ width: "190px", mr: 2 }}
                    InputProps={{
                      style: {
                        borderRadius: "40px",
                      },
                    }}
                    id="outlined-basic"
                    // label="Password"
                    variant="outlined"
                    name="conpwd"
                    onChange={setInput}
                    type="password"
                    placeholder="Confirm password"
                    value={formValue.conpwd}
                  />
                </Box>
                {/* <label>Email</label> */}
                <Box
                  sx={{ display: "flex", flexDirection: "row", m: 1, mt: 2 }}
                >
                  <TextField
                    sx={{ width: "190px", mr: 2 }}
                    InputProps={{
                      style: {
                        borderRadius: "40px",
                      },
                    }}
                    id="outlined-basic"
                    // label="Email"
                    variant="outlined"
                    name="email"
                    onChange={setInput}
                    type="email"
                    placeholder="Enter email"
                    value={formValue.email}
                  />
                  {/* <label>Select Role</label> */}
                  <FormControl sx={{ width: "190px", mr: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      select an option
                    </InputLabel>
                    <Select
                      //  InputProps={{
                      //   style: {
                      //     borderRadius: "40px",
                      //   }
                      // }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="roleId"
                      style={{ borderRadius: "40px", color: "gray" }}
                      onChange={(e) => {
                        setInput(e);
                      }}
                      required
                      value={formValue.roleId}
                    >
                      <MenuItem value={2}>Manager</MenuItem>
                      <MenuItem value={3}>Visitor</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  /* onClick={handleForm} */ sx={{
                    width: "30%",
                    height: "40px",
                    borderRadius: "40px",
                    mt: 2,
                  }}
                >
                  Create User
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <GridTable
          columns={GetData.academyDataList}
          rows={rows}
          height={"30vh"}
        />
      </Paper>
    </Box>
  );
};
