import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { Add, MoreHorizTwoTone, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { GridTable } from '../common/GridTable';
import api from '../../api/http-common'
import { GetData } from '../../data/GetData';



export const PlayerPreparation = () => {
    const roleId = sessionStorage.getItem('roleId');
    const userId = sessionStorage.getItem('userId');
    const [preparation, setPreparation] = useState([]);
    const [preparationFilter, setPreparationFilter] = useState([]);
    const [player, setPlayer] = useState([]);
    const [name, setName] = useState('');

    const style = {
        formControl: {
            width: { md: "20%", sm: "75%", xs: "75%" },
            m: 2,
        },
        label: {
            color: '#000',
            fontWeight: 700,
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                color: "#000",
                fontSize: '20px',

            }
        },
        inputFiled: {
            color: '#000',
            background: "#FFFFFF",
            border: '1px solid var(--app-bar-color)',
            borderradius: '8px',
            boxshadow: 'rgb(0 0 8px var(--app-bar-color))',
            fontSize: '20px'
            // "& .MuiOutlinedInput-notchedOutline": {
            //     borderColor: "#FFFFFF",
            // }
            // "&:hover > .MuiOutlinedInput-notchedOutline": {
            //     borderColor: "#000",

            // }
        }
    };

    useEffect(() => {
        var i = 0;
        api.get("warmUpHeaderList/player/" + userId + "/2023-01-01/2023-07-30")
            .then((res) => {
                const pre = res.data.map((item) => {
                    item['id'] = ++i;
                    var today = new Date();
                    var toDate = formatDate(today);
                    var fromDate = formatDate(new Date(new Date().setDate(today.getDate() - 90)));
                    item['date'] = formatDate1(new Date(item['createdDate']));
                    item['actual'] = 0;
                    item['assign'] = 0;
                    api.get("warmUpWorkoutDetailsController/headerId/" + item['warmUpWorkoutHeaderId'])
                        .then((detail) => {
                            detail.data.map((row) => {
                                item['actual'] += row['actualSet'];
                                item['assign'] += row['assignSet'];
                            })
                            item['percentage'] = ((item['actual'] / item['assign']) * 100).toFixed(0) + " %";
                            item['percentage'] = item['percentage'] !== '100 %' ? <Box sx={{ color: 'red' }}>{item['percentage']}</Box> : <Box sx={{ color: 'green' }}>{item['percentage']}</Box>;
                        })
                    item['action'] = <MoreHorizTwoTone />;
                    return item;
                })
                setPreparation(pre);
                setPreparationFilter(pre);
            }).catch((err) => {
                console.log(err.message);
            })
    }, [])

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }
    function formatDate1(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    // const handlePlayer = (event) => {

    //     const playerId = event.target.value;
    //     setName(playerId);
    //     const newItem = preparation.filter((item) => item.playerId === playerId)
    //     setPreparationFilter(newItem)
    //     if (event.target.value === "all") {
    //         setPreparationFilter(preparation);
    //     }
    // };
    return (
        <>
            <Box className="wrapper" sx={{ display: 'flex', width: "100%", justifyContent: 'center' }}>
                <Paper className='content' elevation={4} sx={{ mt: 8, mb: 20 }}>
                    <Box sx={{ m: 2, display: { sm: "flex", md: "flex", position: 'relative' } }}>
                        <Box sx={{ m: 2, mt: 3, width: { md: "30%" } }}>
                            {/* <InputCheckBox /> */}
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#000000' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#000000' }} />} />} sx={{ color: '#000000' }} label="Today" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#000000' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#000000' }} />} />} sx={{ color: '#000000' }} label="FTW" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#000000' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#000000' }} />} />} sx={{ color: '#000000' }} label="FTM" />
                            <FormControlLabel control={<Checkbox icon={<RadioButtonUnchecked sx={{ color: '#000000' }} />} checkedIcon={<RadioButtonChecked sx={{ color: '#000000' }} />} />} sx={{ color: '#000000' }} label="FTY" />
                        </Box>
                        <Button variant="contained" className='addButton' sx={{ position: 'absolute', top: 0, right: 0 }}>
                            Add <Add />
                        </Button>
                    </Box>
                    {/* Grid table  */}
                    <GridTable rows={preparationFilter} columns={GetData.preparation.playerColumns} />
                </Paper>
            </Box>
        </>
    )

}

