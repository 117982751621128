import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import LoadingComponents from "../../Common/Components/LoadingComponents";

export default function CoachBasedMigration({
  players,
  coaches,
  coachId,
  playerId,
  coachName,
  getPlayers,
}) {
  const user_id = parseInt(sessionStorage.getItem("userId"));
  const { toast, contextHolder } = ToastMessage();

  const [selectedIndex, setSelectedIndex] = useState("");
  const [migrationButtonDisabled, setMigrationButtonDisabled] = useState(false);
  const [selectedCoachID, setSelectedCoachID] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const migratePlayers = async (player) => {
    if (parseInt(player.coachId) === parseInt(selectedCoachID)) {
      toast("error", "Please Select Different Coach to Migrate");
      return;
    }
    setIsLoading(true);
    try {
      setMigrationButtonDisabled(true);
      const personal_info = await api
        .get(`/personal_info/userId/${player.userId}`)
        .then((res) => res.data);
      const player_assign = await api
        .get(`/playerAssign/player/isactive/${player.userId}`)
        .then((res) => res.data);
      const update_personal_info = await api.put(
        `/personal_info/${personal_info.personalInfoId}`,
        {
          ...personal_info,
          coachId: selectedCoachID,
          modifyDate: new Date().toISOString().split("T")[0],
        }
      );
      const update_player_assign = await api.put(
        `/playerAssign/${player_assign.playerAssignDetailId}`,
        {
          ...player_assign,
          coachId: selectedCoachID,
          endDate: new Date().toISOString().split("T")[0],
          modifiedBy: user_id,
          modifiedDate: new Date().toISOString().split("T")[0],
        }
      );
      const add_player_assign = await api.post(`/playerAssign/add`, {
        playerId: player.userId,
        startDate: new Date().toISOString().split("T")[0],
        active: player.isActive,
        academyId: player.academyId,
        createdBy: user_id,
        createdDate: new Date().toISOString().split("T")[0],
        coachId: selectedCoachID,
        modifiedBy: user_id,
        modifiedDate: new Date().toISOString().split("T")[0],
      });

      const getPlayer = await api
        .get(`/users/${player.userId}`)
        .then((res) => res.data)
        .catch((err) => {});
      const user_update = await api.put(`/users/${player.userId}`, {
        ...getPlayer,
        coachId: selectedCoachID,
        modifiedBy: user_id,
        modifiedDate: new Date().toISOString().split("T")[0],
      });

      await Promise.all([
        personal_info,
        player_assign,
        update_personal_info,
        update_player_assign,
        add_player_assign,
        user_update,
      ]);
      getPlayers();
      setSelectedIndex("");
      setSelectedCoachID("");
      toast("success", "Player Migrated Successfully");
    } catch (err) {
      console.error(err);
    } finally {
      setMigrationButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const coachSelectMigrate = (event, coach) => {
    const selected_Index = event;
    setSelectedIndex(selected_Index);
    setSelectedCoachID(coach);
  };

  const filterCoach = players.filter(
    (item) =>
      (coachId === "" ||
        coachId === null ||
        parseInt(coachId) === parseInt(item.coachId)) &&
      (playerId === "" ||
        playerId === null ||
        parseInt(playerId) === parseInt(item.userId))
  );
  const coachData = filterCoach.map((player, index) => {
    return {
      id: index + 1,
      loginId: player.loginId,
      name: player.userName,
      coach: (
        <>
          <select
            id="coachSelectMigrate"
            value={selectedIndex === index ? selectedCoachID : player.coachId}
            onChange={(e) => {
              coachSelectMigrate(index, e.target.value);
            }}
            style={{ width: 200, fontFamily: "var(--font-family)" }}
          >
            {!parseInt(player.coachId) && (
              <option
                selected={!parseInt(player.coachId) && selectedIndex !== index}
                style={{ fontFamily: "var(--font-family)" }}
                value=""
              >
                No Coach Assigned Yet
              </option>
            )}
            {coaches.map((coach) => {
              return (
                <option
                  selected={
                    parseInt(coach.userId) === parseInt(player.coachId) &&
                    parseInt(player.coachId)
                  }
                  style={{ fontFamily: "var(--font-family)" }}
                  key={coach.userId}
                  value={coach.userId}
                >
                  {coach.userName}
                </option>
              );
            })}
          </select>
          <IconButton
            sx={{ width: "auto" }}
            disabled={
              selectedIndex !== index ||
              migrationButtonDisabled ||
              !selectedCoachID ||
              parseInt(player.coachId) === parseInt(selectedCoachID)
            }
          >
            <Tooltip title="Migrate">
              <TransferWithinAStationIcon
                onClick={() => migratePlayers(player)}
                style={{ cursor: "pointer", width: "50px" }}
              />
            </Tooltip>
          </IconButton>
        </>
      ),
    };
  });
  return (
    <Box>
      {contextHolder}
      <LoadingComponents loading={isLoading} />
      <GridTable rows={coachData} columns={GetData.coachMigrateData} />
    </Box>
  );
}
