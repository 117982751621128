import { Box, Typography } from "@mui/material";
import React from "react";
import { ChartComponent } from "../../../../Common/Components/chart/ChartComponent";

const HealthLevelTrack = ({ influence }) => {
  const FEEL = influence?.map((item) => item.health.toUpperCase());
  const counts = {};
  FEEL.forEach(function (x) {
    counts[x] = (counts[x] || 0) + 1;
  });
  console.log(FEEL);
  const DATA = [
    {
      name: "ACTIVE",
      value: counts?.ACTIVE || 0,
    },
    {
      name: "JUST RECOVERED",
      value: counts["JUST RECOVERED"] || 0,
    },
    {
      name: "SICK",
      value: counts?.SICK || 0,
    },
  ];

  const chartOption = {
    series: DATA?.map((item) => item.value),
    options: {
      chart: {
        type: "radialBar",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            strokeWidth: 300, // Increase this value to make the track bigger
          },
          //   size: "80%",
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
            total: {
              show: false,
            },
          },
        },
      },
      colors: [
        "var(--active-color)",
        "var(--just-recovered-color)",
        "var(--sick-color)",
      ],
      labels: DATA.map((item) => item.name),
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        labels: {
          colors: "var(--font-color)",
          useSeriesColors: false,
        },
      },
      tooltip: {
        enabled: true,
        colors: "var(--font-color)",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      noData: {
        text: "There Is No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "var(--font-family)",
        },
      },
    },
  };

  return (
    <Box className="biometric-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          mb: 3,
        }}
      >
        Health Tracking
      </Typography>
      <Box sx={{display:"flex",alignItems:"center",height:"80%"}}>
      <ChartComponent
        options={chartOption.options}
        series={chartOption.series}
        type={"radialBar"}
        height={250}
      />
      </Box>
    </Box>
  );
};

export default HealthLevelTrack;
