import React from "react";

const TitleAndValue = ({ title, value }) => {
  return (
    <div className="title-value-container">
      <div className="title-style">{title}</div> :
      <div className="value-style">{value}</div>
    </div>
  );
};

export default TitleAndValue;
