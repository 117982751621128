import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import Styles from "./Styles";

const CommonDropdown = (props) => {
  const {
    label,
    value,
    onChange,
    array,
    menuValue,
    display,
    firstOption,
    name,
    readOnly,
    search,
    required,
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor={name}
        style={{
          color: "var(--font-color)", // Add your label style here
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          fontWeight: "bold",
          mt:-2
        }}
      >
        {label}
      </InputLabel>
      <Select
        id={name}
        required={required}
        sx={{
          backgroundColor: readOnly
            ? "var(--disabled-field)"
            : "var(--text-field)",
          height: "52px",
          borderRadius: "var(--roundness)",
          color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          "& .MuiSvgIcon-root": {
            color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
          },
        }}
        name={name}
        label={label}
        fullWidth
        value={value}
        inputProps={{
          typeof: "search",
          readOnly: readOnly === null || readOnly === "" ? false : readOnly,
          style: {
            minHeight: "20px",
          },
        }}
        onChange={onChange}
        MenuProps={Styles.MenuProps()}
      >
        {search && (
          <MenuItem
            value="All"
            style={{
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              color: "var(--font-color)",
            }}
            className="common-font-style"
          >
            {firstOption === "" || firstOption == null ? "None" : firstOption}
          </MenuItem>
        )}
        {array?.length > 0 &&
          array?.map((item, index) => (
            <MenuItem
              key={index}
              value={item[menuValue]}
              style={{
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
                color: "var(--font-color)",
              }}
            >
              {item[display]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CommonDropdown;
