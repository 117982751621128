import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { Done } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

export default function DateCalendarServerRequest({ userData }) {
    
  const highlightedDates = React.useMemo(
    () =>
      userData
        .map((user) => ({
          date: user.createDate,
          month: dayjs(user.createDate, "DD-MM-YYYY").format("MM-YYYY"),
        }))
        .reverse(),
    [userData]
  );
// console.log(userData.map((user)=>user.createDate));
  const monthsToDisplay = React.useMemo(
    () => Array.from(new Set(highlightedDates.map((item) => item.month))),
    [highlightedDates]
  );

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth &&
      highlightedDays.indexOf(day.format("DD-MM-YYYY")) >= 0;

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <Done color="success" /> : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  const requestAbortControllers = React.useRef({});
  const [isLoading, setIsLoading] = React.useState({});
  const [highlightedDaysByMonth, setHighlightedDaysByMonth] = React.useState(
    {}
  );
  const [nonHighlightedDaysCount, setNonHighlightedDaysCount] = React.useState(
    {}
  );

  const fetchHighlightedDays = React.useCallback(
    (date) => {
      const monthKey = date.format("MM-YYYY");
      const controller = new AbortController();

      setIsLoading((prev) => ({
        ...prev,
        [monthKey]: true,
      }));

      requestAbortControllers.current[monthKey] = controller;

      setTimeout(() => {
        const datesToHighlight = highlightedDates
          .filter((item) => item.month === monthKey)
          .map((item) => item.date);

        const totalDaysInMonth = date.daysInMonth();
        const nonHighlightedCount = totalDaysInMonth - datesToHighlight.length;

        setHighlightedDaysByMonth((prev) => ({
          ...prev,
          [monthKey]: datesToHighlight,
        }));
        setNonHighlightedDaysCount((prev) => ({
          ...prev,
          [monthKey]: nonHighlightedCount,
        }));
        setIsLoading((prev) => ({
          ...prev,
          [monthKey]: false,
        }));
      }, 100);
    },
    [highlightedDates]
  );

  React.useEffect(() => {
    const timeoutIds = monthsToDisplay.map((month) => {
      const initialDate = dayjs(month, "MM-YYYY");
      return setTimeout(() => fetchHighlightedDays(initialDate), 100);
    });

    return () => {
      timeoutIds.forEach(clearTimeout);
      Object.values(requestAbortControllers.current).forEach((controller) =>
        controller.abort()
      );
    };
  }, [monthsToDisplay, fetchHighlightedDays]);

  const handleMonthChange = (date) => {
    const monthKey = date.format("MM-YYYY");
    if (requestAbortControllers.current[monthKey]) {
      requestAbortControllers.current[monthKey].abort();
    }

    setIsLoading((prev) => ({
      ...prev,
      [monthKey]: true,
    }));
    setHighlightedDaysByMonth((prev) => ({
      ...prev,
      [monthKey]: [],
    }));
    fetchHighlightedDays(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        {monthsToDisplay.map((monthKey) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={monthKey}>
            <DateCalendar
              defaultValue={dayjs(monthKey, "MM-YYYY")}
              loading={isLoading[monthKey]}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays: highlightedDaysByMonth[monthKey] || [],
                },
              }}
              sx={{border:"1px solid gray"}}
            />
          </Grid>
        ))}
      </Grid>
    </LocalizationProvider>
  );
}
