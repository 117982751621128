import React from "react";
import ReactApexChart from "react-apexcharts";
import './Chart.css'

export const ChartComponent = (props) => {
  const { options, series, type, height } = props;
  return (
    <ReactApexChart
      className="chart"
      height={height}
      options={options}
      series={series}
      type={type}
    />
  );
};
