import { Box, Grid, Slider, Tooltip, Typography, styled } from "@mui/material";
import React from "react";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: "var(--chart-bar-color)",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "var(--chart-bar-color)",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
    "&:before": {
      boxShadow:
        "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "#d0d0d0",
  },
}));

const InfluencerTrack = ({ influence, dayDifference }) => {
  const days = dayDifference.totalDays;

  const getCount = (column) => {
    return influence.map((item) => item[column])?.filter(Boolean).length;
  };

  const Influence_Array = [
    { id: 1, name: "Breakfast", value: getCount("breakfast") },
    { id: 2, name: "Lunch", value: getCount("lunch") },
    { id: 3, name: "Snack", value: getCount("snack") },
    { id: 4, name: "Dinner", value: getCount("dinner") },
  ];

  return (
    <Box className="biometric-container">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--font-family)",
            fontWeight: "var(--font-weight)",
            fontSize: "var(--header-font-size)",
            mb: 1,
          }}
        >
          Meal Consumption
        </Typography>
        {/* <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--font-family)",
            fontWeight: "var(--font-weight)",
            fontSize: "var(--header-font-size)",
            mb: 1,
          }}
        >
          Days &nbsp; &nbsp;
        </Typography> */}
      </Box>
      <Grid
        container
        alignItems="center"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        columnSpacing={1}
        rowSpacing={3}
        columns={1}
      >
        {Influence_Array.map((item) => (
          <Grid item xs={1} md={1} sm={1} lg={1} key={item.id}>
            <Grid
              container
              alignItems="center"
              key={item.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              columnSpacing={3}
              rowSpacing={8}
              columns={6}
            >
              <Grid
                item
                xs={1}
                md={2}
                sm={1}
                lg={2}
                xl={2}
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--body-font-size)",
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                md={2}
                sm={2}
                lg={2}
                xl={2}
                sx={{ display: "flex", alignItems: "end" }}
              >
                <IOSSlider
                  aria-label="ios slider"
                  value={item.value}
                  onChange={() => {}}
                  ValueLabelComponent={ValueLabelComponent}
                  valueLabelDisplay="on"
                  max={days}
                />
              </Grid>
              <Grid item xs={1} md={2} sm={1} lg={2}>
                <Tooltip key={item.id} title={`${item.value} / ${days} days`}>
                  <Typography
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--body-font-size)",
                    }}
                  >
                    {item.value} / {days}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InfluencerTrack;

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
}
