import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography, Button, Select, MenuItem, Grid, Avatar } from '@mui/material'
import api from "../../api/http-common";
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { format } from 'date-fns';
import {
  AccessibilityNew as AccessibilityNewIcon,
  MonitorWeight as MonitorWeightIcon,
  Man3 as Man3Icon,
  Man4 as Man4Icon,
  MonitorHeart as MonitorHeartIcon,
  DeviceThermostat as DeviceThermostatIcon,
  HealthAndSafety as HealthAndSafetyIcon,
  Bloodtype as BloodtypeIcon,
  ThermostatAuto as ThermostatAutoIcon
} from '@mui/icons-material';
import noDataIcon from '../../assets/noData.gif'

const style = {
  formControl: {
    width: { md: "28%", sm: "35%", xs: "25%" },

    m: 2,
  },
  label: {
    color: '#000',
    fontWeight: 700,
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: "#000",
      fontSize: '20px',

    }
  },


  inputFiled: {
    color: '#000',
    background: "#FFFFFF",
    fontSize: '20px',
    fontWeight: 700,
    borderRadius: "10px",
    fontFamily: 'var(--font-family)',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",

    }
  }

};
const UserAnalytics = () => {
  const userId = sessionStorage.getItem("userId");
  const [userDetails, setUserDetails] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [selectPlayer, setSelectPlayer] = useState([]);

  const [name, setName] = useState([])
  const [selectedItem, setSelectedItem] = useState('');
  const [allPerData, setAllPerData] = useState(0);
  const [allRepData, setAllRepData] = useState(0);
  const [allRestData, setAllRestData] = useState(0);
  const [allActiveData, setAllActiveData] = useState(0);
  const [morningPerf, setMorningPerf] = useState([]);
  const [eveningPerf, setEveningPerf] = useState([]);
  const [viewType, setViewType] = useState('option1')
  const [subEventId, setSubEventId] = useState('')
  const [selectEvent, setSelectEvent] = useState([]);
  const [selectEventPlayer, setSelectEventPlayer] = useState('');
  const [showCharts, setShowCharts] = useState(false)
  const [selectedSubEvent, setSelectedEvent] = useState('')
  const [personalBest, setPersonalBest] = useState([])
  const [bioMetric, setBioMetric] = useState([]);
  const [coachUserId, setCoachUserId] = useState(null)
  const academyId = sessionStorage.getItem('academyId');

  //Display in coachId
  async function getUser() {
    const user = await api.get('/users/' + userId)
    const detail = user.data;
    setUserDetails(detail)

  }
  async function getName() {
    const name = await api.get('personal_info/userId/' + userId)
    const res = name.data;
    console.log(res)
    setName(res)
  }
  // async function getPlayer() {
  //   try {
  //     const response = await api.get("/user_role/all");
  //     console.log(response.data);
  //     const roleId = response.data.find((item) => item.roleId === 3);
  //     console.log(roleId);
  //     const res = await api.get(`/users/academy/player/${academyId}`);
  //     console.log(res.data);
  //     //  const matchId = res.data.find(item=>item.userId===selectedItem)
  //     //  console.log(matchId.userName)


  //     //  const id = res.data.find((item)=>item.userId===selectedItem)
  //     //  if(id){
  //     //   console.log(id.userName)
  //     //   return id.userName;
  //     //  }
  //     //  else{
  //     //   console.log('error')
  //     //  }

  //     setSelectPlayer(res.data)
  //     setSelectedItem(res.data[0]["userId"]);
  //   } catch (err) {
  //     console.log("Error", err);
  //   }
  // }

  async function getPlayer() {
    try {
      const response = await api.get("/user_role/all");
      console.log(response.data);
      const roleId = response.data.find((item) => item.roleId === 3);
      console.log(roleId);
      const res = await api.get(`/users/academy/player/${academyId}`);
      console.log(res.data);
      //  const matchId = res.data.find(item=>item.userId===selectedItem)
      //  console.log(matchId.userName)


      //  const id = res.data.find((item)=>item.userId===selectedItem)
      //  if(id){
      //   console.log(id.userName)
      //   return id.userName;
      //  }
      //  else{
      //   console.log('error')
      //  }

      setSelectPlayer(res.data)
      setSelectedItem(res.data[0]["userId"]);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getEvent() {
    try {
      const isOptionValid = selectPlayer.some(option => option.userId === selectedItem);

      if (selectedItem && isOptionValid) {
        const player = await api.get('/strength/userId/' + selectedItem);
        const response = player.data;
        const subEventId = response.map(item => item.subEventsId);

        let event = [];

        for (let item of subEventId) {
          const res = await api.get('/sub_events/' + item);
          event.push(res.data);
        }
        console.log("Event Data", event);
        setSelectEvent(event);
        const defaultSubEventId = event.length > 0 ? event[0].subEventId : null;
        setSelectEventPlayer(defaultSubEventId);
        setShowCharts(true);
        console.log("Event", event[0].subEventName);
      }
    } catch (err) {
      console.log("Error", err);
    }
  }

  useEffect(() => {
    getPerformance(selectEventPlayer);
  }, [selectEventPlayer]);


  useEffect(() => {
    console.log("Id", selectedItem);
    console.log("Id", userId);
    console.log("Id", selectEventPlayer);

  }, [selectEventPlayer]);
  async function getPerformance(e) {
    const data = e;
    console.log(data)
    setSelectEventPlayer(data)


    try {
      if (viewType == 'option1') {
        if (selectedItem && selectEventPlayer) {

          // setSelectEventPlayer(selectedSubEvent);
          // console.log(selectedSubEvent)
          const response = await api.get('/practice/userId/' + selectedItem);
          // console.log(response.data);
          console.log(data)
          const filterData = response.data.filter(item => item.subEventId == data);
          console.log("Filter Data", filterData);
          const combinedData = filterData.map(item => ({
            performance: item.performance,
            createDate: item.createDate,
            session: item.session,
          }));

          // console.log("Combined Data", combinedData);
          const morningData = combinedData.filter(item => item.session === 'Morning');
          const eveningData = combinedData.filter(item => item.session === 'Evening');

          // console.log("Morning Session Data", morningData);
          // console.log("Evening Session Data", eveningData);
          setMorningPerf(morningData)
          setEveningPerf(eveningData);
          const date = morningPerf.map(item => item.createDate)
          // console.log(date)
          setShowCharts(true)
        }
      }
      else if (viewType == 'option2') {
        if (selectedItem && selectEventPlayer) {
          const now = new Date();

          // Calculate the first day of the current week (assuming Monday as the first day of the week)
          const firstDayOfWeek = new Date(now);
          firstDayOfWeek.setDate(now.getDate() - now.getDay() + 1);
          firstDayOfWeek.setHours(0, 0, 0, 0);

          // Calculate the last day of the current week
          const lastDayOfWeek = new Date(firstDayOfWeek);
          lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
          lastDayOfWeek.setHours(23, 59, 59, 999);

          // Your fromDate and toDate will now represent the entire current week

          const fromDate = firstDayOfWeek;
          const toDate = lastDayOfWeek;

          // const dateArray = [];

          // // Start loop from Nov 21 (add one day to fromDate)
          // for (let currentDate = new Date(fromDate); currentDate <= toDate; currentDate.setDate(currentDate.getDate() + 1)) {
          //   dateArray.push(new Date(currentDate));
          // }

          // console.log("Date Array:", dateArray);



          const response = await api.get('/practice/userId/' + selectedItem);
          // console.log(response.data);

          const filterData = response.data.filter(item => item.subEventId == data);
          // console.log("Filter Data", filterData);

          const combinedData = filterData.map(item => ({
            performance: item.performance,
            createDate: new Date(item.createDate), // Convert createDate to Date object
            session: item.session,
          }));

          // console.log("Combined Data", combinedData);
          const morningData = combinedData.filter(item => item.session === 'Morning');
          const eveningData = combinedData.filter(item => item.session === 'Evening');

          // console.log("Morning Session Data", morningData);
          // console.log("Evening Session Data", eveningData);

          // console.log(morningData);
          // console.log(eveningData);

          const filteredMorningDate = morningData.filter(itemDate => {
            return itemDate.createDate.getTime() >= fromDate.getTime();
          });
          // console.log(filteredMorningDate);

          const filteredEveningDate = eveningData.filter(item => {
            return item.createDate.getTime() >= fromDate.getTime();
          });
          // console.log(filteredEveningDate);
          setMorningPerf(filteredMorningDate);
          setEveningPerf(filteredEveningDate);
          setShowCharts(true)
        }
      }
      else if (viewType == 'option3') {
        if (selectedItem && selectEventPlayer) {
          const now = new Date();

          // Calculate the first day of the current month
          const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
          firstDayOfMonth.setHours(0, 0, 0, 0);

          // Calculate the last day of the current month
          const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          lastDayOfMonth.setHours(23, 59, 59, 999);

          // Your fromDate and toDate will now represent the entire month
          const fromDate = firstDayOfMonth;
          const toDate = lastDayOfMonth;

          const response = await api.get('/practice/userId/' + selectedItem);
          // console.log(response.data);

          const filterData = response.data.filter(item => item.subEventId == data);
          // console.log("Filter Data", filterData);

          const combinedData = filterData.map(item => ({
            performance: item.performance,
            createDate: new Date(item.createDate), // Convert createDate to Date object
            session: item.session,
          }));

          // console.log("Combined Data", combinedData);
          const morningData = combinedData.filter(item => item.session === 'Morning');
          const eveningData = combinedData.filter(item => item.session === 'Evening');

          // console.log("Morning Session Data", morningData);
          // console.log("Evening Session Data", eveningData);

          // console.log(morningData);
          // console.log(eveningData);

          const filteredMorningDate = morningData.filter(itemDate => {
            return itemDate.createDate.getTime() >= fromDate.getTime();
          });
          // console.log("MornigFilter", filteredMorningDate);

          const filteredEveningDate = eveningData.filter(item => {
            return item.createDate.getTime() >= fromDate.getTime();
          });
          // console.log("EveningFilter", filteredEveningDate);
          setMorningPerf(filteredMorningDate);
          setEveningPerf(filteredEveningDate);
          setShowCharts(true)

        }
      }
      else if (viewType == 'option4') {
        if (selectedItem && selectEventPlayer) {

          const now = new Date();

          // Calculate the first day of the current year
          const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
          firstDayOfYear.setHours(0, 0, 0, 0);

          // Calculate the last day of the current year
          const lastDayOfYear = new Date(now.getFullYear() + 1, 0, 0);
          lastDayOfYear.setHours(23, 59, 59, 999);

          // Your fromDate and toDate will now represent the entire current year
          const fromDate = firstDayOfYear;
          const toDate = lastDayOfYear;

          const response = await api.get('/practice/userId/' + selectedItem);
          // console.log(response.data);

          const filterData = response.data.filter(item => item.subEventId == data);
          // console.log("Filter Data", filterData);

          const combinedData = filterData.map(item => ({
            performance: item.performance,
            createDate: new Date(item.createDate), // Convert createDate to Date object
            session: item.session,
          }));

          // console.log("Combined Data", combinedData);
          const morningData = combinedData.filter(item => item.session === 'Morning');
          const eveningData = combinedData.filter(item => item.session === 'Evening');

          // console.log("Morning Session Data", morningData);
          // console.log("Evening Session Data", eveningData);

          // console.log(morningData);
          // console.log(eveningData);

          const filteredMorningDate = morningData.filter(itemDate => {
            return itemDate.createDate.getTime() >= fromDate.getTime();
          });
          // console.log("MornigFilter", filteredMorningDate);

          const filteredEveningDate = eveningData.filter(item => {
            return item.createDate.getTime() >= fromDate.getTime();

          });
          // console.log("EveningFilter", filteredEveningDate);
          setMorningPerf(filteredMorningDate);
          setEveningPerf(filteredEveningDate);
          setShowCharts(true)

        }

      }

      const matchId = selectPlayer.find(item => item.userId === selectedItem)
      console.log("CoachId", matchId.coachId)
      setCoachUserId(matchId.coachId)
      const response = await api.get(`/performanceBest/` + selectedItem + '/' + data)
      console.log(response.data)
      const res = response.data.eventTypeId
      console.log(res)
      if (res == 1) {
        const minPerf = response.data.minPerformance;
        console.log("Minimum performance:", minPerf);
        setPersonalBest(minPerf)

      } else {
        const maxPerf = response.data.maxPerformance;
        console.log("Maximum performance:", maxPerf);
        setPersonalBest(maxPerf)
      }

    } catch (err) {
      console.error("Error in getFilter:", err);
    }
  }

  const handleEventChange = (e) => {
    setSelectEventPlayer(e.target.data);
    getPerformance(e.target.value);
  }

  const handleOptionClick = async (option) => {
    try {
      if (option === 'Weekly' && selectedItem) {
        getEvent()
        getPerformance(selectEvent)
        const now = new Date();

        // Calculate the first day of the current week (assuming Monday as the first day of the week)
        const firstDayOfWeek = new Date(now);
        firstDayOfWeek.setDate(now.getDate() - now.getDay() + 1);
        firstDayOfWeek.setHours(0, 0, 0, 0);

        // Calculate the last day of the current week
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);

        // Your fromDate and toDate will now represent the entire current week
        const fromDate = firstDayOfWeek;
        const toDate = lastDayOfWeek;



        const player = await api.get('/warmUpWorkoutHeader/playerId/' + selectedItem);
        const WHI = player.data.map(id => id.warmUpWorkoutHeaderId);


        let detailData = [];

        for (let id of WHI) {
          const details = await api.get('/warmUpWorkoutDetailsController/headerId/' + id);
          const response = details.data.flat();
          // console.log(response);

          const dataForHeader = response.map(item => {
            const createdDate = new Date(item.modifiedDate);
            // console.log("CreatedDate:", createdDate)
            const assignSet = parseFloat(item.assignSet);
            const actualSet = parseFloat(item.actualSet);
            const assignRep = parseFloat(item.assignRep);
            const actualRep = parseFloat(item.actualRep);
            const assignRest = parseFloat(item.assignRest);
            const actualRest = parseFloat(item.actualRest);
            const assignTime = parseFloat(item.assignTime);
            const actualTime = parseFloat(item.actualTime);
            // Add more properties as needed

            return {
              createdDate,
              assignSet,
              actualSet,
              assignRep,
              actualRep,
              assignRest,
              actualRest,
              assignTime,
              actualTime
              // Add more properties as needed
            };
          });

          detailData.push(...dataForHeader);
          // console.log(detailData);
        }

        const filteredData = detailData.filter(itemDate => {
          return itemDate.createdDate >= fromDate.getTime()
        });

        // console.log("filterData:", filteredData)
        // Calculate percentages for the filteredData array
        let percentages = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignSet === 0) {
            percentages.push(0);
          } else {
            let per = (filteredData[i].actualSet / filteredData[i].assignSet) * 100;
            percentages.push(per);
          }
        }

        // console.log(percentages);
        const totalPercentage = percentages.reduce((total, per) => total + per, 0) / percentages.length;
        console.log("Set Total Percentage:", totalPercentage.toFixed() + "%");
        setAllPerData(totalPercentage)


        const percent = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRep === 0) {
            percent.push(0);
          } else {
            let per = (filteredData[i].actualRep / filteredData[i].assignRep) * 100;
            percent.push(per)
          }
        }

        // console.log("REPS", percent)

        const totalPercent = percent.reduce((total, per) => total + per, 0) / percent.length;

        // console.log("Rep Total Percentage:", totalPercent.toFixed() + "%");
        setAllRepData(totalPercent);


        const percentage = [];

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRest === 0) {
            percentage.push(0);
          } else {
            let per = (filteredData[i].actualRest / filteredData[i].assignRest) * 100;
            percentage.push(per)
          }
        }
        // console.log(percentage)
        const totalPercentages = percentage.reduce((total, per) => total + per, 0) / percentage.length;

        // console.log("Rest Total Percentage:", totalPercentages.toFixed() + "%");
        setAllRestData(totalPercentages)



        const per = []; // Change the variable name to something else

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignTime === 0) {
            per.push(0);
          } else {
            let percentage = (filteredData[i].actualTime / filteredData[i].assignTime) * 100;
            per.push(percentage)
          }
        }
        const totalPer = per.reduce((total, p) => total + p, 0) / per.length;

        // console.log("Time Total Percentage:", totalPer.toFixed(2) + "%");
        setAllActiveData(totalPer);
      }


      else if (option === 'Month' && selectedItem) {
        getEvent()
        getPerformance(selectEvent)
        const now = new Date();

        // Calculate the first day of the current month
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);

        // Calculate the last day of the current month
        const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        lastDayOfMonth.setHours(23, 59, 59, 999);

        // Your fromDate and toDate will now represent the entire month
        const fromDate = firstDayOfMonth;
        const toDate = lastDayOfMonth;
        // console.log(fromDate)
        // console.log(toDate)

        const player = await api.get('/warmUpWorkoutHeader/playerId/' + selectedItem);
        const WHI = player.data.map(id => id.warmUpWorkoutHeaderId);

        let detailData = [];

        for (let id of WHI) {
          const details = await api.get('/warmUpWorkoutDetailsController/headerId/' + id);
          const response = details.data.flat();
          // console.log(response);

          const dataForHeader = response.map(item => {
            const createdDate = new Date(item.modifiedDate);
            console.log("CreatedDate:", createdDate)
            const assignSet = parseFloat(item.assignSet);
            const actualSet = parseFloat(item.actualSet);
            const assignRep = parseFloat(item.assignRep);
            const actualRep = parseFloat(item.actualRep);
            const assignRest = parseFloat(item.assignRest);
            const actualRest = parseFloat(item.actualRest);
            const assignTime = parseFloat(item.assignTime);
            const actualTime = parseFloat(item.actualTime);
            // Add more properties as needed

            return {
              createdDate,
              assignSet,
              actualSet,
              assignRep,
              actualRep,
              assignRest,
              actualRest,
              assignTime,
              actualTime
              // Add more properties as needed
            };
          });

          detailData.push(...dataForHeader);
          // console.log(detailData);
        }

        const filteredData = detailData.filter(itemDate => {
          return itemDate.createdDate >= fromDate.getTime()
        });

        // console.log("filterData:", filteredData)

        let percentages = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignSet === 0) {
            percentages.push(0);
          } else {
            let per = (filteredData[i].actualSet / filteredData[i].assignSet) * 100;
            percentages.push(per);
          }
        }

        // console.log(percentages);
        const totalPercentage = percentages.reduce((total, per) => total + per, 0) / percentages.length;
        // console.log("Set Total Percentage:", totalPercentage.toFixed() + "%");
        setAllPerData(totalPercentage)


        const percent = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRep === 0) {
            percent.push(0);
          } else {
            let per = (filteredData[i].actualRep / filteredData[i].assignRep) * 100;
            percent.push(per)
          }
        }

        // console.log("REPS", percent)

        const totalPercent = percent.reduce((total, per) => total + per, 0) / percent.length;

        // console.log("Rep Total Percentage:", totalPercent.toFixed() + "%");
        setAllRepData(totalPercent);


        const percentage = [];

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRest === 0) {
            percentage.push(0);
          } else {
            let per = (filteredData[i].actualRest / filteredData[i].assignRest) * 100;
            percentage.push(per)
          }
        }
        // console.log(percentage)
        const totalPercentages = percentage.reduce((total, per) => total + per, 0) / percentage.length;

        // console.log("Rest Total Percentage:", totalPercentages.toFixed() + "%");
        setAllRestData(totalPercentages)



        const per = []; // Change the variable name to something else

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignTime === 0) {
            per.push(0);
          } else {
            let percentage = (filteredData[i].actualTime / filteredData[i].assignTime) * 100;
            per.push(percentage)
          }
        }
        const totalPer = per.reduce((total, p) => total + p, 0) / per.length;

        // console.log("Time Total Percentage:", totalPer.toFixed(2) + "%");
        setAllActiveData(totalPer);





      }
      else if (option === 'Annual') {
        getEvent()
        getPerformance(selectEvent)
        const now = new Date();

        // Calculate the first day of the current year
        const firstDayOfYear = new Date(now.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);

        // Calculate the last day of the current year
        const lastDayOfYear = new Date(now.getFullYear() + 1, 0, 0);
        lastDayOfYear.setHours(23, 59, 59, 999);

        // Your fromDate and toDate will now represent the entire current year
        const fromDate = firstDayOfYear;
        const toDate = lastDayOfYear;

        // console.log(fromDate);
        // console.log(toDate);

        const player = await api.get('/warmUpWorkoutHeader/playerId/' + selectedItem);
        const WHI = player.data.map(id => id.warmUpWorkoutHeaderId);

        let detailData = [];

        for (let id of WHI) {
          const details = await api.get('/warmUpWorkoutDetailsController/headerId/' + id);
          const response = details.data.flat();
          // console.log(response);

          const dataForHeader = response.map(item => {
            const createdDate = new Date(item.modifiedDate);
            console.log("CreatedDate:", createdDate)
            const assignSet = parseFloat(item.assignSet);
            const actualSet = parseFloat(item.actualSet);
            const assignRep = parseFloat(item.assignRep);
            const actualRep = parseFloat(item.actualRep);
            const assignRest = parseFloat(item.assignRest);
            const actualRest = parseFloat(item.actualRest);
            const assignTime = parseFloat(item.assignTime);
            const actualTime = parseFloat(item.actualTime);
            // Add more properties as needed

            return {
              createdDate,
              assignSet,
              actualSet,
              assignRep,
              actualRep,
              assignRest,
              actualRest,
              assignTime,
              actualTime
              // Add more properties as needed
            };
          });

          detailData.push(...dataForHeader);
          // console.log(detailData);
        }

        const filteredData = detailData.filter(itemDate => {
          return itemDate.createdDate >= fromDate.getTime()
        });

        // console.log("filterData:", filteredData)


        let percentages = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignSet === 0) {
            percentages.push(0);
          } else {
            let per = (filteredData[i].actualSet / filteredData[i].assignSet) * 100;
            percentages.push(per);
          }
        }

        // console.log(percentages);
        const totalPercentage = percentages.reduce((total, per) => total + per, 0) / percentages.length;
        // console.log("Set Total Percentage:", totalPercentage.toFixed() + "%");
        setAllPerData(totalPercentage)


        const percent = [];
        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRep === 0) {
            percent.push(0);
          } else {
            let per = (filteredData[i].actualRep / filteredData[i].assignRep) * 100;
            percent.push(per)
          }
        }

        // console.log("REPS", percent)

        const totalPercent = percent.reduce((total, per) => total + per, 0) / percent.length;

        // console.log("Rep Total Percentage:", totalPercent.toFixed() + "%");
        setAllRepData(totalPercent);


        const percentage = [];

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignRest === 0) {
            percentage.push(0);
          } else {
            let per = (filteredData[i].actualRest / filteredData[i].assignRest) * 100;
            percentage.push(per)
          }
        }
        // console.log(percentage)
        const totalPercentages = percentage.reduce((total, per) => total + per, 0) / percentage.length;

        // console.log("Rest Total Percentage:", totalPercentages.toFixed() + "%");
        setAllRestData(totalPercentages)



        const per = []; // Change the variable name to something else

        for (let i = 0; i < filteredData.length; i++) {
          if (filteredData[i].assignTime === 0) {
            per.push(0);
          } else {
            let percentage = (filteredData[i].actualTime / filteredData[i].assignTime) * 100;
            per.push(percentage)
          }
        }
        const totalPer = per.reduce((total, p) => total + p, 0) / per.length;

        // console.log("Time Total Percentage:", totalPer.toFixed(2) + "%");
        setAllActiveData(totalPer);



      }
      else if (option === 'All') {

        if (selectedItem) {
          getEvent()
          getPerformance(selectEvent)
          //preparation progress
          const player = await api.get('/warmUpWorkoutHeader/playerId/' + selectedItem);
          // console.log(player.data);
          const WHI = player.data.map(id => id.warmUpWorkoutHeaderId)
          // console.log(WHI)

          let detailData = []
          for (let id of WHI) {
            const details = await api.get('/warmUpWorkoutDetailsController/headerId/' + id);
            detailData.push(details.data)
          }
          const data = detailData.flat()
          // console.log(data);

          const assignSet = data.map(item => parseFloat(item.assignSet));

          // console.log("assignSet:", assignSet)
          const actualSet = data.map(item => parseFloat(item.actualSet));
          // console.log("actualSet:", actualSet)

          // Calculate percentages and other computations here
          let percentages = [];
          for (let i = 0; i < assignSet.length; i++) {
            if (assignSet[i] === 0) {
              percentages.push(0);
            } else {
              let per = (actualSet[i] / assignSet[i]) * 100;
              percentages.push(per);
            }
          }
          // console.log("sets:", percentages)
          const totalPercentage = percentages.reduce((total, per) => total + per, 0) / percentages.length;
          // console.log("Set Total Percentage:", totalPercentage.toFixed() + "%");
          setAllPerData(totalPercentage)
          // const averagePercentage = parseFloat((totalPercentage / percentages.length).toFixed(2));

          // console.log("Per Average", averagePercentage)
          // console.log("Percentage for each pair:", percentages.map(p => p.toFixed(2) + "%"));

          const assignRep = data.map(item => parseFloat(item.assignRep));
          // console.log("assignRep:", assignRep)
          const actualRep = data.map(item => parseFloat(item.actualRep));
          // console.log("actualRep:", actualRep)


          const percent = [];

          for (let i = 0; i < assignRep.length; i++) {
            if (assignRep[i] === 0) {
              percent.push(0);
            } else {
              const per = (actualRep[i] / assignRep[i]) * 100;
              percent.push(per);

            }


          }
          // console.log(percent)

          const totalPercent = percent.reduce((total, per) => total + per, 0) / percent.length;

          // console.log("Rep Total Percentage:", totalPercent.toFixed() + "%");
          setAllRepData(totalPercent);
          // const averagePercent = parseFloat((totalPercent / percent.length).toFixed(2));


          // console.log("Rep Average:", averagePercent)

          // console.log("Percentage for each pair:", percent.map(p => p.toFixed(2) + "%"));



          const assignRest = data.map(item => parseFloat(item.assignRest));

          const actualRest = data.map(item => parseFloat(item.actualRest));
          const percentage = [];

          for (let i = 0; i < assignRest.length; i++) {
            if (assignRest[i] === 0) {
              percentage.push(0);
            } else {
              const per = (actualRest[i] / assignRest[i]) * 100;
              percentage.push(per);
            }
            // console.log(percentage);
          }
          const totalPercentages = percentage.reduce((total, per) => total + per, 0) / percentage.length;

          // console.log("Rest Total Percentage:", totalPercentages.toFixed() + "%");
          setAllRestData(totalPercentages)
          // console.log(totalPercentages)
          // const averagePercentages = parseFloat((totalPercentages / percentage.length).toFixed(2));

          // console.log("Rest Average", averagePercentages)
          // console.log("Percentage for each pair:", percentage.map(p => p.toFixed(2) + "%"));



          const assignTime = data.map(item => parseFloat(item.assignTime));

          const actualTime = data.map(item => parseFloat(item.actualTime));

          const per = []; // Change the variable name to something else

          for (let i = 0; i < assignTime.length; i++) {
            if (assignTime[i] === 0) {
              per.push(0);
            } else {
              const percentage = (actualTime[i] / assignTime[i]) * 100;
              per.push(percentage);
            }
          }

          const totalPer = per.reduce((total, p) => total + p, 0) / per.length;

          // console.log("Time Total Percentage:", totalPer.toFixed(2) + "%");
          setAllActiveData(totalPer);
          // const averagePer = parseFloat((totalPer / per.length).toFixed(2));

          // console.log("Time Average", averagePer);
          // console.log("Percentage for each pair:", per.map(p => p.toFixed(2) + "%"));

          // }




        }

        else {
          console.log('No player selected.');
        }

      }



    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleChange = (e) => {
    setSelectedItem(e.target.value);
    getEvent(e.target.value);
  }


  useEffect(() => {
    async function getBioMetric() {
      try {
        const response = await api.get('/biometric/playerId/' + selectedItem);

        if (response.data) {
          const allData = response.data.map(item => {
            const height = item.height;
            const weight = item.weight;
            const ankle = item.ankle;
            const hip = item.hip;
            const restHeart = item.restingHeartRate;
            const targetHeart = item.targetedHeartRate;
            const beforeTemp = item.temperatureBefore;
            const afterTemp = item.temperatureAfter;
            const beforeTrail = item.bpbeforeTrail;
            const afterTrail = item.bpafterTrail;
            const bloodPres = item.bloodPressure;
            const bmi = item.bmi;
            const bodyTemp = item.bodyTemperature;
            const date = item.modifiedDate;

            return {
              height,
              weight,
              ankle,
              hip,
              restHeart,
              targetHeart,
              beforeTemp,
              afterTemp,
              beforeTrail,
              afterTrail,
              bmi,
              bloodPres,
              bodyTemp,
              date,
            };
          });

          if (allData.length > 0) {
            const latestDate = allData.reduce((maxDate, item) => {
              const currentDate = new Date(item.date);
              return currentDate > maxDate ? currentDate : maxDate;
            }, new Date(0));

            const latestItem = allData.find(
              item => new Date(item.date).getTime() === latestDate.getTime()
            );

            if (latestItem) {
              const biometric = {
                height: latestItem.height,
                weight: latestItem.weight,
                ankle: latestItem.ankle,
                hip: latestItem.hip,
                restHeart: latestItem.restHeart,
                targetHeart: latestItem.targetHeart,
                beforeTemp: latestItem.beforeTemp,
                afterTemp: latestItem.afterTemp,
                beforeTrail: latestItem.beforeTrail,
                afterTrail: latestItem.afterTrail,
                bmi: latestItem.bmi,
                bloodPres: latestItem.bloodPres,
                bodyTemp: latestItem.bodyTemp,
              };

              setBioMetric(biometric);
              console.log(biometric);
            } else {
              console.log("No data found for the latest date.");
            }
          } else {
            setBioMetric({
              height: '',
              weight: '',
              ankle: '',
              hip: '',
              restHeart: '',
              targetHeart: '',
              beforeTemp: '',
              afterTemp: '',
              beforeTrail: '',
              afterTrail: '',
              bmi: '',
              bloodPres: '',
              bodyTemp: '',
              date: '',
            });

            console.log("No data received from the server.");
          }
        } else {
          console.log("No data found in the response.");
        }
      } catch (err) {
        if (err.response) {
          console.log('BioMetric Error', err.response.status, err.response.data);
        } else if (err.request) {
          console.log('BioMetric Error - No response received', err.request);
        } else {
          console.log('BioMetric Error', err.message);
        }
      }
    }

    getBioMetric();
  }, [selectedItem]);



  useEffect(() => {
    // const matchId = selectPlayer.find((item)=>item.userId===selectedItem)
    // console.log("hi",matchId)
    getUser();
    getPlayer();
    getName();
  }, [])


  useEffect(() => {
    getEvent(selectedItem);
    handleOptionClick('All');
  }, [selectedItem])

  const data = [
    { name: 'Set', x: allPerData.toFixed(0), },
    { name: 'Reps', x: allRepData.toFixed(0) },
    { name: 'Rest', x: allRestData.toFixed(0), },
    { name: 'Time', x: allActiveData.toFixed(0), },
  ];
  const selectedPlayer = selectPlayer.find(player => player.userId === selectedItem);
  const playerName = selectedPlayer ? selectedPlayer.userName : '';



  const gradientStyle = {
    background: 'linear-gradient(to right, #3C93EA8D, #6EA7EC55)', // Adjust colors as needed
  };

  const sortedMorningDates = morningPerf
    .map(item => new Date(item.createDate))
    .sort((a, b) => a - b)
    .map(date => format(date, 'MM-dd'));


  const sortedEveningDates = eveningPerf
    .map(item => new Date(item.createDate))
    .sort((a, b) => a - b)
    .map(date => format(date, 'MM-dd'));

  const extendDates = (dates, count) => {
    const lastDate = dates.length > 0 ? dates[dates.length - 1] : null;

    if (!lastDate) return dates;

    const extendedDates = [...dates];

    for (let i = 1; i <= count; i++) {
      const nextDate = new Date(lastDate);
      nextDate.setDate(nextDate.getDate() + i);
      extendedDates.push(format(nextDate, 'MM-dd'));
    }

    return extendedDates;
  };

  const extendedMorningDates = extendDates(
    morningPerf.map(item => new Date(item.createDate)).sort((a, b) => a - b).map(date => format(date, 'MM-dd')),
    5
  );

  const extendedEveningDates = extendDates(
    eveningPerf.map(item => new Date(item.createDate)).sort((a, b) => a - b).map(date => format(date, 'MM-dd')),
    5
  );
  const reverseArray = arr => [...arr].reverse();

  // Reverse the performance data for the y-axis
  const reversedMorningPerf = reverseArray(morningPerf.map(item => item.performance));
  const reversedEveningPerf = reverseArray(eveningPerf.map(item => item.performance));




  return (

    <Box className="wrapper" sx={{ display: 'flex', width: "100%", justifyContent: 'center', height: '100vh' }}>
      <Paper elevation={4} sx={{ height: '89vh', ...gradientStyle, borderRadius: '20px', mt: 11, ml: 8 }}>
        {selectPlayer ? (
          <div>
            <div style={{ marginTop: "20px", fontSize: '20px', marginLeft: '42px' }}>
              <h4>Select a Player</h4>
            </div>

            <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                    <Select
                                        sx={{
                                            ...style.inputFiled,
                                            height: '70%',
                                            width: '50%',
                                            marginTop: "2%",
                                            marginLeft: '6%',
                                            fontStyle: 'var(--font-family)'
                                        }}
                                        id='player'
                                        // label="Select Player"
                                        value={selectedItem} // Ensure a default or empty string if selectedItem is falsy
                                        onChange={
                                            handleChange// Pass the selected value to getEvent
                                        }
                                    >
                                        {selectPlayer.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.userId} style={{ color: option.active ? 'black' : 'grey' }}>
                      {option.userName}
                    </MenuItem>
                  );
                })}
               
                                    </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <Box sx={{ mb: 1, mt: 1 }}>
                                        <Typography variant='h4' sx={{
                                            color: 'rgba(0, 0, 0, 0.613)', fontWeight: 600,textAlign:"center"

                                        }}> Analytic Overview of<span style={{ color: '#345EB8', fontWeight: 600, fontSize: 40 }}> {playerName}</span></Typography>
                                    </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <Box>
                                        <Grid container spacing={1}   sx={{
                                            ...style.inputFiled,
                                            marginTop: "2%",
                                            fontStyle: 'var(--font-family)',
                                        }}
                                        >
                                            <Grid item xs={3} sx={{ paddingTop:"0px !important"}}>
                                            <Button value={viewType}
                                            onClick={() => {
                                                handleOptionClick('All')
                                                setViewType("option1")
                                            }}

                                            sx={{
                                                color: selectedOption === 'All' ? 'white' : 'black',
                                                background: selectedOption === 'All' ? 'white' : 'transparent',
                                                '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                                            }}
                                        >All</Button>
                                            </Grid>
                                            <Grid item xs={3} sx={{paddingTop:"0px !important"}}>
                                            <Button value={viewType}
                                            onClick={() => {
                                                handleOptionClick('Weekly')
                                                setViewType("option2")
                                            }}

                                            sx={{
                                                color: selectedOption === 'Week' ? 'white' : 'black',
                                                background: selectedOption === 'Week' ? 'white' : 'transparent',
                                                '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                                            }}

                                        >Week</Button>
                                            </Grid>
                                            <Grid item xs={3} sx={{paddingTop:"0px !important"}}>
                                            <Button  value={viewType}
                                            onClick={() => {
                                                handleOptionClick('Month')
                                                setViewType("option3")
                                            }}
                                            sx={{
                                                color: selectedOption === 'Month' ? 'white' : 'black',
                                                background: selectedOption === 'Month' ? 'white' : 'transparent',
                                                '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                                            }}
                                        >Month</Button>
                                            </Grid>
                                            <Grid item xs={3} sx={{paddingTop:"0px !important"}}>
                                            <Button value={viewType}
                                            onClick={() => {
                                                handleOptionClick('Annual')
                                                setViewType("option4")
                                            }}
                                            sx={{
                                                color: selectedOption === 'Annual' ? 'white' : 'black',
                                                background: selectedOption === 'Annual' ? 'white' : 'transparent',
                                                '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                                            }}>Annual</Button>
                                            </Grid>
                                        </Grid>
                                      
                                    </Box>
                                        </Grid>
                                </Grid>
                                
            {/* <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Select
                  sx={{
                    ...style.inputFiled,
                    height: '70%',
                    width: '50%',
                    marginTop: "2%",
                    marginLeft: '6%',
                  }}
                  id='player'
                  //label="Select Player"
                  value={selectedItem} // Ensure a default or empty string if selectedItem is falsy
                  onChange={
                    handleChange// Pass the selected value to getEvent
                  }
                >
                  {selectPlayer.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.userId}>
                        {option.userName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>

              <div style={{ flex: 1 }}>
                <Box sx={{ mb: 3, mr: 6 }}>
                  <Typography variant='h4' sx={{
                    color: 'rgba(0, 0, 0, 0.613)', fontWeight: 600, marginTop: "1%", width: '130%'

                  }}> Analytic Overview of<span style={{ color: '#345EB8', fontWeight: 600, fontSize: 40 }}> {playerName}</span></Typography>
                </Box>
              </div>

              <div style={{ flex: 1 }}>
                <Select
                  sx={{
                    ...style.inputFiled,
                    height: '70%',
                    width: '50%',
                    marginTop: "2%",
                    marginLeft: '40%'
                    // ml:{xs:2,md:30,sm:3}
                  }}
                  value={viewType}
                  id='player'
                  // label="Select Player"
                  onChange={(e) => setViewType(e.target.value)}
                >
                  <MenuItem value="option1"
                    onClick={() => handleOptionClick('All')}

                    sx={{
                      color: selectedOption === 'All' ? 'white' : 'black',
                      background: selectedOption === 'All' ? 'white' : 'transparent',
                      '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                    }}
                  >All</MenuItem>
                  <MenuItem value="option2"
                    onClick={() => handleOptionClick('Weekly')}

                    sx={{
                      color: selectedOption === 'Week' ? 'white' : 'black',
                      background: selectedOption === 'Week' ? 'white' : 'transparent',
                      '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                    }}

                  >Week</MenuItem>
                  <MenuItem value="option3"
                    onClick={() => handleOptionClick('Month')}
                    sx={{
                      color: selectedOption === 'Month' ? 'white' : 'black',
                      background: selectedOption === 'Month' ? 'white' : 'transparent',
                      '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                    }}
                  >Month</MenuItem>
                  <MenuItem value="option4"
                    onClick={() => handleOptionClick('Annual')}
                    sx={{
                      color: selectedOption === 'Annual' ? 'white' : 'black',
                      background: selectedOption === 'Annual' ? 'white' : 'transparent',
                      '&:hover': { background: '#24EDE3' }, height: '40px', width: '300px',
                    }}>Annual</MenuItem>
                </Select>
              </div>
            </div> */}

            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>,
                <Box sx={{ height: '25%', ml: '2%' }}>
                  <Grid container spacing={{ xs: 2, md: 1, sm: 2 }} columns={{ xs: 1, sm: 3, md: 4 }} sx={{ elevation: 3, height: '90%' }}>
                    <Grid item xs={1} sm={1} md={1} >
                      <Paper elevation={4} sx={{ marginLeft: '5%', height: '100%', borderRadius: '20px', backgroundColor: " #FFFFFFD8" }}>
                        <Typography variant='h4' sx={{ color: 'black', fontWeight: '700', fontSize: '25px', textAlign: "center", pt: '25%' }}>Personal Best </Typography>
                        <Typography variant='h4' sx={{ color: 'blue', fontWeight: 600, fontSize: '30px', textAlign: 'center' }}>{personalBest}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} >
                      <Grid container spacing={{ xs: 2, md: 1, sm: 1 }} columns={{ xs: 1, sm: 1, md: 1 }} sx={{ elevation: 3, }}>
                        <Grid item xs={1} sm={1} md={1} >
                          <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: " #FFFFFFD8" }}>
                            <Typography variant='h4' sx={{ color: 'black', fontWeight: 600, fontSize: '25px', paddingLeft: '25px', paddingTop: '20px', textAlign: 'center', paddingBottom: '1%' }}>Biometrics </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} >
                          <Grid container spacing={{ xs: 2, md: 1, sm: 1 }} columns={{ xs: 1, sm: 2, md: 4 }} sx={{ elevation: 3, height: '100%' }}>
                            <Grid item xs={1} sm={1} md={1} >
                              <Paper elevation={4} sx={{ height: '100%', borderRadius: '10px', backgroundColor: " #FFFFFFD8" }}>
                                <p style={{ paddingLeft: '10%', paddingTop: '2%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}> <AccessibilityNewIcon style={{ color: 'blue' }} /> {bioMetric.height} cm</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><MonitorWeightIcon style={{ color: 'green' }} /> {bioMetric.weight} kg</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><Man3Icon style={{ color: 'orange', paddingTop: '1px' }} /> {bioMetric.ankle} cm</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><Man4Icon style={{ color: 'gray', paddingTop: '1px' }} /> {bioMetric.hip} cm</p>
                              </Paper>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} >
                              <Paper elevation={4} sx={{ height: '100%', borderRadius: '10px', backgroundColor: " #FFFFFFD8" }}>
                                <p style={{ paddingLeft: '10%', paddingTop: '2%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><HealthAndSafetyIcon style={{ color: 'red', }} /> {bioMetric.restHeart} bpm</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}> <MonitorHeartIcon style={{ color: 'purple' }} /> {bioMetric.targetHeart} bpm</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><DeviceThermostatIcon style={{ color: 'darkGreen' }} /> {bioMetric.beforeTemp} c</p>
                              </Paper>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} >
                              <Paper elevation={4} sx={{ height: '100%', borderRadius: '10px', backgroundColor: " #FFFFFFD8" }}>
                                <p style={{ paddingLeft: '10%', paddingTop: '2%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><DeviceThermostatIcon style={{ color: 'darkViolet' }} /> {bioMetric.afterTemp} c</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><BloodtypeIcon style={{ color: 'blue' }} /> {bioMetric.beforeTrail} MmHg</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><BloodtypeIcon style={{ color: 'darkOrange' }} /> {bioMetric.afterTrail} MmHg</p>    </Paper>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} >
                              <Paper elevation={4} sx={{ height: '100%', borderRadius: '10px', backgroundColor: " #FFFFFFD8" }}>
                                <p style={{ paddingLeft: '10%', paddingTop: '2%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><AccessibilityNewIcon style={{ color: 'blue', }} /> {bioMetric.bmi} MmHg</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><BloodtypeIcon style={{ color: 'red' }} /> {bioMetric.bloodPres} MmHg</p>
                                <p style={{ paddingLeft: '10%', color: 'black', fontWeight: 600, fontFamily: 'revert' }}><ThermostatAutoIcon style={{ color: 'green' }} /> {bioMetric.bodyTemp} MmHg</p>

                              </Paper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Paper elevation={4} sx={{ marginTop: '40px', marginLeft: '30px', height: '445px', width: '1080px', borderRadius: '20px', backgroundColor: " #FFFFFFD8" }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      <Typography variant='h4' sx={{ color: 'black', fontWeight: 600, fontSize: '18px', paddingLeft: '25px', paddingTop: '20px' }}>Performance</Typography>
                    </div>
                    <div style={{ flex: 1, paddingTop: '20px' }}>
                      <Select
                        sx={{
                          ...style.inputFiled,
                          height: '50px',
                          width: '200px',
                          marginLeft: '320px'
                        }}
                        id='player'
                        // label="Select Player"
                        value={selectEventPlayer}
                        onChange={handleEventChange}
                      >
                        {selectEvent.map((option, index) => (
                          <MenuItem key={index} value={option.subEventId}>
                            {option.subEventName}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {showCharts && (morningPerf.length !== 0 || eveningPerf.length !== 0) ? (
                    <LineChart
                      //   yAxis={[{ data: [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50] }]}
                      //   xAxis={[{ data: [2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50] }]}
                      // xAxis={[{ data: date.map(i => i) }]}
                      //  xAxis={{ data: morningPerf.map(item => item.createDate) }}
                      height={370}

                      width={1100}

                      series={[

                        { name: "Morning", curve: "natural", data: morningPerf.map(item => item.performance) },
                        { name: "Evening", curve: "natural", data: eveningPerf.map(item => item.performance) },
                      ]}
                      xAxis={[{ scaleType: 'point', data: extendedMorningDates }]}
                      yAxis={[{ data: reversedMorningPerf, min: 2 }]}

                    />
                  ) : <center>
                    <p style={{ marginTop: '200px' }}>No data</p>
                  </center>
                  }

                </Paper>
              </div>

              <div style={{ flex: 1 }}>,
                <Paper elevation={4} sx={{ height: '655px', marginRight: '30px', marginLeft: '15px', borderRadius: '20px', marginBottom: '30px', width: '650px', backgroundColor: " #FFFFFFBD" }}>
                  <Typography variant='h4' sx={{ color: 'black', fontWeight: 600, fontSize: '18px', paddingLeft: '25px', paddingTop: '20px', mb: '10%' }}>Preparation </Typography>
                  <Stack direction="row">
                    <BarChart
                      series={[
                        { data: [allPerData], label: 'Set', color: '#D34562', sensitive: false, barSize: { width: 10, height: 10 } },  // Red
                        { data: [allRepData], label: 'Reps', color: '#00bfff', sensitive: false, barSize: { width: 10, height: 30 } },  // Blue
                        { data: [allRestData], label: 'Rest', color: '#ffd700', sensitive: false, barSize: { width: 10, height: 30 } },  // Yellow
                        { data: [allActiveData], label: 'Time', color: '#9900FFB7', sensitive: false, barSize: { width: 10, height: 30 } },  // Yellow

                      ]}
                      width={700}
                      height={450}

                    />

                  </Stack>
                </Paper>

              </div>
            </div>

          </div>
        ) : (
          <Box>
            <Typography variant="body2">
              <Avatar src={noDataIcon} style={{ width: '70%', height: '70%', marginLeft: '15%' }} alt="" />
            </Typography>
          </Box>
        )}

      </Paper></Box>

  );
};

export default UserAnalytics;