import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { TableCell, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Style from "../../Common/Components/Styles";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";

export default function ViewPlayers({ post }) {
  const [players, setplayers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState();

  useEffect(() => {
    api
      .get("users/coachId/" + post.userId)
      .then((response) => {
        setplayers(response.data);
        setFilteredData(response.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [post.userId]);

  useEffect(() => {
    // Check if there's a search query
    if (searchQuery) {
      // Filter players based on search query
      const filtered = players.filter(
        (player) =>
          player?.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          player?.userId.toString().includes(searchQuery)
      );
      setFilteredData(filtered);
    } else {
      // If no search query, use the original players data
      setFilteredData(players);
    }
  }, [searchQuery, players]);

  const row = filteredData.map((player, index) => {
    return {
      id: player.loginId,
      playerName: player.userName,
    };
  });

  return (
    <Fragment>
      <TableCell align="center" sx={{ border: "none" }}>
        <MoreHorizIcon
          variant="outlined"
          style={{ cursor: "pointer" }}
          onClick={handleOpen}
        />
      </TableCell>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Style.widthPopupStyle}>
          <div style={{ position: "absolute", top: "0px", right: "0px" }}>
            <IconButton
              sx={{ color: "var(--app-bar-color)" }}
              onClick={handleClose}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <Typography
            sx={{
              flex: 1,
              mb: 1,
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
            component="div"
          >
            Player's assigned to {post.userName}
          </Typography>
          {/* </Toolbar>
        </AppBar> */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{
                  fontFamily: "var(--font-family)",
                  display: "flex",
                  alignItems: "center",
                }}
                InputProps={{
                  style: {
                    borderRadius: "40px",
                  },
                }}
                size="small"
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                inputProps={{
                  style: {
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "var(--font-color)", // Add your label style here
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    fontWeight: "bold",
                  },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "end" }}>
              <Typography
                sx={{
                  mb: 1,
                  display: "flex",
                  justifyContent: "end",
                  fontFamily: "var(--font-family)",
                  backgroundColor: "var(--app-bar-color)",
                  padding: "10px",
                  borderRadius: "15px",
                  color: "white",
                }}
                variant="h5"
                component="div"
              >
                {players.length}
              </Typography>
            </Box>
          </Box>
          <GridTable rows={row} columns={GetData.view_player} height={400} />
        </Box>
      </Modal>
    </Fragment>
  );
}
