import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";

export const AdminProfile = () => {
  // const userId = sessionStorage.getItem("userId");
  const academyId = sessionStorage.getItem("academyId");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");

  // const [updateData, setUpdateData] = useState({});
  const [prefix, setPrefix] = useState("");
  const [academyName, setAcademyName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [foundedOn, setFoundedOn] = useState("");
  const [academyDetails, setAcademyDetails] = useState({});

  // const [isDisabled, setIsDisabled] = useState(false);
  const { toast, contextHolder } = ToastMessage();

  // console.log("updateData", updateData);

  const navigate = useNavigate();

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // const [contactNumber, setContactNumber]= useState('')
  const fetchData = useCallback(async () => {
    try {
      toast("loading", "Loading...");
      const academyDetail = await api
        .get(`/academy/${academyId}`)
        .then((res) => res.data)
        .catch((err) => console.error(err));
      setAcademyDetails(academyDetail);
      setAcademyName(academyDetail.academyName);
      setEmailId(academyDetail.emailId);
      setContactNumber(academyDetail.contactNumber);
      setContactPerson(academyDetail.contactPerson);
      setAddress(academyDetail.address);
      setCity(academyDetail.city);
      setPinCode(academyDetail.pinCode);
      setPrefix(academyDetail.prefix);
      setFoundedOn(academyDetail.createdDate);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }, [academyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSubmit() {
    try {
      if (
        !address ||
        !city ||
        !pinCode ||
        !contactPerson ||
        !contactNumber ||
        !emailId
      ) {
        // Create an array to store the names of missing fields
        const missingFields = [];

        // Check each field individually and add its name to the array if it's empty
        if (!address) missingFields.push("Address");
        if (!city) missingFields.push("City");
        if (!pinCode) missingFields.push("Pincode");
        if (!contactPerson) missingFields.push("Contact Person");
        if (!contactNumber) missingFields.push("Contact Number");
        if (!emailId) missingFields.push("Email ID");

        // Create the alert message based on the missing fields
        let alertMessage =
          "Please fill in fields:\n" + missingFields.join("\n");

        // Display the alert
        alert(alertMessage);

        return;
      }

      // if (user.userId) {
      //   const personalDetails = {
      //     name: firstName,
      //     fatherName: lastName,
      //     // gender: gender,
      //     dob: dob,
      //     street: address,
      //     city: city,
      //     pinCode: pinCode,
      //     mobileNo: phoneNo,
      //     email: email,
      //     // height: height,
      //     // weight: weight,
      //     createdDate: getCurrentSQLDate(),
      //     userId: userId,
      //     academyId: sessionStorage.getItem("academyId"),
      //     createdBy: sessionStorage.getItem("userId"),
      //     coachId: sessionStorage.getItem("userId"),
      //   };

      //   const personalResponse = await api.get("/personal_info/all");
      //   console.log(personalResponse.data);

      //   const id = personalResponse.data.find(
      //     (item) => item.userId === user.userId
      //   );

      //   if (id && id.personalInfoId !== undefined) {
      //     const res = id.personalInfoId;
      //     console.log("hi", res);
      //     try {
      //       const update = await api.put(
      //         `/personal_info/${res}`,
      //         personalDetails
      //       );
      //       setUpdateData(update.data);
      //       console.log("profile update successfully", update);
      //       toast("success", "Updated Successfully...");
      //       setFirstName(update.data.name);
      //       setLastName(update.data.fatherName);
      //       // setGender(update.data.gender);
      //       setDob(update.data.dob);
      //       setAddress(update.data.street);
      //       setCity(update.data.city);
      //       setPinCode(update.data.pinCode);
      //       setPhoneNo(update.data.mobileNo);
      //       setEmail(update.data.email);
      //       // setHeight(update.data.height);
      //       // setWeight(update.data.weight);
      //       console.log(update.data.name);
      //       const userUpdate = {
      //         ...user,
      //         userName: update.data.name,
      //         email: update.data.email,
      //       };
      //       console.log("user", user);
      //       console.log(userUpdate);
      //       const userTable = await api.put(`/users/${userId}`, userUpdate);
      //       console.log(userTable.data);
      //       sessionStorage.setItem("userName", firstName);
      //       navigate("/adminprofile");
      //     } catch (error) {
      //       console.error("Error updating profile:", error);
      //     }
      //   } else {
      //     const responsePost = await api.post(
      //       "/personal_info/add",
      //       personalDetails
      //     );
      //     console.log("profile post successfully", responsePost);
      //     toast("success", "Added Successfully...");
      //     setIsProfileExists(false);
      //   }
      // }
    } catch (err) {
      console.log(err);
    }
  }

  const location = sessionStorage.getItem("location");

  const navigatePath = () => navigate(`${location}`);

  return (
    <>
      <Box className="common-wrapper">
        {contextHolder}
        <Box>
          <CustomCommonButton
            onClick={navigatePath}
            variant="contained"
            text="BACK"
            icon={<ArrowCircleLeftIcon sx={{ fontSize: "25px" }} />}
          />
        </Box>
        <Paper
          elevation={3}
          sx={{
            borderRadius: "var(--roundness)",
            p: 5,
            mt: 2,
            userSelect: "none",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              color: "var(--app-bar-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--header-font-size)",
              mb: 2,
            }}
          >
            Academy Info
          </Typography>
          <Grid
            container
            spacing={2}
            columns={{ sx: 1, sm: 1, md: 3, lg: 3, xl: 3 }}
          >
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={academyName}
                name={"academyName"}
                onChange={(e) => setAcademyName(e.target.value)}
                label={"Academy Name"}
                type="text"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={foundedOn}
                name={"foundedOn"}
                onChange={(e) => setFoundedOn(e.target.value)}
                label={"Founded On"}
                type="date"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={prefix}
                name={"prefix"}
                onChange={(e) => setPrefix(e.target.value)}
                label={"Prefix"}
                type="text"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={address}
                name={"address"}
                onChange={(e) => setAddress(e.target.value)}
                label={"Address"}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={city}
                name={"city"}
                onChange={(e) => setCity(e.target.value)}
                label={"City"}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={pinCode}
                name={"pinCode"}
                onChange={(e) => setPinCode(e.target.value)}
                label={"PIN Code"}
                maxLength={6}
                type="number"
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={contactPerson}
                name={"contact person"}
                onChange={(e) => setContactPerson(e.target.value)}
                label={"Contact Person"}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={contactNumber}
                name={"contact number"}
                onChange={(e) => setContactNumber(e.target.value)}
                label={"Contact Number"}
                type="number"
                maxLength={10}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={1} xl={1}>
              <CommonTextField
                value={emailId}
                name={"emailId"}
                onChange={(e) => setEmailId(e.target.value)}
                label={"Email ID"}
                type="mail"
                readOnly={true}
              />
            </Grid>
            {/* <Grid item xs={1} md={1} lg={1} xl={1}>
              <CustomCommonButton
                text="Update"
                icon={<Update />}
                onChange={handleSubmit}
              />
            </Grid> */}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
