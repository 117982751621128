import { EditNoteRounded, Update } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  FormHelperText,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import ToastMessage from "../../Common/Components/ToastMessage";
import { CommonAddIcon } from "../../Common/IconsAndComps";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import Style from "../../Common/Components/Styles";

const style = {
  formControl1: {
    width: "100%",
  },
  label: {
    color: "#000",
    fontWeight: 500,
    fontFamily: "var(--font-family)",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: "grey",
      fontSize: "15px",
      fontFamily: "var(--font-family)",
    },
  },
  inputFiled: {
    color: "#000",
    background: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "var(--font-family)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
};

const RecordMaster = () => {
  const [event, setEvent] = useState([]);
  const [selectEvent, setSelectEvent] = useState("");
  const [subEvent, setSubEvent] = useState([]);
  const [selectSubEvent, setSelectSubEvent] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [selectWorld, setSelectWorld] = useState();
  const [selectNational, setSelectNational] = useState();
  const [selectState, setSelectState] = useState();
  const [editWorld, setEditWorld] = useState("");
  const [editNational, setEditNational] = useState("");
  const [editState, setEditState] = useState("");
  const [recordSet, setRecordSet] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterEvent, setFilterEvent] = useState("All");
  const [eventDisplay, setEventDisplay] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [singleRecord, setSingleRecord] = useState([]);
  const [selectedGender, setSelectedGender] = useState("All");
  const { toast, contextHolder } = ToastMessage();

  const handleBlur = (setter) => (e) => {
    const value = e.target.value;
    const regex =
      selectEvent === 1
        ? /^(\d{0,2}:)?(\d{0,2})(\.?\d{0,3})?$/
        : /^(\d{0,2})(\.?\d{0,3})?$/;
    if (value === "" || regex.test(value)) {
      setter(value);
    } else {
      toast(
        "warning",
        `Please enter a valid time in ${
          selectEvent === 1 ? "mm:ss.sss" : "m.cm"
        } format`
      );
      setter(""); // Clear the input if invalid
    }
  };

  const handleInputEditChange = (setter) => (e) => {
    const value = e.target.value;
    const timeRegex =
      selectEvent === 1
        ? /^(\d{0,2}:)?(\d{0,2})(\.?\d{0,3})?$/
        : /^(\d{0,2})(\.?\d{0,3})?$/;
    if (timeRegex.test(value)) {
      setter(value);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setBackdropOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBackdropOpen(false);
  };
  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  async function getSubEvent(selectedEventId) {
    try {
      const subEventResponse = await api.get(
        `/sub_events/eventId/${selectedEventId}`
      );
      const subEventData = subEventResponse.data;
      setSubEvent(subEventData);
    } catch (err) {
      console.log("error in getSubEvents", err);
    }
  }

  async function getEvent() {
    try {
      const eventResponse = await api.get("/event_type/all");
      const eventData = eventResponse.data;
      setEvent(eventData);
    } catch (err) {
      console.log("error in getEvent", err);
    }
  }

  async function getSubEventDisplay() {
    try {
      const subEventResponse = await api.get("/sub_events/all");
      const subEventData = subEventResponse.data;
      setEventDisplay(subEventData);
    } catch (err) {
      console.log("error in getEvent", err);
    }
  }
  const [units, setUnits] = useState([]);

  const getAllUnits = async () => {
    try {
      const response = await api.get("/unit/all");
      setUnits(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getAllUnits();
  }, []);

  function formatTime(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    let result = "";

    if (minutes > 0) {
      result += String(minutes).padStart(1, "0") + ":";
    }

    result += remainingSeconds.toFixed(3);

    return result;
  }

  // function formatTimeMinute(seconds) {
  //   if (isNaN(seconds) || seconds < 0) {
  //     return "Invalid input";
  //   }

  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;

  //   let result = "";

  //   if (minutes > 0) {
  //     result += String(minutes).padStart(1, "0") + ":";
  //   }

  //   result += remainingSeconds.toFixed(0);

  //   return result;
  // }

  async function getRecords() {
    try {
      toast("loading", "Loading...");
      const record = await api.get("/record/all");
      const setRecord = record.data;
      setRecordSet(setRecord);
    } catch (err) {
      console.log("error in getRecords");
    }
  }
  function convertToSeconds(timeString) {
    if (timeString === undefined || timeString === null) {
      throw new Error("Invalid time format: timeString is undefined or null");
    }

    const timeParts = timeString.split(":");
    let minutes = 0;
    let seconds = 0;
    if (timeParts.length === 2) {
      minutes = parseInt(timeParts[0], 10);
      seconds = parseFloat(timeParts[1]);
    } else if (timeParts.length === 1) {
      seconds = parseFloat(timeParts[0]);
    } else {
      toast("success", "Invalid TIME FORMAT! please add to valid format");
      throw new Error("Invalid time format");
    }

    return minutes * 60 + seconds;
  }

  async function handleAddRecord() {
    if (!selectEvent || !selectGender || !selectSubEvent) {
      toast("error", `Please select Event, Sub event and Gender`);
      return;
    }
    const regex = /^(\d{0,2}:)?(\d{0,2})(\.?\d{0,3})?$/;
    if (
      !regex.test(selectWorld) ||
      !regex.test(selectState) ||
      !regex.test(selectNational)
    ) {
      toast(
        "warning",
        `Please enter a valid time in ${
          selectEvent === 1 ? "mm:ss.sss" : "m.cm"
        } format`
      );
      return;
    }
    try {
      toast("loading", "Loading...");
      let worldSeconds, nationalSeconds, stateSeconds;

      if (parseInt(selectEvent) === 1) {
        worldSeconds = selectWorld ? convertToSeconds(selectWorld) : 0;
        nationalSeconds = selectNational ? convertToSeconds(selectNational) : 0;
        stateSeconds = selectState ? convertToSeconds(selectState) : 0;
      } else if (parseInt(selectEvent) === 2 || parseInt(selectEvent) === 3) {
        worldSeconds = selectWorld;
        nationalSeconds = selectNational;
        stateSeconds = selectState;
      }

      const duplicateGender = await api.get(`/record/all`, {
        params: {
          subEventId: selectSubEvent,
          gender: selectGender,
          eventTypeId: parseInt(selectEvent),
        },
      });

      const isDuplicate = duplicateGender.data.some((data) => {
        return (
          parseInt(data.eventTypeId) === parseInt(selectEvent) &&
          parseInt(data.subEventId) === parseInt(selectSubEvent) &&
          parseInt(data.gender) === parseInt(selectGender)
        );
      });
      const response = await api.get("/sub_events/all");
      const data = response.data;
      const filter = data.find((item) => item.subEventId == selectSubEvent);

      // Check if 'isDuplicate' property is present and is true
      if (isDuplicate) {
        toast(
          "error",
          `Record already exists for ${capitalizeFirstLetter(
            selectGender
          )} in ${filter.subEventName}`
        );
        handleClose();
        setSelectEvent("");
        setSelectSubEvent("");
        setSelectGender("");
        setSelectWorld("");
        setSelectNational("");
        setSelectState("");
        return;
      }

      const addRecord = {
        eventTypeId: selectEvent,
        subEventId: selectSubEvent,
        gender: selectGender,
        worldValue: parseFloat(worldSeconds),
        worldDate: getCurrentSQLDate(),
        nationalValue: parseFloat(nationalSeconds),
        nationalDate: getCurrentSQLDate(),
        stateValue: parseFloat(stateSeconds),
        stateDate: getCurrentSQLDate(),
      };
      console.log("addRecord", addRecord);
      if (addRecord.eventTypeId == 1) {
        if (
          addRecord.worldValue < addRecord.nationalValue &&
          addRecord.nationalValue < addRecord.stateValue
        ) {
          const add = await api.post("/record/add", addRecord);
          console.log("Successfully added", add);
          toast("success", "Record added successfully");
          getRecords();
          handleClose();
          setSelectEvent("");
          setSelectSubEvent("");
          setSelectGender("");
          setSelectWorld("");
          setSelectNational("");
          setSelectState("");

          // window.location.reload();
        } else if (addRecord.worldValue >= addRecord.nationalValue) {
          toast(
            "error",
            "WorldValue is greater than or equal to NationalValue"
          );
        } else if (addRecord.nationalValue >= addRecord.stateValue) {
          toast(
            "error",
            "NationalValue is greater than or equal to StateValue"
          );
        }
      }

      if (addRecord.eventTypeId == 2 || addRecord.eventTypeId == 3) {
        if (
          addRecord.worldValue > addRecord.nationalValue &&
          addRecord.nationalValue > addRecord.stateValue
        ) {
          const add = await api.post("/record/add", addRecord);
          console.log("Successfully added", add);
          toast("success", "Record added successfully");

          handleClose();
          setSelectEvent("");
          setSelectSubEvent("");
          setSelectGender("");
          setSelectWorld("");
          setSelectNational("");
          setSelectState("");
          getRecords();
          // window.location.reload();
        } else if (addRecord.worldValue <= addRecord.nationalValue) {
          toast(
            "error",
            "WorldValue is greater than or equal to NationalValue"
          );
        } else if (addRecord.nationalValue <= addRecord.stateValue) {
          toast(
            "error",
            "NationalValue is greater than or equal to StateValue"
          );
        }
      }
    } catch (err) {
      console.log("Error in addRecord", err);
    }
  }

  const handleEdit = (record) => {
    setSelectedRecordId(record.recordId);
    setSelectEvent(record.eventTypeId);
    setEditState(record.stateValue);
    setEditNational(record.nationalValue);
    setEditWorld(record.worldValue);
    setEditMode(true);
  };
  const handleEditCloseModel = () => {
    setSelectedRecordId(null);
    setEditState("");
    setEditNational("");
    setSelectEvent("");
    setEditWorld("");
    setEditMode(false);
  };

  async function handleEditRecord() {
    try {
      const response = await api.get(`/record/${selectedRecordId}`);
      console.log(response.data.eventTypeId);

      let worldSeconds, nationalSeconds, stateSeconds;

      if (response.data.eventTypeId === 1) {
        worldSeconds = convertToSeconds(editWorld);
        nationalSeconds = convertToSeconds(editNational);
        stateSeconds = convertToSeconds(editState);
      } else {
        worldSeconds = editWorld;
        nationalSeconds = editNational;
        stateSeconds = editState;
      }
      console.log("Before setRecord in handleEditRecord:", selectedRecordId);

      const update = {
        ...singleRecord,
        worldValue: worldSeconds,
        stateValue: stateSeconds,
        nationalValue: nationalSeconds,
        eventTypeId: response.data.eventTypeId,
        subEventId: response.data.subEventId,
        gender: response.data.gender,
        stateDate: getCurrentSQLDate(),
        nationalDate: getCurrentSQLDate(),
        worldDate: getCurrentSQLDate(),
      };
      console.log(update);
      if (update.eventTypeId === 2 || update.eventTypeId === 3) {
        if (update.worldValue <= update.nationalValue) {
          toast(
            "error",
            "WorldValue should greater than or equal to NationalValue"
          );
          return;
        } else if (update.nationalValue <= update.stateValue) {
          toast(
            "error",
            "NationalValue should greater than or equal to StateValue"
          );
          return;
        }
      }

      if (update.eventTypeId === 1) {
        if (update.worldValue >= update.nationalValue >= update.stateValue) {
          toast(
            "error",
            "WorldValue should less than or equal to NationalValue"
          );
          return;
        } else if (update.nationalValue >= update.stateValue) {
          toast(
            "error",
            "NationalValue should less than or equal to StateValue"
          );
          return;
        }
      }

      const add = await api.put(`/record/${selectedRecordId}`, update);
      console.log("success", add.data);
      toast("success", "Record update successfully");
      handleClose();
      setEditMode(false);
      setEditOpen(false);
      getRecords();
      // window.location.reload();
    } catch (err) {
      console.error("Error in editing record", err);
    }
  }

  async function setRecord() {
    try {
      const response = await api.get(`/record/${selectedRecordId}`);

      // Function to convert seconds to "min:sec.ms" format
      const formatDuration = (durationInSeconds) => {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = (durationInSeconds % 60).toFixed(3);
        return `${minutes}:${seconds}`;
      };

      let nationalValueFormatted = "";
      let worldValueFormatted = "";
      let stateValueFormatted = "";

      if (response.data.eventTypeId === 1) {
        // Assuming nationalValue is in seconds, convert it to the desired format
        nationalValueFormatted = formatDuration(response.data.nationalValue);
        stateValueFormatted = formatDuration(response.data.stateValue);
        worldValueFormatted = formatDuration(response.data.worldValue);
        setSingleRecord(response.data);
        setEditNational(nationalValueFormatted);
        setEditWorld(worldValueFormatted);
        setEditState(stateValueFormatted);
      } else {
        setSingleRecord(response.data);
        setEditNational(response.data.nationalValue);
        setEditWorld(response.data.worldValue);
        setEditState(response.data.stateValue);
      }
    } catch (err) {
      console.error("Error fetching record data:", err);
    }
  }

  function capitalizeFirstLetter(str) {
    if (!str || str.length === 0) {
      return ""; // Handle the case when str is null or empty
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    getEvent();
    getRecords();
    getSubEventDisplay();
  }, []);

  useEffect(() => {
    if (selectedRecordId && editMode) {
      // Fetch the record when edit mode is enabled
      setRecord(selectedRecordId);
    }
  }, [selectedRecordId, editMode]);
  useEffect(() => {
    if (selectEvent) {
      getSubEvent(selectEvent);
    }
  }, [selectEvent]);
  useEffect(
    () => {
      setSelectWorld("");
      setSelectNational("");
      setSelectState("");
    },
    [
      /* dependency array */
    ]
  );

  const filterRecord = recordSet.filter(
    (item) =>
      (selectedGender === "All" ||
        item.gender.toLowerCase() === selectedGender) &&
      (filterEvent === "All" ||
        (event &&
          event.find(
            (e) =>
              e.eventTypeId === item.eventTypeId &&
              e.eventType.toLowerCase() === filterEvent.toLowerCase()
          )))
  );

  console.log(filterRecord);

  const rows = filterRecord.map((item, index) => {
    // Find the subEvent that matches the current item's subEventId
    const subEvent =
      eventDisplay &&
      eventDisplay.find((sub) => sub.subEventId === item.subEventId);

    // Check if subEvent is found before trying to access its unitId property
    const unit = subEvent
      ? units.find(
          (unit) => parseInt(unit.unitId) === parseInt(subEvent.unitId)
        )
      : null;

    return {
      id: index + 1,
      event:
        (
          event &&
          event.find(
            (e) => parseInt(e.eventTypeId) === parseInt(item.eventTypeId)
          )
        )?.eventType || "--",
      subEvent: subEvent?.subEventName || "--",
      gender: capitalizeFirstLetter(item.gender) || "--",
      world:
        parseInt(item.eventTypeId) === 1
          ? formatTime(item.worldValue)
          : item.worldValue,
      national:
        parseInt(item.eventTypeId) === 1
          ? formatTime(item.nationalValue)
          : item.nationalValue,
      state:
        parseInt(item.eventTypeId) === 1
          ? formatTime(item.stateValue)
          : item.stateValue,
      unit: unit?.pluralName || "--",
      action: (
        <>
          <EditNoteRounded color="success" onClick={() => handleEdit(item)} />
          {/* <DeleteRounded
            color="error"
            sx={{ ml: 2 }}
            onClick={() => handleDelete(item.recordId)}
          /> */}
        </>
      ),
    };
  });

  const GENDER = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];
  const EVENT = [
    { name: "Track", value: "track" },
    { name: "Field-Jump", value: "field-jump" },
    { name: "Field-Throw", value: "field-throw" },
  ];
  return (
    <Box className="common-wrapper">
      {contextHolder}
      <Grid container spacing={2} columns={{ sm: 1, md: 3, lg: 3 }}>
        <Grid item xs={1} md={0.5}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label="Filter By Event"
            value={filterEvent}
            onChange={(e) => setFilterEvent(e.target.value)}
            array={EVENT}
            menuValue={"value"}
            display={"name"}
          />
        </Grid>
        <Grid item xs={1} md={0.5}>
          <CommonDropdown
            firstOption={"All"}
            search={true}
            label="Filter By Gender"
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
            array={GENDER}
            menuValue={"value"}
            display={"name"}
          />
        </Grid>
        <Grid item xs={1} md={1} />
        <Grid
          item
          xs={1}
          md={1}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <CustomCommonButton
            onClick={handleOpen}
            text="Record"
            icon={CommonAddIcon}
          />
        </Grid>
      </Grid>
      <Modal keepMounted open={open} onClose={handleClose}>
        <Box sx={Style.widthPopupStyle()}>
          <Typography
            variant="h4"
            sx={{
              color: "var(--app-bar-color)",
              fontWeight: 700,
              mb: 2,
              textAlign: "center",
              mt: 1,
              fontFamily: "var(--font-family)",
            }}
          >
            Create Record
          </Typography>
          <Grid container columns={{ sm: 1, md: 3, lg: 3 }} spacing={2}>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>Event</InputLabel>
                <Select
                  style={{ borderRadius: 12 }}
                  sx={style.inputFiled}
                  id="event"
                  label="event"
                  value={selectEvent}
                  onChange={(e) => setSelectEvent(e.target.value)}
                >
                  {event.map((option) => (
                    <MenuItem
                      key={option.eventTypeId}
                      value={option.eventTypeId}
                    >
                      {option.eventType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>Sub Event</InputLabel>
                <Select
                  style={{ borderRadius: 12 }}
                  sx={style.inputFiled}
                  id="sub event"
                  label="sub event"
                  value={selectSubEvent}
                  onChange={(e) => setSelectSubEvent(e.target.value)}
                >
                  {subEvent.map((option) => (
                    <MenuItem key={option.subEventId} value={option.subEventId}>
                      {option.subEventName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>Gender</InputLabel>
                <Select
                  style={{ borderRadius: 12 }}
                  sx={style.inputFiled}
                  id="gender"
                  label="gender"
                  value={selectGender}
                  onChange={(e) => setSelectGender(e.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
              {/* {parseInt(selectEvent) === 1 ? (
            <>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>World Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="world"
                  type="text"
                  label="World Record"
                  placeholder="mm:ss.sss"
                  value={selectWorld}
                  onChange={handleInputChange(setSelectWorld)}
                  onBlur={handleInputBlur(setSelectWorld)}
                />
              </FormControl>

              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>National Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="national"
                  type="text"
                  label="National Record"
                  placeholder="mm:ss.sss"
                  value={selectNational}
                  onChange={handleInputChange(setSelectNational)}
                  onBlur={handleInputBlur(setSelectNational)}
                />
              </FormControl>

              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>State Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="state"
                  type="text"
                  label="State Record"
                  placeholder="mm:ss.sss"
                  value={selectState}
                  onChange={handleInputChange(setSelectState)}
                  onBlur={handleInputBlur(setSelectState)}
                />
              </FormControl>
            </>
          ) : (
            <> */}{" "}
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>World Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="world"
                  type="text"
                  label="World Record"
                  placeholder={
                    parseInt(selectEvent) === 1 ? "mm:ss.sss" : "m.cm"
                  }
                  value={selectWorld}
                  onChange={handleInputEditChange(setSelectWorld)}
                  onBlur={handleBlur(setSelectWorld)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>National Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="national"
                  type="text"
                  label="National Record"
                  placeholder={
                    parseInt(selectEvent) === 1 ? "mm:ss.sss" : "m.cm"
                  }
                  value={selectNational}
                  onChange={handleInputEditChange(setSelectNational)}
                  onBlur={handleBlur(setSelectNational)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>State Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="state"
                  type="text"
                  label="State Record"
                  placeholder={
                    parseInt(selectEvent) === 1 ? "mm:ss.sss" : "m.cm"
                  }
                  value={selectState}
                  onChange={handleInputEditChange(setSelectState)}
                  onBlur={handleBlur(setSelectState)}
                />
              </FormControl>
              {/* </>
          )} */}
            </Grid>
            <Grid
              item
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CustomCommonButton
                onClick={handleAddRecord}
                icon={CommonAddIcon}
                text="Add"
              />
            </Grid>
          </Grid>

          {/* <Button
            variant="contained"
            onClick={handleAddRecord}
            sx={{
              m: 2,
              borderRadius: 3,
              background: "var(--app-bar-color)",
              width: 150,
              ml: 28,
              fontFamily: "var(--font-family)",
            }}
          >
            Add
          </Button> */}
        </Box>
      </Modal>
      <Modal
        open={editMode}
        onClose={handleEditCloseModel}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: { backgroundColor: "transparent" },
        }}
      >
        <Box sx={Style.widthPopupStyle()}>
          <Typography
            variant="h4"
            sx={{
              color: "var(--app-bar-color)",
              fontWeight: 700,
              mb: 2,
              textAlign: "center",
              mt: 1,
              fontFamily: "var(--font-family)",
            }}
          >
            Update Record
          </Typography>

          {/* {parseInt(selectEvent) === 1 ? (
            <>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>World Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="world"
                  type="text"
                  label="World Record"
                  value={editWorld}
                  onChange={handleInputEditChange(setEditWorld)}
                  onBlur={handleInputEditBlur(setEditWorld)}
                />
              </FormControl>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>National Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="national"
                  type="text"
                  label="National Record"
                  value={editNational}
                  onChange={handleInputEditChange(setEditNational)}
                  onBlur={handleInputEditBlur(setEditNational)}
                />
              </FormControl>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>State Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="state"
                  type="text"
                  label="State Record"
                  value={editState}
                  onChange={handleInputEditChange(setEditState)}
                  onBlur={handleInputEditBlur(setEditState)}
                />
              </FormControl>
            </>
          ) : (
            <> */}
          <Grid container columns={{ sm: 1, md: 3, lg: 3 }} spacing={2}>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel>World Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  id="world"
                  sx={style.inputFiled}
                  type="text"
                  label="World Record"
                  value={editWorld}
                  onChange={handleInputEditChange(setEditWorld)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>National Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="national"
                  type="text"
                  label="National Record"
                  value={editNational}
                  onChange={handleInputEditChange(setEditNational)}
                />
              </FormControl>
            </Grid>
            <Grid item sm={1} md={1} lg={1}>
              <FormControl variant="outlined" sx={style.formControl1}>
                <InputLabel sx={style.label}>State Record</InputLabel>
                <OutlinedInput
                  style={{ borderRadius: "10px" }}
                  sx={style.inputFiled}
                  id="state"
                  type="text"
                  label="State Record"
                  value={editState}
                  onChange={handleInputEditChange(setEditState)}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              sm={3}
              md={3}
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <CustomCommonButton
                onClick={handleEditRecord}
                icon={<Update />}
                text="Update"
              />
            </Grid>
          </Grid>
          {/* <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          ></div> */}
          {/* <Button
            variant="contained"
            onClick={() => handleEditRecord()}
            sx={{
              m: 2,
              borderRadius: 3,
              background: "var(--app-bar-color)",
              width: 150,
              ml: 28,
              fontFamily: "var(--font-family)",
            }}
          >
            Edit
          </Button> */}
        </Box>
      </Modal>

      <GridTable columns={GetData.record_master} rows={rows} />
    </Box>
  );
};

export default RecordMaster;
