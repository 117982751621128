import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { ChartComponent } from "../../Common/Components/chart/ChartComponent";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProgressChart = ({ label, filteredData }) => {
  const energyLevel =
    Array.isArray(filteredData) &&
    filteredData
      ?.map((item) => item?.energyLevel)
      ?.reduce((a, b) => a + b, 0)
      .toFixed(2);
  const percentage = energyLevel
    ? energyLevel / filteredData?.length
    : filteredData;
  const percent = Number.isNaN(percentage) ? 0 : percentage.toFixed(2);

  // console.log(first)
  const options = {
    series: [percent],
    options: {
      chart: {
        height: 250,
        type: "radialBar",
      },
      colors: ["var(--app-bar-color)"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
            },
            value: {
              color: "var(--font-color)",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  };

  return (
    <ChartComponent
      options={options.options}
      series={options.series}
      type={"radialBar"}
      height={250}
    />
  );
};

export default ProgressChart;
