import { Box, Typography } from "@mui/material";
import React from "react";
import { ChartComponent } from "../../../../Common/Components/chart/ChartComponent";
import Extension from "../../../../Common/Extension";

const FeelLevelTrack = ({ influence }) => {
  const feelingsMap = {
    ANGRY: 0,
    MAD: 0,
    STRESSED: 1,
    SAD: 2,
    BORED: 3,
    HAPPY: 4,
  };

  // console.log(influence);

  const emojis = {
    0: "😠",
    1: "😩",
    2: "😢",
    3: "😐",
    4: "😊",
  };

  const categories = influence?.map((data) =>
    Extension.convertDateFormatToDMY(data.createdDate)
  );

  const series = [
    {
      name: "FEEL",
      data: influence
        ?.map((item) => feelingsMap[item?.feel.toUpperCase()])
        .reverse(),
    },
  ];

  // console.log(categories);
  // console.log(series);

  const chartOption = {
    series: series,
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 0.5,
      },
      markers: {
        size: 5,
      },
      colors: ["var(--chart-bar-color)", "var(--chart-dark-color)"],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        color: "var(--font-color)", // Legend item color
        labels: {
          colors: "var(--font-color)",
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 20,
        },
        // itemTextTransform: "rotate-45",
      },
      xaxis: {
        categories: categories.reverse() || [],
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return emojis[val];
          },
        },
        tickAmount: Object.keys(emojis).length - 1,
        min: 0,
        max: Object.keys(emojis).length - 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return emojis[val];
          },
        },
      },
      noData: {
        text: "There Is No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "var(--font-family)",
        },
      },
    },
  };

  return (
    <Box className="biometric-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          mb: 3,
        }}
      >
        Feel Level
      </Typography>
      <ChartComponent
        options={chartOption.options}
        series={chartOption.series}
        type={"line"}
        height={250}
      />
    </Box>
  );
};

export default FeelLevelTrack;
