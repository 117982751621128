import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import { ChartComponent } from "../../Common/Components/chart/ChartComponent";
import Extension from "../../Common/Extension";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "./GridTable";

export const PreparationView = () => {
  const userId = sessionStorage.getItem("userId");

  const [setsAverage, setSetsAverage] = useState("");
  const [repAverage, setRepAverage] = useState("");
  const [restAverage, setRestAverage] = useState("");
  const [rowData, setRowData] = useState([]);
  const [playerName, setPlayerName] = useState([]);
  const { warmUpWorkoutHeaderId } = useParams();
  const [playerDetails, setPlayerDetails] = useState([]);
  const [activity, setActivity] = useState([]);

  async function fetchData() {
    try {
      const res = await api.get("/warmUp/all");
      const data = res.data;
      const detailsResponse = await api.get(
        "/warmUpWorkoutDetailsController/headerId/" + warmUpWorkoutHeaderId
      );
      const detailsData = detailsResponse.data;
      const mergedData = detailsData.map((datas) => {
        const pData = data.filter(
          (item) => parseInt(item.warmUpId) === parseInt(datas.warmUpId)
        );

        return {
          datas,
          pData,
        };
      });
      setRowData(mergedData);
    } catch (err) {
      console.error(err);
    }
  }

  const rows = rowData.map((item, index) => {
    return {
      id: index,
      exercise: item.pData.map((i) => i.warmUpName),
      type: item.pData.map((i) => i.type),
      unit: item.pData.map((i) => i.unit),
      assignSet: item.datas.assignSet,
      actualSet: item.datas.actualSet,
      assignRep: item.datas.assignRep,
      actualRep: item.datas.actualRep,
      assignRest: item.datas.assignRest,
      actualRest: item.datas.actualRest,
      assignTime: item.datas.assignTime,
      actualTime: item.datas.actualTime,
    };
  });

  async function getPlayerName() {
    try {
      const user = await api.get("/users/coachId/" + userId);
      const datas = user.data;
      const res = await api.get(
        "/warmUpWorkoutHeader/" + warmUpWorkoutHeaderId
      );
      const response = res.data;
      setPlayerDetails(response);

      const userName = datas.find(
        (d) => parseInt(d.userId) === parseInt(response.playerId)
      );
      setPlayerName(userName);
      const activity = await api.get("/activity/" + response.activityId);
      const act = activity.data;
      setActivity(act);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetchData();
    getPlayerName();
  }, []);

  const capitalizeFirstLetter = (word, index) => {
    const lowercaseWords = ["and", "to", "m", "for"];
    return lowercaseWords.includes(word.toLowerCase()) && index !== 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1);
  };

  const new_rows = rows.map((row, index) => ({
    ...row,
    id: index + 1,
    actualRep: row.actualRep || 0,
    actualRest: Extension.formatDuration(row.actualRest) || "00:00",
    actualSet: row.actualSet || 0,
    actualTime: Extension.formatDuration(row.actualTime) || "00:00",
    assignRep: row.assignRep || 0,
    assignRest: Extension.formatDuration(row.assignRest) || "00:00",
    assignSet: row.assignSet || 0,
    assignTime: Extension.formatDuration(row.assignTime) || "00:00",
    exercise_name: row.exercise[0]
      ?.split(/\s+/)
      .map((word, i) => capitalizeFirstLetter(word))
      .join(" "),
    type: row.type[0]
      ?.split(/\s+/) // Split by whitespace to handle single and multiple words
      .map((word, i) => capitalizeFirstLetter(word))
      .join(" "),
  }));

  const getPercentageForChart = useCallback(() => {
    if (new_rows && new_rows.length > 0) {
      const getTotals = (param) => {
        const count = new_rows.map((item) => item[param]);
        const totals = count.reduce((a, b) => a + b, 0);
        console.log(totals);
        return totals;
      };

      const getPercentage = (assign, actual) => {
        return assign === 0 || actual === 0 ? 0 : (actual / assign) * 100;
      };

      const assign_set = getTotals("assignSet");
      const act_set = getTotals("actualSet");
      const assign_rest = getTotals("assignRest");
      const act_rest = getTotals("actualRest");
      const assign_rep = getTotals("assignRep");
      const act_rep = getTotals("actualRep");

      const set_average = getPercentage(assign_set, act_set).toFixed(2);
      const rep_average = getPercentage(assign_rest, act_rest).toFixed(2);
      const rest_average = getPercentage(assign_rep, act_rep).toFixed(2);
      setSetsAverage(set_average);
      setRepAverage(rep_average);
      setRestAverage(rest_average);
      console.log(set_average);
      console.log(rep_average);
      console.log(rest_average);
    }
  }, [new_rows]);

  useEffect(() => {
    if (
      new_rows &&
      new_rows.length > 0 &&
      !setsAverage &&
      !repAverage &&
      !restAverage
    ) {
      getPercentageForChart();
    }
  }, [getPercentageForChart, new_rows, repAverage, restAverage, setsAverage]);

  const navigate = useNavigate();

  const navigatePath = () => navigate("/preparation");

  const series = [
    {
      name: "Value",
      data: [setsAverage, repAverage, restAverage],
    },
  ];

  const chart1Options = {
    options: {
      chart: {
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          distributed: true, // This is the key change
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: ["#fb8486", "#90be6d", "#2d9cdb"],
      xaxis: {
        categories: ["Average Sets", "Average Reps", "Average Rest"],
        labels: {
          style: {
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            fontWeight: "var(--font-weight)", // Set font weight to bold
            colors: "var(--font-color)",
          },
        },
      },
      yaxis: {
        min: 0,
        // max: 10,
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          colors: "var(--font-color)",
        },
        labels: {
          formatter: function (val) {
            return `${val}%`;
          },
        },
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val;
      //     },
      //   },
      // },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        labels: {
          colors: "var(--font-color)",
          useSeriesColors: false,
        },
      },
      noData: {
        text: "There Is No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "black",
          fontSize: "18px",
          fontFamily: "var(--font-family)",
        },
      },
    },
    series: series,
  };

  return (
    <Box className="common-wrapper">
      <Box>
        <CustomCommonButton
          onClick={navigatePath}
          variant="contained"
          text="BACK"
          icon={<ArrowCircleLeftIcon />}
        />
      </Box>
      <Paper
        className="content"
        elevation={4}
        sx={{ p: 3, mt: 3, borderRadius: "var(--roundness)" }}
      >
        <Grid
          container
          columns={{ sm: 1, xs: 1, md: 2, lg: 2 }}
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Paper>
              <ChartComponent
                options={chart1Options.options}
                series={chart1Options.series}
                type={"bar"}
                height={400}
              />
            </Paper>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <TableContainer component={Paper}>
              <Table sx={{ height: 400 }}>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      Player Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {playerName?.userName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      Activity
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {activity?.activityName}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      Session
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {playerDetails?.session}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {Extension.convertDateFormatToDMY(
                        playerDetails?.createdDate
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      Start Time
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {playerDetails?.startTime || "--"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      End Time
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                        color: "var(--font-color)",
                      }}
                    >
                      {playerDetails.endTime || "--"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2}>
            <GridTable
              columns={GetData.preparation_view}
              rows={new_rows}
              height={"30vh"}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
