import {
  DeleteRounded,
  PostAdd,
  RemoveRedEye,
  RequestQuote,
} from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonDropdown from "../../Common/Components/CommonDropdown";
import CommonAlertPopup from "../../Common/Components/CommonPopUp/CommonAlertPopup";
import CustomTextField from "../../Common/Components/CommonTextField";
import CustomCommonButton from "../../Common/Components/CustomCommonButton";
import Styles from "../../Common/Components/Styles";
import ToastMessage from "../../Common/Components/ToastMessage";
import api from "../../api/http-common";
import { GetData } from "../../data/GetData";
import GridTable from "../common/GridTable";
import LoadingComponents from "../../Common/Components/LoadingComponents";
import Extension from "../../Common/Extension";

const CombinationEdit = () => {
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [activity, setActivity] = useState([]);
  const [player, setPlayer] = useState({});
  const [exercise, setExercise] = useState([]);
  const [activityValue, setActivityValue] = useState("");
  const [selectDetail, setSelectDetail] = useState([]);
  const { warmUpCombinationId } = useParams();
  const [sets, setSets] = useState();
  const [newlyAddedExercises, setNewlyAddedExercises] = useState([]);
  const [wId, setWId] = useState([]);
  const [isCreatedByCurrentUser, setIsCreatedByCurrentUser] = useState(false);
  const [academyDetail, setAcademyDetail] = useState({});
  const [actValue, setActValue] = useState({
    activityIdName: "",
  });
  const [values, setValues] = useState({
    // combinationName: "",
  });
  const [rep, setRep] = useState([]);
  const [rest, setRest] = useState([]);
  const [time, setTime] = useState([]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [isActiveTimeSeconds, setIsActiveTimeSeconds] = useState(true);
  const [isRestTimeSeconds, setIsRestTimeSeconds] = useState(true);

  const handlePopupOpen = (index) => {
    setSelectedIndex(index);
    setPopupOpen(true);
  };
  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  var initialExercise = {
    warmUpId: "",
    noOfSet: "0",
    noOfRep: "0",
    noOfRest: "00:00",
    noOfTime: "00:00",
  };
  const [readOnly] = useState(true);
  const [exerciseValue, setExerciseValue] = useState(initialExercise);
  const { toast, contextHolder } = ToastMessage();

  function getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  async function getCombination() {
    try {
      const res = await api.get("/warmUpCombination/" + warmUpCombinationId);
      setValues({
        ...values,
        ...res.data,
        combinationName: res.data.warmUpCombinationName,
      });
      setIsCreatedByCurrentUser(parseInt(res.data.createdBy) === userId);
    } catch (err) {
      console.log(err);
    }
  }

  async function getActivity() {
    try {
      const res = await api.get("/warmUpCombination/" + warmUpCombinationId);
      const data = res.data.activityId;
      let playerData;
      if (res.data) {
        playerData = await api
          .get(`/users/${res.data.createdBy}`)
          .then((res) => res.data);
        setPlayer(playerData);
      }
      if (data) {
        const response = await api.get("/activity/" + data);
        const actName = response.data.activityName;
        setActivityValue(data);
        setActValue({ ...actValue, activityIdName: actName });
      }
      const response = await api.get(
        isCreatedByCurrentUser
          ? `/activity/createdBy/${userId}`
          : `/activity/all`
      );
      if (!isCreatedByCurrentUser) {
        console.log(playerData);
        await api
          .get(`/academy/${playerData.academyId}`)
          .then((res) => setAcademyDetail(res.data))
          .catch((err) => console.log(err));
      }
      setActivity(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getExercise() {
    try {
      const response = await api.get(`/warmUp/createdBy/${userId}`);
      setExercise(response.data);
    } catch (err) {
      console.log("Error", err);
    }
  }

  async function getDetails() {
    try {
      setInitialLoading(true);
      const res = await api.get(
        "/warmUpDetails/warmUpCombination/" + warmUpCombinationId
      );
      res.data.map((warm) => warm.warmUpId);
      const emptyData = [];
      for (let item of res.data) {
        const detailsData = await api
          .get("/warmUp/" + item.warmUpId)
          .then((res) => res.data)
          .catch((err) => console.error(err));
        detailsData.assignSet = item.noOfSet;
        detailsData.rep = item.rep;
        detailsData.rest = item.rest;
        detailsData.time = item.time;
        emptyData.push(detailsData);
      }
      setSelectDetail(emptyData);
    } catch (err) {
      console.log(err);
    } finally {
      setInitialLoading(false);
    }
  }

  useEffect(() => {
    getCombination();
    getActivity();
    getExercise();
    getDetails();
  }, [warmUpCombinationId]);

  function addSingleExercise() {
    const existingExercise = selectDetail.find(
      (item) => parseInt(item.warmUpId) === parseInt(exerciseValue.warmUpId)
    );
    const rest = Extension.convertDurationIntoSeconds(exerciseValue.noOfRest);
    const active = Extension.convertDurationIntoSeconds(exerciseValue.noOfTime);
    if (existingExercise) {
      // Update the existing exercise
      toast("error", "Already Exist Exercise");
      const updatedDetail = selectDetail.map((item) => {
        if (item.warmUpId === exerciseValue.warmUpId) {
          return {
            ...item,
            assignSet: exerciseValue.noOfSet,
            rep: exerciseValue.noOfRep,
            time: active,
            rest: rest,
          };
        }
        return item;
      });

      setSelectDetail(updatedDetail);
    } else {
      // Add a new exercise
      const selectedExercise = exercise.find(
        (item) => item.warmUpId === exerciseValue.warmUpId
      );
      if (selectedExercise) {
        const newExercise = {
          ...selectedExercise,
          warmUpId: selectedExercise.warmUpId,
          warmUpName: selectedExercise.warmUpName,
          assignSet: exerciseValue.noOfSet,
          type: selectedExercise.type,
          unit: selectedExercise.unit,
          rep: exerciseValue.noOfRep,
          time: active,
          rest: rest,
        };
        setSelectDetail((previous) => [...previous, newExercise]);
        setNewlyAddedExercises((prevExercises) => [
          ...prevExercises,
          newExercise,
        ]);
      }
    }
    setExerciseValue(initialExercise);
  }

  const navigate = useNavigate();
  async function handleEditSubmit() {
    try {
      toast("loading", "Processing...");
      // Iterate over newly added exercises
      for (const newExercise of newlyAddedExercises) {
        console.log(newExercise);
        const warmUpDetailsAdd = {
          warmUpId: newExercise.warmUpId,
          noOfSet: newExercise.assignSet,
          rep: newExercise.rep,
          rest: newExercise.rest,
          time: newExercise.time,
          warmUpCombinationId: warmUpCombinationId,
          createdBy: sessionStorage.getItem("userId"),
          createdDate: getCurrentSQLDate(),
          modifiedBy: sessionStorage.getItem("userId"),
          modifiedDate: getCurrentSQLDate(),
        };
        // Send the API request for the newly added exercise
        await api.post("/warmUpDetails/add", warmUpDetailsAdd);
        toast("success", "Combination Updated Successfully");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      console.error(`Error updating exercise with warmUpId:`, err);
    }
    const response = await api.get(
      `/warmUpDetails/warmUpCombination/` + warmUpCombinationId
    );
    const warmId = response.data;
    let warmDetails = [];
    for (let Wid of wId) {
      const filteredIds = warmId
        .filter((Id) => Wid === Id.warmUpId)
        .map((id) => id.warmUpDetailsId);
      console.log(Wid);
      warmDetails = warmDetails.concat(filteredIds);
    }

    try {
      for (const warmDetailId of warmDetails) {
        await api.delete(`/warmUpDetails/` + warmDetailId);
        console.log(`Deleted warmUpDetails with ID ${warmDetailId}`);
      }

      navigate("/Combination");
    } catch (err) {
      console.log(err);
    }
  }

  const handleDelete = () => {
    const updatedWarmupList = [...selectDetail];
    const deletedItem = updatedWarmupList.splice(selectedIndex, 1)[0];
    setWId([...wId, deletedItem.warmUpId]);
    setSelectDetail(updatedWarmupList);
    handlePopupClose();
  };
  const navigatePath = () => navigate("/Combination");
  const rows = selectDetail.map((item, index) => {
    return {
      id: index + 1,
      exercise: item.warmUpName || "--",
      type: item.type || "--",
      unit: item.unit || "--",
      noOfSet: item.assignSet || 0,
      reps: item.rep || 0,
      rest: Extension.formatDuration(item.rest) || "00:00",
      activeTime: Extension.formatDuration(item.time) || "00:00",
      action: (
        <IconButton
          disabled={!isCreatedByCurrentUser}
          onClick={() => handlePopupOpen(index)}
        >
          <DeleteRounded
            className="icon-button-style"
            sx={{
              color: !isCreatedByCurrentUser ? "var(--disable-del-button)" : "",
            }}
            color="error"
          />
        </IconButton>
      ),
    };
  });

  return (
    <Box className="common-wrapper">
      {contextHolder}
      <LoadingComponents loading={initialLoading} />
      <CommonAlertPopup
        handleYes={handleDelete}
        handleNo={handlePopupClose}
        titleText={<p>Confirmation</p>}
        contentText={<p>Are you sure you want to delete the Exercise?</p>}
        open={popupOpen}
        handleClose={handlePopupClose}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Yes"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          // marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "15%",
          }}
        >
          <CustomCommonButton
            onClick={navigatePath}
            variant="contained"
            text="BACK"
            icon={<ArrowCircleLeftIcon sx={{ fontSize: "25px", mr: 1 }} />}
          />
          <div className="views-container">
            <Tooltip arrow title={"Views"}>
              <RemoveRedEye />
            </Tooltip>{" "}
            {values.noOfViews || 0}
          </div>
          <div className="views-container">
            <Tooltip arrow title="Uses">
              <RequestQuote />
            </Tooltip>{" "}
            {values.noOfUses || 0}
          </div>
        </Box>
        {!isCreatedByCurrentUser && (
          <>
            <Typography
              variant="h3"
              textAlign={"right"}
              sx={Styles.titleText()}
            >
              Created By {player?.userName || "--"} from{" "}
              {academyDetail?.academyName || "--"}
            </Typography>
          </>
        )}
      </div>
      {isCreatedByCurrentUser && (
        <Grid
          container
          spacing={3}
          mt={0.5}
          columns={{ sm: 1, md: 3, lg: 5, xl: 5 }}
        >
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CustomTextField
              value={values.combinationName}
              style={{
                borderRadius: 12,
                fontSize: "var(--font-size)",
                fontFamily: "var(--font-family)",
              }}
              onChange={(e) =>
                setValues({ ...values, combinationName: e.target.value })
              }
              inputProps={{
                readOnly: readOnly || !isCreatedByCurrentUser,
              }}
              name="Combination Name"
              label="Combination Name"
              type="text"
            />
          </Grid>

          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CommonDropdown
              firstOption={"All"}
              label={"Activity"}
              value={activityValue}
              onChange={(e) => {
                setActivityValue(e.target.value);
              }}
              array={activity}
              menuValue={"activityId"}
              display={"activityName"}
            />
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CommonDropdown
              firstOption={"All"}
              label={"Exercise Name"}
              value={exerciseValue.warmUpId}
              onChange={(e) => {
                setExerciseValue((previous) => {
                  return { ...previous, warmUpId: e.target.value };
                });
              }}
              array={exercise}
              menuValue={"warmUpId"}
              display={"warmUpName"}
            />
          </Grid>
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CustomTextField
              value={exerciseValue.noOfSet}
              name="No.of Sets"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (
                  inputValue === "" ||
                  (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                ) {
                  setExerciseValue((previous) => {
                    return { ...previous, noOfSet: inputValue };
                  });
                  setSets(inputValue === "" ? "" : parseInt(inputValue));
                }
              }}
              label="No. of Sets"
              type="number"
            />
          </Grid>

          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CustomTextField
              value={exerciseValue.noOfRep}
              name="Reps"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (
                  inputValue === "" ||
                  (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 99)
                ) {
                  setExerciseValue((previous) => {
                    return { ...previous, noOfRep: inputValue };
                  });
                  setRep(inputValue === "" ? "" : parseInt(inputValue));
                }
              }}
              label="Reps"
              type="number"
            />
          </Grid>

          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CustomTextField
              value={exerciseValue.noOfRest}
              name="Rest"
              inputProps={{
                maxLength: 5,
              }}
              onChange={(e) => {
                const value = Extension.autoFormatDuration(e.target.value);
                console.log(value);
                setExerciseValue((previous) => {
                  return { ...previous, noOfRest: value };
                });
                setRest(value);
              }}
              label="Rest (mm:ss)"
              // type="number"
            />
          </Grid>
          {/* <Grid
            item
            xs={1}
            sm={1}
            md={0.3}
            lg={0.3}
            xl={0.3}
            sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}
          >
            <CustomCommonButton
              onClick={() => setIsRestTimeSeconds(!isRestTimeSeconds)}
              text={isRestTimeSeconds ? "m" : "s"}
            />
          </Grid> */}
          <Grid item xs={1} md={1} lg={1} xl={1}>
            <CustomTextField
              value={exerciseValue.noOfTime}
              name="Active Time"
              inputProps={{
                maxLength: 5,
              }}
              onChange={(e) => {
                const value = Extension.autoFormatDuration(
                  e.target.value,
                  setRest
                );
                console.log(value);
                setExerciseValue((previous) => {
                  return { ...previous, noOfTime: value };
                });
                setTime(value === "" ? "" : parseInt(value));
              }}
              label="Active (mm:ss)"
              // type="number"
            />
          </Grid>
          {/* <Grid
            item
            xs={1}
            sm={1}
            md={0.3}
            lg={0.3}
            xl={0.3}
            sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}
          >
            <CustomCommonButton
              onClick={() => setIsActiveTimeSeconds(!isActiveTimeSeconds)}
              text={isActiveTimeSeconds ? "m" : "s"}
            />
          </Grid> */}
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}>
            <Box sx={{ display: "flex" }}>
              <CustomCommonButton
                disabled={
                  (!exerciseValue.warmUpId &&
                    !exerciseValue.noOfSet &&
                    !exerciseValue.noOfRep &&
                    !exerciseValue.noOfRest &&
                    !exerciseValue.noOfTime) ||
                  (exerciseValue.noOfSet <= 0 &&
                    exerciseValue.noOfRep <= 0 &&
                    exerciseValue.noOfRest === "00:00" &&
                    exerciseValue.noOfTime === "00:00")
                }
                onClick={() => {
                  addSingleExercise();
                }}
                icon={<PostAdd sx={{ fontSize: "40px" }} />}
                tooltipText="Add Exercise"
              />
              <Box sx={{ ml: 2 }}>
                <CustomCommonButton
                  disabled={!isCreatedByCurrentUser}
                  onClick={handleEditSubmit}
                  tooltipText="Update Combination"
                  // text="Combination"
                  icon={<PlaylistAddIcon sx={{ fontSize: "40px" }} />}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} md={0.5} lg={0.5} xl={0.5}></Grid>
        </Grid>
      )}
      {!isCreatedByCurrentUser && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Typography variant="h3" textAlign={"left"} sx={Styles.titleText()}>
            Activity Name:{" "}
            {
              activity?.find((item) => item.activityId === activityValue)
                ?.activityName
            }
          </Typography>
          <Typography variant="h3" textAlign={"right"} sx={Styles.titleText()}>
            Combination Name: {values.combinationName}
          </Typography>
        </div>
      )}
      <GridTable
        columns={GetData.combinationAdd}
        rows={rows}
        height={isCreatedByCurrentUser ? "70vh" : "70vh"}
      />
    </Box>
  );
};

export default CombinationEdit;
