import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const RemainingDays = ({ dates }) => {
  const today = dayjs();
  const [missingDaysCount, setMissingDaysCount] = useState(0);

  const arrayData = dates.map((user) => user.createDate);

  useEffect(() => {
    const uniqueDates = Array.from(new Set(arrayData)); // Remove duplicates
    const dateObjects = uniqueDates.map(date => dayjs(date, 'YYYY-MM-DD')).sort((a, b) => a - b);

    const startDate = dateObjects[0];
    const endDate = today;

    let missingCount = 0;
    for (let date = startDate; date?.isBefore(endDate); date = date.add(1, 'day')) {
      if (!dateObjects.some(d => d.isSame(date, 'day'))) {
        missingCount++;
      }
    }
    setMissingDaysCount(missingCount);
  }, [arrayData]);

  return (
    <div>
      {missingDaysCount}
    </div>
  );
};

export default RemainingDays;
