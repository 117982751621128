import { Box, Typography } from "@mui/material";
import React from "react";
import LiquidFillGauge from "react-liquid-gauge";
import "./WaterTrack.css";

const WaterTrack = ({ influence, dayDifference }) => {
  const Consumption = influence
    ?.map((item) => item?.water)
    ?.reduce((a, b) => a + b, 0)
    ?.toFixed(2);
  const targetedWater = dayDifference.totalDays * 4;
  const radius = 100; // Radius of the gauge
  const percentage = (Consumption / targetedWater) * 100;
  const percent = Number.isNaN(percentage) ? 0 : percentage; // Convert value to percentage
  return (
    <Box className="preparation-container">
      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--font-family)",
          fontWeight: "var(--font-weight)",
          fontSize: "var(--header-font-size)",
          mb: 1,
        }}
      >
        Water Tracking
      </Typography>
      <div className="footer-container">
        <Typography
          variant="p"
          sx={{
            fontFamily: "var(--font-family)",
            fontSize: "var(--body-font-size)",
          }}
        >
          Target <b>{targetedWater || 0}</b> ltrs
        </Typography>
      </div>
      <div className="gauge-container">
        <LiquidFillGauge
          style={{ margin: "0 auto" }}
          width={radius * 2}
          height={radius * 2}
          value={percent}
          percent="%"
          textSize={0.75}
          textOffsetX={0}
          textOffsetY={0}
          textRenderer={(props) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius) / 2;
            const valueStyle = {
              fontSize: textPixels,
            };
            const percentStyle = {
              fontSize: textPixels * 0.6,
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {value}
                </tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          // gradientStops={gradientStops}
          circleStyle={{
            fill: "var(--chart-bar-color)",
          }}
          waveStyle={{
            fill: "#18dbde",
          }}
          textStyle={{
            fill: "var(--chart-bar-color)",
          }}
          waveTextStyle={{
            fill: "#FFFFFF",
          }}
        />
      </div>
      <div className="footer-container">
        <Typography
          variant="p"
          sx={{
            fontFamily: "var(--font-family)",
            fontSize: "var(--body-font-size)",
          }}
        >
          Consumption <b>{Consumption || 0}</b> ltrs
        </Typography>
      </div>
    </Box>
  );
};

export default WaterTrack;
