import moment from "moment";

class Extension {
  getCurrentSQLDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  timeDifference = (date1, date2) => {
    const startDate = moment(date2);
    const endDate = moment(date1);

    // Calculate the difference in days
    const totalDays = endDate.diff(startDate, "days") + 1;

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate the remaining weeks
    const weeks = Math.floor(remainingDays / 7);
    const remainingWeeks = weeks + (remainingDays % 7 > 0 ? 1 : 0);

    return { totalDays, months, remainingDays, remainingWeeks };
  };

  dayDifferFind = (date1, date2) => {
    const startDate = moment(date2);
    const endDate = moment(date1);

    if (!date2) {
      return null;
    }

    // Calculate the difference in days
    const totalDays = endDate.diff(startDate, "days");

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");
    const weeks = endDate.diff(startDate, "weeks");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate the remaining weeks
    // const weeks = Math.floor(remainingDays / 7);
    // const remainingWeeks = weeks + (remainingDays % 7 > 0 ? 1 : 0);

    console.log(totalDays, weeks, months);

    const value = totalDays > 30 ? months : totalDays > 7 ? weeks : totalDays;
    const format = totalDays > 30 ? "Months" : totalDays > 7 ? "Weeks" : "Days";

    return { value, format };
  };

  convertDateFormatToDMY(date) {
    const inDate = new Date(date);
    const year = inDate.getFullYear();
    const month = String(inDate.getMonth() + 1).padStart(2, "0");
    const day = String(inDate.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  }

  formatDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = (durationInSeconds % 60)?.toFixed(0);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }

  formatDurationWithMilliSecond(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = (durationInSeconds % 60)?.toFixed(2);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(5, "0")}`;
  }

  autoFormatDuration(value) {
    if (!value) {
      return "00:00";
    }
    // Remove any non-digit characters
    let cleanValue = value.replace(/\D/g, "");

    // Convert to number for comparisons
    let numValue = parseInt(cleanValue, 10);

    // Ensure the value doesn't exceed 5959 (59:59)
    if (numValue > 5959) {
      numValue = 5959;
    }

    // Convert back to string and pad with leading zeros if necessary
    let paddedValue = numValue.toString().padStart(4, "0");

    // Format as MM:SS
    let formattedValue = paddedValue.slice(0, 2) + ":" + paddedValue.slice(2);

    return formattedValue;
  }

  convertDurationIntoSeconds(duration) {
    const splitValue = duration.toString().split(":");
    const minute = splitValue[0] * 60;
    const seconds = splitValue[1];
    return parseInt(minute) + parseInt(seconds);
  }
}

export default new Extension();
