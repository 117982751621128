import { Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const PlayersContainer = ({ players, handleUserSelection }) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");
  // const [existingPlayers, setExistingPlayers] = useState([]);

  // useEffect(() => {
  //   if (existingPlayers !== players) {
  //     setExistingPlayers(players);
  //     setSelectedPlayer("");
  //   }
  // }, [existingPlayers, players]);

  const handleClick = useCallback(
    (e) => {
      setSelectedPlayer(e.userId);
      handleUserSelection(e);
    },
    [handleUserSelection]
  );

  useEffect(() => {
    if (Array.isArray(players) && players.length > 0) {
      const playerExists = players.some(
        (player) => player.userId === selectedPlayer
      );
      if (!playerExists) {
        handleClick(players[0]);
      }
    }
  }, [players, handleUserSelection]);

  return (
    <div className="player-container">
      {Array.isArray(players) && players.length <= 0 && (
        <div className="images">
          <div
            className={`player-image`}
            style={{
              height: 85,
              width: 85,
            }}
          ></div>
          <p>No Players</p>
          <p>Assigned Yet</p>
        </div>
      )}
      {players?.map((item, index) => (
        <div className="images" key={item.userId}>
          <div
            className={`player-image`}
            style={{
              height: 85,
              width: 85,
              border:
                parseInt(selectedPlayer) === parseInt(item.userId) &&
                "2px solid rgb(32, 240, 9)",
            }}
            onClick={() => handleClick(item)}
          >
            {item.userName.split("")[0]}
          </div>
          <p>{item.loginId}</p>
          <Tooltip title={item.userName} placement="top">
            <p className="cut-text">{item.userName}</p>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default PlayersContainer;

PlayersContainer.propTypes = {
  players: PropTypes.array,
  handleUserSelection: PropTypes.func,
};
